import logo from "../assets/image/logo.png";
import builder_icon from "../assets/image/builder_icon.png";
import customers_icon from "../assets/image/customer_icon.png";
import question from "../assets/image/question.png";
import merchant_icon from "../assets/image/merchant_icon_white.png";
import performance_icon from "../assets/image/graph.png";
import settings_icon from "../assets/image/setting.png";
import team_icon from "../assets/image/group.png";
import logout from "../assets/image/logout.png";
import up_arrow from "../assets/image/up-arrow.png";
import "./sidebarmenu.scss";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const SidebarSettingMenu = (props) => {
  const userData = useSelector((state) => state.auth.user);
  const history = useHistory();
  const handleMenuClick = (path) => {
    history.push({
      pathname: path,
    });
  };
  let style = {};
  if (window.location.pathname === "/Business") {
    style.top = "800px"
  }
  else {
    style.bottom = "60px"
  }
  return (
    <div style={{ ...style }}
      className={`sidebar-menu-wrapper ${props.sidebarOpened ? "sidebar-menu-opened" : ""
        }`}
    >
      <div className="top-header">
        <img src={logo} alt={"logo"} />
        <span>{props.companyName}</span>
      </div>
      <div className="content-wrapper">
        <div className="left-content-wrapper">
          <div className="sub-header">
            <span>Account</span>
          </div>
          <ul className="no-bullets">
            {props.allAccess.merchantAccess ? (
              <li
                className={
                  window.location.pathname === "/Merchant" ? "selected" : ""
                }
                onClick={() => {
                  handleMenuClick("/Merchant");
                }}
              >
                <img src={merchant_icon} alt={"merchant_icon"} />
                <span>Merchant</span>
              </li>
            ) : null}
            {userData && userData.role !== 4 && !userData.subAdmin && (
              <li
                className={
                  window.location.pathname === "/customer" ? "selected" : ""
                }
                onClick={() => {
                  handleMenuClick("/customer");
                }}
              >
                <img src={customers_icon} alt={"customers"} />
                <span>Customers</span>
              </li>
            )}
            {userData && userData.role !== 4 && !userData.subAdmin && (
              <li
                className={
                  window.location.pathname === "/tasks" ? "selected" : ""
                }
                onClick={() => {
                  handleMenuClick("/tasks");
                }}
              >
                <img src={customers_icon} alt={"customers"} />
                <span>Tasks</span>
              </li>
            )}
            {/* {userData && userData.role !== 4 && !userData.subAdmin && (
              <li
                className={
                  window.location.pathname === "/invoice" ? "selected" : ""
                }
                onClick={() => {
                  handleMenuClick("/invoice");
                }}
              >
                <img src={customers_icon} alt={"customers"} />
                <span>Invoice</span>
              </li>
            )} */}
            {userData /*&& !userData.subAdmin*/ &&
              (userData.role === 1 ||
                userData.role === 2 ||
                userData.role === 3) ? (
              <li
                className={
                  window.location.pathname === "/Team" ? "selected" : ""
                }
                onClick={() => {
                  handleMenuClick("/Team");
                }}
              >
                <img src={team_icon} alt={"teams"} />
                <span>Teams</span>
              </li>
            ) : null}
            <li
              className={
                window.location.pathname === "/Performance" ? "selected" : ""
              }
              onClick={() => {
                handleMenuClick("/Performance");
              }}
            >
              <img src={performance_icon} alt={"performance"} />
              <span>Performances</span>
            </li>
            {userData && userData.role !== 4 /*&& !userData.subAdmin*/ && (
              <li
                className={
                  window.location.pathname === "/Settings" ? "selected" : ""
                }
                onClick={() => {
                  handleMenuClick("/Settings");
                }}
              >
                <img src={settings_icon} alt={"setting"} />
                <span>Administration</span>
              </li>
            )}
            <li onClick={props.logout}>
              <img src={logout} alt={"logout"} />
              <span>Log out</span>
            </li>
          </ul>
        </div>
        <div className="left-content-wrapper right-content-wrapper">
          <div className="sub-header">
            <span>Explore</span>
          </div>
          <ul className="no-bullets">
            <li>
              <img src={question} alt={"merchant_icon"} />
              <a
                href="https://help.gomove.ai/en/"
                target="_blank"
                rel="noreferrer"
              >
                Get help
              </a>
            </li>
            <li
              className={
                window.location.pathname === "/Business" ? "selected" : ""
              }
              onClick={() => {
                handleMenuClick("/Business");
              }}
            >
              <img src={up_arrow} alt={"customers"} />
              <span>Upgrade account</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SidebarSettingMenu;
