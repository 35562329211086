import axios from "axios";
import authHeader from "./auth-header";
import timeZoneHeader from "./timezone-header";
import moment from "moment";
import momentTz from "moment-timezone";

const API_URL = process.env.REACT_APP_API_URL + "task/";
let timezone = { ...timeZoneHeader() }

const getUnassignedTaskList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id

    if (params.date) {
        const dateQuery = getdateQuery(params.date, timezone)
        delete params.date
        params = { ...params, ...dateQuery }
    }
    return axios.get(API_URL, { headers: authHeader(), params: { limit: 10, ...params, taskCategory: "unassigned", } });
};

const getTaskList = (params, tracking) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    if (tracking) delete params.userId
    if (params.date) {
        const dateQuery = getdateQuery(params.date, timezone)
        delete params.date
        params = { ...params, ...dateQuery }
    }
    return axios.get(API_URL, { headers: { ...authHeader(), ...timeZoneHeader() }, params: { limit: 10000, ...params, all: true } });
};
const getCustomerTaskList = (params, tracking) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    if (tracking) delete params.userId
    if (params.date) {
        const dateQuery = getdateQuery(params.date, timezone)
        delete params.date
        params = { ...params, ...dateQuery }
    }
    return axios.get(API_URL + `getCustomerTasks`, { headers: { ...authHeader(), ...timeZoneHeader() }, params: { limit: 10000, ...params, all: true } });
};
const getTaskListTracking = (params, tracking) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    if (tracking) delete params.userId
    if (params.date) {
        const dateQuery = getdateQuery(params.date, timezone)
        delete params.date
        params = { ...params, ...dateQuery }
    }
    return axios.get(API_URL, { params: { limit: 10000, ...params, all: true } });
};

const getMissionListTracking = (params, tracking) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    if (tracking) delete params.userId
    if (params.date) {
        const dateQuery = getdateQuery(params.date, timezone)
        delete params.date
        params = { ...params, ...dateQuery }
    }
    return axios.get(API_URL + 'missions/tracking', { headers: authHeader(), params: { ...params } });
};

const getTaskCount = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id


    return axios.get(API_URL + 'count', { headers: authHeader(), params: { ...params } });
};

const mainSearch = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser && currentUser.role !== 1) params.userId = currentUser.id

    if (params.date) {
        // const dateQuery = getdateQuery(params.date)
        params.startDate = +moment(params.date).startOf("d")
        params.endDate = +moment(params.date).endOf("d")
        delete params.date
        params = { ...params }
    }
    return axios.get(API_URL + 'mainSearch', { headers: authHeader(), params: { ...params } });
};

const createTask = (data) => {
    return axios.post(API_URL, data, { headers: { ...authHeader(), ...timeZoneHeader() } });
};

const saveTaskPosition = (data) => {
    data = clean(data)
    return axios.post(API_URL + 'changePosition', data, { headers: authHeader() });
};

const getUnassignedMissionList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    if (params.date) {
        const dateQuery = getdateQuery(params.date, timezone)
        delete params.date
        params = { ...params, ...dateQuery }
    }
    return axios.get(API_URL + 'missions', { headers: { ...authHeader(), ...timeZoneHeader() }, params: { ...params, missionCategory: "unassigned" } });
};
const getAssignedMissionList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    if (params.date) {
        const dateQuery = getdateQuery(params.date, timezone)
        delete params.date
        params = { ...params, ...dateQuery }
    }
    return axios.get(API_URL + 'missions', { headers: { ...authHeader(), ...timeZoneHeader() }, params: { ...params, missionCategory: "assigned" } });
};

const getMissionList = (params) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) params.userId = currentUser.id
    if (params.date) {
        const dateQuery = getdateQuery(params.date, timezone)
        delete params.date
        params = { ...params, ...dateQuery }
    }
    return axios.get(API_URL + 'missions', { headers: { ...authHeader(), ...timeZoneHeader() }, params: { ...params, } });
};
const getMissionDetails = (params) => {
    return axios.get(API_URL + 'missions/details', { headers: { ...authHeader(), ...timeZoneHeader() }, params: { ...params, } });
};

const createMession = (data) => {
    data = clean(data)
    if (data.date) {
        const dateQuery = getdateQueryMission(data.date)
        data = { ...data, dateQuery: JSON.stringify(dateQuery), missionDate: data.date }
        delete data.date
        let timezone = { ...timeZoneHeader() }
        data.missionDate = momentTz(data.missionDate).tz(timezone.timezone)._d
    }
    return axios.post(API_URL + 'createMission', data, { headers: { ...authHeader(), ...timeZoneHeader() } });
};
const assignMission = (data) => {
    data = clean(data)
    return axios.post(API_URL + 'assignMission', data, { headers: authHeader() });
};

const unAssignMission = (data) => {
    data = clean(data)
    return axios.post(API_URL + 'unAssignMission', data, { headers: authHeader() });
};

const optimiseMission = (data) => {
    data = clean(data)
    return axios.post(API_URL + 'optimiseMission', data, { headers: authHeader() });
};

const deleteMission = (data) => {
    data = clean(data)
    const headerObj = { headers: authHeader() }
    return axios.put(API_URL + 'deleteMission', data, headerObj);

};
const deleteTasks = (data) => {
    const headerObj = { headers: authHeader() }
    return axios.put(API_URL + 'deleteTask', data, headerObj);
};

function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
    }
    return obj
}
const getdateQuery = (date, timezone) => {
    let currentDate = new Date(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    if (timezone) {
        return {
            orderDate: {
                $gte: momentTz(date).tz(timezone.timezone).startOf("d")._d,
                $lt: momentTz(date).tz(timezone.timezone).endOf("d")._d
            }
        }
    }
    else {
        return {
            orderDate: {
                $gte: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
                $lt: new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate())
            }
        }
    }

}

const getdateQueryMission = (date) => {
    let currentDate = new Date(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);

    return {
        missionDate: {
            $gte: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            $lt: new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate())
        }
    }

}

const setLinkedTasks = (data) => {
    data = clean(data)
    return axios.post(API_URL + 'setLinkedTasks', data, { headers: authHeader() });
};


const addTaskToMission = (data) => {
    data = clean(data)
    return axios.post(API_URL + 'addTaskToMission', data, { headers: authHeader() });
};

const createMissionPDF = (data) => {
    data = clean(data)
    return axios.get(API_URL + 'createMissionPDF', { headers: authHeader(), responseType: 'blob', params: { ...data } });
}

const createMultipleTasks = (data) => {
    return axios.post(API_URL + 'createMultipleTasks', data, { headers: { ...authHeader(), ...timeZoneHeader() } });
}

const exportTaskSheet = (data) => {
    data = clean(data)
    return axios.get(API_URL + 'exportTaskSheet', { headers: authHeader(), responseType: 'blob' });
}

const createTaskPDF = (data) => {
    data = clean(data)
    return axios.get(API_URL + 'createTaskPDF', { headers: { ...authHeader(), ...timeZoneHeader() }, responseType: 'blob', params: { ...data } });
}
const getTaskHistory = (params) => {
    params = clean(params)
    return axios.get(API_URL + 'getTaskHistory', { headers: authHeader(), params: { ...params } });
}
const changeStatus = (data) => {
    data = clean(data)
    return axios.post(API_URL + 'changeStatus', data, { headers: authHeader() });
}

const multipleMissionUpdate = (data) => {
    data = clean(data);
    return axios.put(API_URL + 'mission/multipleUpdate', data, { headers: authHeader() })
}

const updateMissionDirections = (data) => {
    data = clean(data);
    return axios.put(API_URL + 'mission/updateDirections', data, { headers: authHeader() })
}

const submitLegalSignedDoc = (data) => {
    data = clean(data);
    return axios.post(API_URL + 'submitLegalSignedDoc', data, {})
}

const googleDistanceApi = (data) => {
    data = clean(data);
    return axios.post(API_URL + 'googleDistanceApi', data, { headers: authHeader() })
}

const removeTasksFromMission = (data) => {
    data = clean(data);
    return axios.post(API_URL + 'removeTasksFromMission', data, { headers: authHeader() })
}

const updateMultipleTasks = (data) => {
    data = clean(data);
    return axios.post(API_URL + 'updateMultipleTasks', data, { headers: authHeader() })
}

const getTaskCountDaily = (params) => {
    params = clean(params);
    params.orderDate = JSON.stringify(params.orderDate)
    return axios.get(API_URL + 'taskCount', { headers: authHeader(), params: { ...params } })
}

const sendScheduleDelivery = (data) => {
    data = clean(data);
    return axios.post(API_URL + 'scheduleDelivery', data, { headers: authHeader() })
}

const updateMission = (data) => {
    data = clean(data)
    delete data.type
    if (data.missionDate) data.missionDate = momentTz(data.missionDate).tz(timezone.timezone)._d
    if (data.endsOnDate) data.endsOnDate = momentTz(data.endsOnDate).tz(timezone.timezone).endOf("d")._d
    return axios.put(API_URL + 'mission/updateMission', data, { headers: authHeader() })
}
const duplicateMission = (data) => {
    data = clean(data)
    delete data.type
    delete data.repeatOnDays
    if (data.missionDate) data.missionDate = momentTz(data.missionDate).tz(timezone.timezone)._d
    return axios.post(API_URL + 'mission/duplicateMission', data, { headers: authHeader() })
}

const updateScheduleDelivery = (data) => {
    data = clean(data)
    if (data.orderDate) data.orderDate = momentTz(data.orderDate).tz(timezone.timezone)._d
    return axios.put(API_URL + 'updateTaskSchedule', data, { headers: authHeader() })
}
const taskService = {
    getUnassignedTaskList,
    createTask,
    createMession,
    getUnassignedMissionList,
    mainSearch,
    deleteMission,
    deleteTasks,
    getAssignedMissionList,
    assignMission,
    getTaskList,
    saveTaskPosition,
    getMissionList,
    optimiseMission,
    setLinkedTasks,
    addTaskToMission,
    createMissionPDF,
    createMultipleTasks,
    exportTaskSheet,
    getTaskCount,
    createTaskPDF,
    getTaskHistory,
    changeStatus,
    multipleMissionUpdate,
    submitLegalSignedDoc,
    unAssignMission,
    googleDistanceApi,
    removeTasksFromMission,
    getTaskListTracking,
    getMissionListTracking,
    updateMultipleTasks,
    getTaskCountDaily,
    sendScheduleDelivery,
    getMissionDetails,
    updateMission,
    duplicateMission,
    updateMissionDirections,
    getCustomerTaskList,
    updateScheduleDelivery
};

export default taskService