import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
} from "react-bootstrap";
import "./Performance.scss";
import ContentLayout from "../../components/contentlayout";
import PieChartComponent from "./PieChart";
import StackedBarChartComponent from "./StackedBarChart";
import { GET_TASK_ANALYTICS } from "../../redux/task/actionTypes";
import momentTz from "moment-timezone";
import Select from "react-select";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { getDates, getLast7Days, getMonthsBetweenDates, getMonthToDate, getQuarterToDate, getYearToDate, timeZoneHeader } from "../../helpers/helperFunctions";
import TaskList from "./AnalyticsTable";
import { GET_USER_API } from "../../redux/user/actionTypes";
import { GET_TEAM_LIST } from "../../redux/team/actionTypes";
import { USER_ROLES } from "../../helpers/configDefaults";
import { FaAngleDown } from "react-icons/fa";
import * as XLSX from 'xlsx';

const timezone = { ...timeZoneHeader() }
const Options = [{
  id: "TODAY",
  value: "Today"
}, {
  id: "LAST_7_DAYS",
  value: "Last 7 days"
}, {
  id: "MONTH_TO_DATE",
  value: "Month to date"
}, {
  id: "QUARTER_TO_DATE",
  value: "Quarter to date"
}, {
  id: "YEAR_TO_DATE",
  value: "Year to date"
}, {
  id: "ALL_TIME",
  value: "All time"
}, {
  id: "CUSTOM",
  value: "Custom"
}]
const Performance = (props) => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.auth.user);
  const { teamList } = useSelector((state) => state.team)
  let { agentList } = useSelector((state) => state.user);
  const [date, setDate] = useState({
    startDate: momentTz(),
    endDate: momentTz(),
  });
  const [selectedOption, setSelectedOption] = useState(Options[0]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [graphType, setGraphType] = useState("DAILY");
  const [performanceData, setPerformanceData] = useState(null);
  const [distanceUnit, setDistanceUnit] = useState("KM");
  const { settings } = useSelector(
    (state) => state.authSaga
  );

  const [analyticsData, setAnalyticsData] = useState({
    tasksTotal: 0,
    pieChartTasks: { value: "", text: "Tasks" },
    tasks: [
      { name: "Pickup", value: 0, percentage: 0, color: "#EAEAEA" },
      { name: "Dropoff", value: 0, percentage: 0, color: "#4F6D7A" },
      { name: "Drop & Service", value: 0, percentage: 0, color: "#D4C2FC" },
      { name: "Return", value: 0, percentage: 0, color: "#8B2635" },
      // { name: "Install", value: 0, percentage: 0, color: "#00BCD4" },
      { name: "Exchange", value: 0, percentage: 0, color: "#DD6E42" },
      { name: "Service", value: 0, percentage: 0, color: "#E8DAB2" },
    ],
    tasksStatusesTotal: 0,
    pieChartTasksStatuses: { value: "", text: "Success" },
    tasksStatuses: [
      { name: "Unassigned", value: 0, percentage: 0, color: "#B3B3B3" },
      { name: "Assigned", value: 0, percentage: 0, color: "#AE16F2" },
      { name: "Started", value: 0, percentage: 0, color: "#316BB4" },
      { name: "Success", value: 0, percentage: 0, color: "#00B330" },
      // { name: "Delayed", value: 0, percentage: 0, color: "#00BCD4" },
      { name: "Failed", value: 0, percentage: 0, color: "#D10015" },
    ],
    distanceTotal: 0,
    pieChartDistance: { value: "", text: "KM" },
    distance: [
      { name: "En route", value: 0, percentage: 0, color: "#4CAF50" },
    ],
    timeTotal: 0,
    pieChartTime: { value: "", text: "Min/Task" },
    time: [
      { name: "0-5 Min", value: 0, percentage: 0, color: "#4CAF50" },
      { name: "5-10 Min", value: 0, percentage: 0, color: "#9C27B0" },
      { name: "10-20 Min", value: 0, percentage: 0, color: "#FF9800" },
      { name: "20-30 Min", value: 0, percentage: 0, color: "#00BCD4" },
      { name: "30+ Min", value: 0, percentage: 0, color: "#F44336" },
    ]
  })

  const [barGraphData, setBarGraphData] = useState({
    tasksInput: { Pickup: "#EAEAEA", Dropoff: "#4F6D7A", "Drop & Service": "#D4C2FC", Return: "#8B2635", Exchange: "#DD6E42", Service: "#E8DAB2" },
    tasks: [
      // { Pickup: 0, Dropoff: 0, Exchange: 0, Install: 0, Return: 0 },
    ],
    tasksStatusInput: { Unassigned: "#B3B3B3", Assigned: "#AE16F2", Started: "#316BB4", Success: "#00B330", Failed: "#D10015" },
    tasksStatus: [
      // { Unassigned: 0, Assigned: 0, Started: 0, Completed: 0, Delayed: 0, Failed: 0 },
    ],
    distanceInput: { "En route": "#4CAF50" },
    distance: [
      // { "En Route": 0 },
    ],
    timeInput: { "0-5 Min": "#4CAF50", "5-10 Min": "#9C27B0", "10-20 Min": "#FF9800", "20-30 Min": "#00BCD4", "30+ Min": "#F44336" },
    time: [
      // { "0-5 Min": 0, "5-10 Min": 0, "10-20 Min": 0, "20-30 Min": 0, "30+ Min": 0 },
    ]
  })


  useEffect(() => {
    getTeamList()
    getUserList(USER_ROLES.AGENT)
  }, [])

  useEffect(() => {
    if (settings) {
      let findRegionalSetting = settings.find(setting => setting.subType === "REGIONAL_SETTINGS");
      if (findRegionalSetting) {
        if (findRegionalSetting.distanceUnit === "m") {
          setDistanceUnit("Miles")
        }
        else setDistanceUnit("KM")
      }
    }
  }, [settings])

  const getTeamList = async (params, setFieldValue) => {
    let payload = {
      limit: 100,
    };
    if (
      userData.role === 1 &&
      userData.userId &&
      userData.permittedUsers &&
      userData.permittedUsers.length
    ) {
      payload.userIds = userData.permittedUsers;
    }
    if (userData.teamIds) {
      payload.teamIds = userData.teamIds;
    }
    if (userData.subAdmin) {
      delete payload.teamIds;
      delete payload.userIds;
    }

    const currentUser = userData;
    if (currentUser && currentUser.role !== 1) payload.userId = currentUser.id;

    if (params && params.userId) {
      payload.userId = params.userId;
    }
    dispatch({ type: GET_TEAM_LIST, data: { payload } });
  };

  const getUserList = async (role) => {
    let payload = {
      limit: 1000,
      role,
    };

    const currentUser = userData;
    if (currentUser && currentUser.role !== 1) payload.userId = currentUser.id;

    if (
      userData.role === 1 &&
      userData.userId &&
      userData.permittedUsers &&
      userData.permittedUsers.length
    ) {
      payload.userIds = userData.permittedUsers;
    }

    if (userData.role === 2 && userData.userId) {
      payload.userIds = [userData.userId];
    }
    // if (userData.subAdmin) {
    //     delete payload.userIds;
    // }
    dispatch({
      type: GET_USER_API,
      data: {
        payload,
        saveInRedux: true,
      },
    });
  };

  useEffect(() => {
    getTaskPerformances()
  }, [date, selectedAgents, selectedTeams])

  const getTaskPerformances = () => {
    let query = {
      graphType: "DAILY"
    }
    if (selectedOption.id === "YEAR_TO_DATE" || selectedOption.id === "ALL_TIME") {
      query.graphType = "MONTHLY"
    }
    else if (selectedOption.id === "CUSTOM") {
      query.graphType = graphType
    }
    else if (!selectedOption) {
      query.graphType = "MONTHLY"
    }
    setGraphType(query.graphType)
    if (date?.startDate && date?.endDate) {
      query.startDate = +date?.startDate;
      query.endDate = +date?.endDate;
    }
    if (selectedAgents.length > 0) {
      query.agentIds = selectedAgents.map(i => i.id)
    }
    if (selectedTeams.length > 0) {
      query.teamIds = selectedTeams.map(i => i.id)
    }
    dispatch({
      type: GET_TASK_ANALYTICS,
      data: {
        payload: query,
        resCall: (res) => {
          setPerformanceData(res.data)
          let allDates = getDates(date?.startDate, date?.endDate);
          let allYearMonth = getMonthsBetweenDates(date?.startDate, date?.endDate);
          let tasks = res.data.tasks
          let distance = res.data.distance
          let tasksStatuses = res.data.tasksStatuses
          let time = res.data.time
          setAnalyticsTableData({ tasks, distance, tasksStatuses, time })
          if (query.graphType === "DAILY") {
            let barTasks = typeWiseBarData(allDates, tasks, "date", "date")
            let barTasksStatuses = typeWiseBarData(allDates, tasksStatuses, "date", "date")
            let barDistance = typeWiseBarData(allDates, distance, "date", "date", "distance")
            let barTime = typeWiseBarData(allDates, time, "date", "date")

            setBarGraphData((prev) => {
              return {
                ...prev,
                tasks: barTasks,
                tasksStatus: barTasksStatuses,
                distance: barDistance,
                time: barTime
              }
            })
          }
          else {
            let barTasks = typeWiseBarData(allYearMonth, tasks, "year", "year")
            let barTasksStatuses = typeWiseBarData(allYearMonth, tasksStatuses, "year", "year")
            let barDistance = typeWiseBarData(allYearMonth, distance, "year", "year", "distance")
            let barTime = typeWiseBarData(allYearMonth, time, "year", "year")

            setBarGraphData((prev) => {
              return {
                ...prev,
                tasks: barTasks,
                tasksStatus: barTasksStatuses,
                distance: barDistance,
                time: barTime
              }
            })
            // { name: "", Pickup: 0, Dropoff: 0, Exchange: 0, Install: 0, Return: 0 },
          }
        }
      }
    })
  }

  const typeWiseBarData = (allDates, typeData, keyToMatchInput, keyToMatchOutput, type) => {
    let data = []
    for (let date of allDates) {
      let findDateData = typeData.find(i => {
        if (keyToMatchInput === "date")
          return date[keyToMatchInput] === i[keyToMatchOutput]
        else
          return date === `${i.year}-${i.month}`
      });
      let obj = {};
      if (findDateData) {
        for (let objData of findDateData.analytics) {
          let count = objData.count;
          if (type === "distance" && distanceUnit !== "KM") {
            count = count * 0.621371
          }
          obj[objData.type] = count
        }
      }
      data.push({
        name: keyToMatchInput === "date" ? date.MMDD : date,
        ...obj
      })
    }
    if ((!allDates || allDates.length === 0) && typeData) {
      for (let type of typeData) {
        let obj = {};
        for (let analyticData of type.analytics) {
          // for (let objData of analyticData.analytics) {
          let count = analyticData.count
          if (type === "distance" && distanceUnit !== "KM") {
            count = count * 0.621371
          }
          obj[analyticData.type] = count
          // }
        }
        data.push({
          name: keyToMatchInput === "date" ? momentTz(type.date).format("MMM DD") : `${type.year}-${type.month}`,
          ...obj
        })

      }
    }
    return data
  }

  const setAnalyticsTableData = ({ tasks, distance, tasksStatuses, time }) => {
    let newAnalyticsData = JSON.parse(JSON.stringify(analyticsData))
    let allTaskTotal = tasks.reduce((prev, curr) => {
      return Number(prev) + Number(curr.total)
    }, 0)
    newAnalyticsData.tasksTotal = allTaskTotal

    let allDistanceTotal = distance.reduce((prev, curr) => {
      return Number(prev) + Number(curr.total)
    }, 0)
    newAnalyticsData.distanceTotal = allDistanceTotal

    let allTasksStatusTotal = tasksStatuses.reduce((prev, curr) => {
      return Number(prev) + Number(curr.total)
    }, 0)
    newAnalyticsData.tasksStatusesTotal = allTasksStatusTotal

    let allTimeTotalTasks = time.reduce((prev, curr) => {
      return Number(prev) + Number(curr.total)
    }, 0)
    let allTimeTotal = time.reduce((prev, curr) => {
      return Number(prev) + Number(curr.timeTotal)
    }, 0)
    newAnalyticsData.timeTotal = allTimeTotalTasks
    let pieChartTasks = `${allTaskTotal}`;
    let pieChartTasksStatuses = "0";
    let pieChartDistance = allDistanceTotal
    let pieChartTime = Math.floor((allTimeTotal / 60000) / allTimeTotalTasks)
    for (let data of newAnalyticsData.tasks) {
      data.value = tasks.reduce((prev, curr) => {
        let findCurrentValue = curr.analytics.find(i => i.type === data.name);
        return Number(prev) + (Number(findCurrentValue?.count) || 0)
      }, 0)
      data.percentage = data.value ? Math.floor(((Number(data.value) / Number(allTaskTotal)) * 100)).toFixed(2) : 0
    }
    for (let data of newAnalyticsData.tasksStatuses) {
      data.value = tasksStatuses.reduce((prev, curr) => {
        let findCurrentValue = curr.analytics.find(i => i.type === data.name);
        return Number(prev) + (Number(findCurrentValue?.count) || 0)
      }, 0)

      data.percentage = data.value ? Math.floor(((Number(data.value) / Number(allTasksStatusTotal)) * 100)).toFixed(2) : 0
      if (data.name === "Success") {
        pieChartTasksStatuses = `${data.percentage}%`
      }
    }

    for (let data of newAnalyticsData.distance) {
      data.value = distance.reduce((prev, curr) => {
        let findCurrentValue = curr.analytics.find(i => i.type === data.name);
        return Number(prev) + (Number(findCurrentValue?.count) || 0)
      }, 0)

      data.percentage = data.value ? Math.floor(((Number(data.value) / Number(allDistanceTotal)) * 100)).toFixed(2) : 0
    }
    for (let data of newAnalyticsData.time) {
      data.value = time.reduce((prev, curr) => {
        let findCurrentValue = curr.analytics.find(i => i.type === data.name);
        return Number(prev) + (Number(findCurrentValue?.count) || 0)
      }, 0)
      data.percentage = data.value ? Math.floor(((Number(data.value) / Number(allTimeTotalTasks)) * 100)).toFixed(2) : 0
    }
    newAnalyticsData.pieChartTasks = { value: pieChartTasks, text: "Tasks" }
    newAnalyticsData.pieChartTasksStatuses = { value: pieChartTasksStatuses, textTop: "Success Rate", text: "" }
    if (distanceUnit !== "KM") {
      pieChartDistance = pieChartDistance * 0.621371
    }
    newAnalyticsData.pieChartDistance = { value: Math.ceil(pieChartDistance), text: distanceUnit }
    newAnalyticsData.pieChartTime = { value: `${pieChartTime}`, text: "Min/Task" }
    setAnalyticsData(newAnalyticsData)
  }

  const onSelectOption = (e) => {
    setSelectedOption(e)
    if (e.id === "TODAY") {
      setDate({
        startDate: momentTz().startOf("D"),
        endDate: momentTz().endOf("D")
      })
    }
    else if (e.id === "LAST_7_DAYS") {
      setDate(getLast7Days())
    }
    else if (e.id === "MONTH_TO_DATE") {
      setDate(getMonthToDate())
    }
    else if (e.id === "QUARTER_TO_DATE") {
      setDate(getQuarterToDate())
    }
    else if (e.id === "YEAR_TO_DATE") {
      setDate(getYearToDate())
    }
    else if (e.id === "ALL_TIME") {
      setDate({ startDate: null, endDate: null })
    }
  }
  const onSelectAgent = (e) => {
    setSelectedAgents(e)
  }
  const onSelectTeams = (e) => {
    setSelectedTeams(e)
  }

  const [value, setValue] = useState([new Date(), new Date()]);
  const onChange = (e) => {
    setValue(e);

    if (e) {
      setDate({
        startDate: momentTz(e[0]).startOf("D"),
        endDate: momentTz(e[1]).endOf("D")
      })
      if (momentTz(e[1]).diff(momentTz(e[0]), "M") > 2) {
        setGraphType("MONTHLY")
      }
    }
    else {
      setDate({
        startDate: null,
        endDate: null
      })
    }
  }

  const [isSummaryReportEnabled, setSummaryReportEnabled] = useState(true);

  const onClickSummaryReport = () => {
    let teams = ["All Teams"], agents = ["All Drivers"];
    if (selectedTeams.length > 0) {
      teams = []
      for (let team of selectedTeams) {
        teams.push(team.teamName)
      }
    }
    if (selectedAgents.length > 0) {
      agents = []
      for (let agent of selectedAgents) {
        agents.push(`${agent.firstName} ${agent.lastName}`)
      }
    }
    let alltaskTypes = ["Pickup", "Pickup %", "Dropoff", "Dropoff %", "Drop & Service", "Drop & Service %", "Return", "Return %", "Exchange", "Exchange %", "Service", "Service %"];
    let alltaskstatuses = ["Unassigned", "Unassigned %", "Assigned", "Assigned %", "Started", "Started %", "Success", "Success %", "Failed", "Failed %"]
    let alltaskTimes = ["5-10 Min", "5-10 Min %", "10-20 Min", "10-20 Min %", "20-30 Min", "20-30 Min %", "30+ Min", "30+ Min %"]
    let alltaskDistances = ["En route"]
    const data = [
      ["Report Date", "Period", "Teams", "Drivers"],
      [momentTz().format("YYYY-MM-DD"), selectedOption.id === "ALL_TIME" ? "All Time" : `${momentTz(date.startDate).format("MMMM DD, YYYY")}-${momentTz(date.endDate).format("MMMM DD, YYYY")}`, teams.join(", "), agents.join(", ")],
      [],
      ["Date", ...alltaskTypes, "Total Task", ...alltaskstatuses, "Success Rate", "Distance Covered", ...alltaskTimes, "Average Time"],
    ];
    if (performanceData) {
      let allDates = getDates(date?.startDate, date?.endDate);
      let allYearMonth = getMonthsBetweenDates(date?.startDate, date?.endDate);
      let allTimeData = []
      if ((!allDates || allDates.length === 0) && (!allYearMonth || allYearMonth.length === 0)) {
        for (let task of performanceData.tasks) {
          allTimeData.push(`${task.year}-${task.month}`)
        }
      }
      let tasks = performanceData.tasks
      let distance = performanceData.distance
      let tasksStatuses = performanceData.tasksStatuses
      let time = performanceData.time
      if (graphType === "DAILY") {
        if (allTimeData.length > 0) {
          for (let yearMonth of allTimeData) {
            let array = [yearMonth];
            let xlsxTask = typeWiseXLSXData(yearMonth, tasks, "year", "year")
            let xlsxDistance = typeWiseXLSXData(yearMonth, distance, "year", "year")
            let xlsxTasksStatus = typeWiseXLSXData(yearMonth, tasksStatuses, "year", "year")
            let xlsxTime = typeWiseXLSXData(yearMonth, time, "year", "year")
            array.push(...formXlsxData(xlsxTask, alltaskTypes))
            array.push(xlsxTask.total || 0)
            array.push(...formXlsxData(xlsxTasksStatus, alltaskstatuses))
            array.push(xlsxTasksStatus["Success %"] || "0%")
            array.push(...formXlsxData(xlsxDistance, alltaskDistances))
            array.push(...formXlsxData(xlsxTime, alltaskTimes))
            array.push(Math.floor(xlsxTime.totalTime / xlsxTime.total) || 0)
            data.push(array)
          }
        }
        else
          for (let date of allDates) {
            let array = [date.date];
            let xlsxTask = typeWiseXLSXData(date, tasks, "date", "date")
            let xlsxDistance = typeWiseXLSXData(date, distance, "date", "date")
            let xlsxTasksStatus = typeWiseXLSXData(date, tasksStatuses, "date", "date")
            let xlsxTime = typeWiseXLSXData(date, time, "date", "date")
            array.push(...formXlsxData(xlsxTask, alltaskTypes))
            array.push(xlsxTask.total || 0)
            array.push(...formXlsxData(xlsxTasksStatus, alltaskstatuses))
            array.push(xlsxTasksStatus["Success %"] || "0%")
            array.push(...formXlsxData(xlsxDistance, alltaskDistances))
            array.push(...formXlsxData(xlsxTime, alltaskTimes))
            array.push(Math.floor(xlsxTime.totalTime / xlsxTime.total) || 0)
            data.push(array)
          }
      }
      else {
        if (allTimeData.length > 0) {
          for (let yearMonth of allTimeData) {
            let array = [yearMonth];
            let xlsxTask = typeWiseXLSXData(yearMonth, tasks, "year", "year")
            let xlsxDistance = typeWiseXLSXData(yearMonth, distance, "year", "year")
            let xlsxTasksStatus = typeWiseXLSXData(yearMonth, tasksStatuses, "year", "year")
            let xlsxTime = typeWiseXLSXData(yearMonth, time, "year", "year")
            array.push(...formXlsxData(xlsxTask, alltaskTypes))
            array.push(xlsxTask.total || 0)
            array.push(...formXlsxData(xlsxTasksStatus, alltaskstatuses))
            array.push(xlsxTasksStatus["Success %"] || "0%")
            array.push(...formXlsxData(xlsxDistance, alltaskDistances))
            array.push(...formXlsxData(xlsxTime, alltaskTimes))
            array.push(Math.floor(xlsxTime.totalTime / xlsxTime.total) || 0)
            data.push(array)
          }
        }
        else
          for (let yearMonth of allYearMonth) {
            let array = [yearMonth];
            let xlsxTask = typeWiseXLSXData(yearMonth, tasks, "year", "year")
            let xlsxDistance = typeWiseXLSXData(yearMonth, distance, "year", "year")
            let xlsxTasksStatus = typeWiseXLSXData(yearMonth, tasksStatuses, "year", "year")
            let xlsxTime = typeWiseXLSXData(yearMonth, time, "year", "year")
            array.push(...formXlsxData(xlsxTask, alltaskTypes))
            array.push(xlsxTask.total || 0)
            array.push(...formXlsxData(xlsxTasksStatus, alltaskstatuses))
            array.push(xlsxTasksStatus["Success %"] || "0%")
            array.push(...formXlsxData(xlsxDistance, alltaskDistances))
            array.push(...formXlsxData(xlsxTime, alltaskTimes))
            array.push(Math.floor(xlsxTime.totalTime / xlsxTime.total) || 0)
            data.push(array)
          }
      }
    }

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    XLSX.writeFile(workbook, `Performance_${momentTz().format("YYMMDD")}.xlsx`);
  }

  const formXlsxData = (typeData, options) => {
    let array = [];
    for (let option of options) {
      if (typeData[option]) array.push(typeData[option])
      else array.push(option.includes("%") ? "0%" : 0)
    }
    return array
  }

  const typeWiseXLSXData = (date, typeData, keyToMatchInput, keyToMatchOutput) => {
    let findDateData = typeData.find(i => {
      if (keyToMatchInput === "date")
        return date[keyToMatchInput] === i[keyToMatchOutput]
      else
        return date === `${i.year}-${i.month}`
    });
    let obj = {};
    if (findDateData) {
      for (let objData of findDateData.analytics) {
        obj[objData.type] = objData.count
        obj[`${objData.type} %`] = `${((objData.count / findDateData.total) * 100).toFixed(2)}%`
        obj.total = findDateData.total
        obj.totalTime = findDateData.timeTotal / 60000
      }
    }
    return {
      name: keyToMatchInput === "date" ? date.MMDD : date,
      ...obj
    }
  }

  return (
    <>
      <ContentLayout>
        {/* Performance layout */}
        <div className="performance-page-wrapper">
          <div className="performance-heading-box">
            <h1>perfomance</h1>
          </div>
          <div className="perfomance-chart">
            <div className="perforance-searchbar">
              <div className="searchbar">
                <Row>
                  <Col xs={3}>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      defaultValue={Options[0]}
                      value={
                        selectedOption
                      }
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={false}
                      name="color"
                      onChange={(newValue, actionMeta) => {
                        onSelectOption(newValue)
                      }}
                      getOptionLabel={(option) => `${option.value}`}
                      getOptionValue={(option) => option.id}
                      options={Options}
                    />
                  </Col>
                  {selectedOption.id === "CUSTOM" && <Col xs={3} className="picker_wrapper">
                    <DateRangePicker
                      showDoubleView={true}
                      onChange={onChange}
                      value={value} />
                  </Col>}
                  <Col xs={3}>
                    <Select
                      className="react-select-container" F
                      classNamePrefix="react-select"
                      placeholder={"All Team"}
                      value={
                        selectedTeams
                      }
                      isMulti={true}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={false}
                      isSearchable={false}
                      name="color"
                      onChange={(newValue, actionMeta) => {
                        onSelectTeams(newValue)
                      }}
                      getOptionLabel={(option) => `${option.teamName}`}
                      getOptionValue={(option) => option.id}
                      options={teamList}
                    />
                  </Col>
                  <Col xs={3}>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder={"All Driver"}
                      value={
                        selectedAgents
                      }
                      isMulti={true}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={false}
                      isSearchable={false}
                      name="color"
                      onChange={(newValue, actionMeta) => {
                        onSelectAgent(newValue)
                      }}
                      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                      getOptionValue={(option) => option.id}
                      options={agentList}
                    />
                  </Col>
                </Row>
              </div>
              <div className="right-selectoption">
                <div className="export-container">
                  <button className="export-button">Export <FaAngleDown /></button>
                  <div className={`dropdown-menu ${isSummaryReportEnabled ? 'enabled' : 'disabled'}`}>
                    <div className="menu-item summary-report" onClick={onClickSummaryReport}>Summary Report</div>
                  </div>
                </div>
                {/* <Button className="scicon"><img src={justifyicon} alt="icon" /></Button> */}
              </div>
            </div>
            <div className="performance-task">
              <span className="performance-task-span">TASKS</span>
              <p >Number of tasks per type</p>
              <Row className="chart-row">
                <Col xs={3}>
                  <PieChartComponent data={analyticsData.tasks} textValue={analyticsData.pieChartTasks} />
                </Col>
                <Col xs={2}>
                  <TaskList data={analyticsData.tasks} />
                </Col>
                <Col xs={7}>
                  <StackedBarChartComponent data={barGraphData.tasks} input={barGraphData.tasksInput} />
                </Col>
              </Row>
            </div>
            <div className="performance-task">
              <span className="performance-task-span">TASKS STATUS</span>
              <p >Task Status Summary</p>
              <Row className="chart-row">
                <Col xs={3}>
                  <PieChartComponent data={analyticsData.tasksStatuses} textValue={analyticsData.pieChartTasksStatuses} />
                </Col>
                <Col xs={2}>
                  <TaskList data={analyticsData.tasksStatuses} />
                </Col>
                <Col xs={7}>
                  <StackedBarChartComponent data={barGraphData.tasksStatus} input={barGraphData.tasksStatusInput} />
                </Col>
              </Row>
            </div>
            <div className="performance-task">
              <span className="performance-task-span">DISTANCE</span>
              <p>Total distance covered</p>
              <Row className="chart-row">
                <Col xs={3}>
                  <PieChartComponent data={analyticsData.distance} textValue={analyticsData.pieChartDistance} />
                </Col>
                <Col xs={2}>
                  {/* <TaskList data={analyticsData.distance} /> */}
                </Col>
                <Col xs={7}>
                  <StackedBarChartComponent data={barGraphData.distance} input={barGraphData.distanceInput} hideLegend={true} />
                </Col>
              </Row>
            </div>
            <div className="performance-task">
              <span className="performance-task-span">TIME</span>
              <p>Service time per month</p>
              <Row className="chart-row">
                <Col xs={3}>
                  <PieChartComponent data={analyticsData.time} textValue={analyticsData.pieChartTime} />
                </Col>
                <Col xs={2}>
                  <TaskList data={analyticsData.time} />
                </Col>
                <Col xs={7}>
                  <StackedBarChartComponent data={barGraphData.time} input={barGraphData.timeInput} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default Performance;
