import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StackedBarChartComponent = ({ data, input, hideLegend }) => {
    const demoUrl = 'https://codesandbox.io/p/sandbox/stacked-bar-chart-7fwfgj';
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={600}
                height={300}
                data={data}
                margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid vertical={false} strokeLinecap="3" stroke="#000000" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip cursor={{ fill: '#272735' }}/>
                {!hideLegend && <Legend />}
                {Object.keys(input).map((d, i) => {
                    return <Bar dataKey={d} stackId={"a"} fill={Object.values(input)[i]} key={i} maxBarSize={20} />
                })}
                {/* <Bar dataKey="Pickup" stackId="a" fill="#4CAF50" />
                <Bar dataKey="Dropoff" stackId="b" fill="#9C27B0" />
                <Bar dataKey="Exchange" stackId="c" fill="#FF9800" />
                <Bar dataKey="Install" stackId="d" fill="#00BCD4" />
                <Bar dataKey="Return" stackId="e" fill="#F44336" /> */}
            </BarChart>
        </ResponsiveContainer>
    );
}

export default StackedBarChartComponent;