import React from "react";
import { Switch, BrowserRouter, Route, withRouter, useLocation } from 'react-router-dom';
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import Dashboard from "./pages/dashboard/Dashboard";
import Planner from "./pages/Planner/Planner";
import Hub from "./pages/hub/hub";
import Customer from "./pages/customer/customer";
import Merchant from "./pages/Merchant/MerchantNew";
import Users from "./pages/Users/user";
import Deliveryinfo from "./components/signup/delivery";
import Page404 from "./pages/Page404";
import PrivateRoute from "./routes/PrivateRoutes"
import SuperPrivateRoute from "./routes/SuperPrivate";
import SuperAndSubadminRoutes from "./routes/SuperAndSubadminRoutes";
import PublicRoute from "./routes/PublicRoutes";
import Team from "./pages/Team/Team";
import Settings from "./pages/Settings/Settings";
import Builder from "./pages/Builder/Builder";
import Library from "./pages/Library/Library";
import ResetPassword from "./pages/Auth/ResetPassword";
import SetPassword from "./pages/Auth/SetNewPassword";
import PasswordOtp from './pages/Auth/PasswordOtp';
import ForgetPassword from './pages/Auth/ForgetPassword';
import Performance from "./pages/Performance/Performance";
import LibraryCategory from "./pages/LibraryCategory/LibraryCategory";
import BuilderCategory from "./pages/BuilderCategory/BuilderCategory";
import ImportPopup from "./pages/Importpopup/Importpopup";
import SupervisePlannerControl from "./pages/SupervisePlanner/pages/container/main";
import PublicTracking from "./pages/Public/PublicTracking";
import PublicResponsibilityDischarge from "./pages/Public/PublicResponsibilityDischarge";
import PublicDeliveryConfirmation from "./pages/Public/PublicDeliveryConfirmation";
import PublicScheduleDelivery from "./pages/Public/PublicScheduleDelivery/PublicScheduleDelivery";
import InvoicingCustomer from "./pages/InvoicingCustomer/container/main";
import Invoicing from "./pages/Invoicing/container/main";
function App(props) {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path="/" restricted={true} component={Login} />
          <PublicRoute restricted={true} path="/signup" component={Signup} />
          <PublicRoute restricted={true} path="/login" component={Login} />
          <Route path="/delivery" component={Deliveryinfo} />
          {/* <PrivateRoute path="/Dashboard" component={Dashboard} />*/}
          <PrivateRoute path="/Planner" component={Planner} />
          <PrivateRoute path="/Dashboard" component={SupervisePlannerControl} />
          <PrivateRoute path="/ImportPopup" component={ImportPopup} />
          {/* <PrivateRoute path="/Map" component={Map} /> */}
          <PrivateRoute path="/Tasks" component={Customer} />
          <PrivateRoute path="/Hub" component={Hub} />
          <SuperAndSubadminRoutes path="/Merchant" component={Merchant} />
          <PrivateRoute path="/Users" component={Users} />
          <SuperAndSubadminRoutes path="/Team" component={Team} />
          {/* <Route path="/Team-d" component={TeamDesign} /> */}
          <PrivateRoute path="/Settings" component={Settings} />
          {/* <Route path="/Business/:categoryId?" component={Business} /> */}
          <Route path="/Business/:categoryId?" component={Builder} />
          <SuperAndSubadminRoutes path="/Library" component={Library} />
          {/* <Route path="/Merchant-d" component={MerchantDesign} /> */}
          <SuperAndSubadminRoutes path="/Performance" component={Performance} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route path="/set-password/:token" component={SetPassword} />
          <Route path="/password-otp" component={PasswordOtp} />
          <Route path="/forgot-password" component={ForgetPassword} />
          <SuperAndSubadminRoutes path="/LibraryCategory" component={LibraryCategory} />
          <SuperPrivateRoute path="/BuilderCategory" component={BuilderCategory} />
          <PublicRoute path="/tracking/:id" component={PublicTracking} />
          {/* <PublicRoute path="/trackingDummy" component={PublicTrackingDummy} /> */}
          <PublicRoute path="/responsibility-discharge/:id" component={PublicResponsibilityDischarge} />
          <PublicRoute path="/delivery-confirmation/:id" component={PublicDeliveryConfirmation} />
          <PublicRoute path="/schedule-delivery/:id" component={PublicScheduleDelivery} />
          <PublicRoute path="/Customer" component={InvoicingCustomer} />
          <PublicRoute path="/Invoice" component={Invoicing} />
          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default withRouter(App);
