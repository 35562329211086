import { useState } from "react";
import MissionSuprviseSide from "../MissionAccordian/MissionSuprviseSide";
import "./Section.scss"
import Tasks from "../MissionAccordian/Task";
import appDefaults from "../../../../helpers/app-defaults";
import { useDispatch, useSelector } from "react-redux";
import { missionClickhandler, missionTaskSingleClickHandler, taskSingleClickHandler } from "../clickHandlers";
import { TASK_STATUSES } from "../../../../helpers/configDefaults";
import { delay } from "../../../../helpers/helperFunctions";
import { CUSTOM_SAVE_TASKS_REDUX, SAVE_TASK_POSITION_API } from "../../../../redux/task/actionTypes";
import optimize from "../../../../helpers/OptimizeMission";
import { SHOW_LOADER } from "../../../../redux/auth/actionTypes";


const unAssignedTaskString = `dummm-${btoa(
    Math.random().toString()
).substring(0, 16)}`;

const assignedMissionTaskString = `dummm-${btoa(
    Math.random().toString()
).substring(0, 16)}`;

const unAssignedMissionTaskString = `dummm-${btoa(
    Math.random().toString()
).substring(0, 16)}`;

const completedMissionTaskString = `dummm-${btoa(
    Math.random().toString()
).substring(0, 16)}`;
const unAssignedMissionString = `dummm-${btoa(
    Math.random().toString()
).substring(0, 16)}`;

const assignedMissionString = `dummm-${btoa(
    Math.random().toString()
).substring(0, 16)}`;

const completedMissionString = `dummm-${btoa(
    Math.random().toString()
).substring(0, 16)}`;

const Sections = (props) => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.auth.user)
    const superAdminRole = userData && userData.role && userData.role === 1 ? true : false;
    let tab = 'left';
    if (props.rightSideBar) {
        console.log("props.rightSideBar", props.rightSideBar)
        let lastSelectedTab = localStorage.getItem("lastSelectedTab");
        if (lastSelectedTab) {
            tab = lastSelectedTab
        }
    }
    const [activeTab, setActiveTab] = useState(tab);
    const { missionsList, tasksList, unAssignedTasksList, tasksListWithouDate, tasksListPendingAction, datedTaskList } = useSelector((state) => state.task)
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (props.rightSideBar) {
            localStorage.setItem("lastSelectedTab", tab);
        }
    }
    const getContextAccess = ({ string, id, createdBy, userId }) => {
        let allContextMenuAccess = {
            createTask: false,
            createMission: false,
            editTask: false,
            deleteTask: false,
            duplicateTask: false,
            deleteMission: false,
            optimizeMission: false,
            assignMission: false,
            changeStatus: false,
            unAssignMission: false,
            printManifest: false,
            removeSelectedTask: false,
            linkTasks: false,
            changeMissionDate: false,
            duplicateMission: false,
            scheduleDelivery: false
        };
        if (string && userData) {
            if (superAdminRole) {
                if (
                    string === unAssignedTaskString ||
                    string === assignedMissionTaskString ||
                    string === unAssignedMissionTaskString
                ) {
                    allContextMenuAccess.editTask = true;
                    allContextMenuAccess.deleteTask = true;
                    allContextMenuAccess.duplicateTask = true;
                    allContextMenuAccess.scheduleDelivery = true;
                    if (string === unAssignedTaskString) {
                        allContextMenuAccess.createMission = true;
                    }
                    if (
                        string === assignedMissionTaskString ||
                        string === unAssignedMissionTaskString
                    ) {
                        allContextMenuAccess.changeStatus = true;
                        allContextMenuAccess.removeSelectedTask = true;
                        allContextMenuAccess.linkTasks = true;
                    }
                }
                if (string === completedMissionTaskString) {
                    allContextMenuAccess.duplicateTask = true;
                }
                if (
                    string === assignedMissionString ||
                    string === unAssignedMissionString
                ) {
                    allContextMenuAccess.assignMission = true;
                    allContextMenuAccess.unAssignMission = true;
                    allContextMenuAccess.optimizeMission = true;
                    allContextMenuAccess.deleteMission = true;
                    allContextMenuAccess.printManifest = true;
                    allContextMenuAccess.changeMissionDate = true;
                    allContextMenuAccess.duplicateMission = true;
                }
                if (string === completedMissionString) {
                    allContextMenuAccess.printManifest = true;
                }
            }
            else {
                if (
                    string === unAssignedTaskString ||
                    string === assignedMissionTaskString ||
                    string === unAssignedMissionTaskString
                ) {
                    if (
                        (userData.role === 2 && !userData.userId) ||
                        (userData.role === 2 &&
                            (userData.userRole === "manager" ||
                                userData.userRole === "admin")) ||
                        (userData.role === 4 && userData.userRole === "crud")
                    ) {
                        allContextMenuAccess.editTask = true;
                        allContextMenuAccess.deleteTask = true;
                        allContextMenuAccess.duplicateTask = true;
                        allContextMenuAccess.scheduleDelivery = true;
                        if (string === unAssignedTaskString) {
                            allContextMenuAccess.createMission = true;
                        }
                        if (
                            string === assignedMissionTaskString ||
                            string === unAssignedMissionTaskString
                        ) {
                            allContextMenuAccess.changeStatus = true;
                            allContextMenuAccess.removeSelectedTask = true;
                            allContextMenuAccess.linkTasks = true;
                        }
                    }
                }

                if (string === completedMissionTaskString) {
                    allContextMenuAccess.duplicateTask = true;
                }
                if (
                    string === assignedMissionString ||
                    string === unAssignedMissionString ||
                    string === completedMissionString
                ) {
                    if (
                        string === assignedMissionString ||
                        string === unAssignedMissionString
                    ) {
                        if (
                            (userData.role === 2 && !userData.userId) ||
                            (userData.role === 2 &&
                                (userData.userRole === "manager" ||
                                    userData.userRole === "admin")) ||
                            (userData.role === 4 && userData.userRole === "crud")
                        ) {
                            if (
                                createdBy === userData.id ||
                                createdBy === userData.userId ||
                                userId === userData.id ||
                                userId === userData.userId
                            ) {
                                allContextMenuAccess.assignMission = true;
                                allContextMenuAccess.unAssignMission = true;
                                allContextMenuAccess.optimizeMission = true;
                                allContextMenuAccess.deleteMission = true;
                                allContextMenuAccess.changeMissionDate = true;
                                allContextMenuAccess.duplicateMission = true;
                            }
                        }
                    }
                    allContextMenuAccess.printManifest = true;
                    allContextMenuAccess.duplicateMission = true;
                }
            }

        }
        return allContextMenuAccess;
    }
    const taskContextHandler = (e, task) => {
        let contextAccess = getContextAccess({ string: unAssignedTaskString });
        e.preventDefault()
        let menu = {
            show: true,
            position: {
                clientX: e.clientX,
                clientY: e.clientY
            },
            type: appDefaults.CONTEXT_MENU_TYPE.TASKS,
            data: {

            },
            menu: [{
                clickMethod: props.editMenuHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.EDIT_TASK,
                hideContextMenu: false
            }, {
                clickMethod: props.deleteMenuHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.DELETE_TASK,
                hideContextMenu: false
            }, {
                clickMethod: props.sectionType === appDefaults.SECTIONS.SUPERVISE_CONTROL || props.sectionType === appDefaults.SECTIONS.RESIZE_CONTAINER ? props.createMissionApiHandler : props.scheduleDeliveryHandler,
                menuItem: props.sectionType === appDefaults.SECTIONS.SUPERVISE_CONTROL || props.sectionType === appDefaults.SECTIONS.RESIZE_CONTAINER ? appDefaults.CONTEXT_MENU_ITEMS.CREATE_MISSION : appDefaults.CONTEXT_MENU_ITEMS.SCHEDULE_DELIVERY,
                hideContextMenu: false
            },
            {
                clickMethod: props.duplicateTaskHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.DUPLICATE_TASK,
                hideContextMenu: false
            }
            ]
        }
        props.contextMenuRef.current = menu
        props.setContextMenu(menu)
    }
    const missionTaskContextHandler = (e, task) => {
        let string = task.taskCategory === "assigned"
            ? assignedMissionTaskString
            : task.taskCategory === "completed"
                ? completedMissionTaskString
                : unAssignedMissionTaskString
        let contextAccess = getContextAccess({ string: string });
        e.preventDefault()
        let menu = {
            show: true,
            position: {
                clientX: e.clientX,
                clientY: e.clientY
            },
            type: appDefaults.CONTEXT_MENU_TYPE.TASKS,
            data: {

            },
            menu: [{
                clickMethod: props.editMenuHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.EDIT_TASK,
                hideContextMenu: !contextAccess.editTask
            }, {
                clickMethod: props.changeStatusHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.CHANGE_STATUS,
                hideContextMenu: !contextAccess.changeStatus
            },
            {
                clickMethod: props.removeTaskHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.REMOVE_TASK,
                hideContextMenu: !contextAccess.removeSelectedTask
            },
            {
                clickMethod: props.deleteMenuHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.DELETE_TASK,
                hideContextMenu: !contextAccess.deleteTask
            },
            {
                clickMethod: props.linkClickHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.LINK_TASKS,
                hideContextMenu: !contextAccess.linkTasks
            },
            {
                clickMethod: props.duplicateTaskHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.DUPLICATE_TASK,
                hideContextMenu: !contextAccess.duplicateTask
            }]
        }
        props.contextMenuRef.current = menu
        props.setContextMenu(menu)
    }
    const missionContextHandler = (e, mission) => {
        let string = unAssignedMissionString;
        if (mission.missionCategory === TASK_STATUSES.ASSIGNED) {
            string = assignedMissionString
        }
        else if (mission.missionCategory === TASK_STATUSES.COMPLETED) {
            string = completedMissionString
        }
        let contextAccess = getContextAccess({ string: string, id: mission.id, userId: mission.userId?.id, createdBy: mission.createdBy });

        e.preventDefault()
        let menu = {
            show: true,
            position: {
                clientX: e.clientX,
                clientY: e.clientY
            },
            type: appDefaults.CONTEXT_MENU_TYPE.MISSION,
            data: {

            },
            menu: [{
                clickMethod: props.assignmenuClickHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.ASSIGN_MISSION,
                hideContextMenu: !contextAccess.assignMission
            },
            {
                clickMethod: props.unassignmenuClickHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.UNASSIGN_MISSION,
                hideContextMenu: !contextAccess.unAssignMission
            },
            {
                clickMethod: props.optimiseMenuClickHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.OPTIMIZE_MISSION,
                hideContextMenu: !contextAccess.optimizeMission
            },
            {
                clickMethod: props.deleteMissionClickHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.DELETE_MISSION,
                hideContextMenu: !contextAccess.deleteMission
            },
            {
                clickMethod: props.printManifestHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.PRINT_MISSION,
                hideContextMenu: !contextAccess.printManifest
            },
            {
                clickMethod: props.updateMissionDateHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.UPDATE_MISSION_DATE,
                hideContextMenu: !contextAccess.changeMissionDate
            },
            {
                clickMethod: props.duplicateMissionHandler,
                menuItem: appDefaults.CONTEXT_MENU_ITEMS.DUPLICATE_MISSION,
                hideContextMenu: !contextAccess.duplicateMission
            }]
        }
        props.contextMenuRef.current = menu
        props.setContextMenu(menu)

    }
    const missionTaskClickHandler = (e, task) => {
        missionTaskSingleClickHandler({
            e, task, i: null, dispatch, unassignedTaskList: JSON.parse(JSON.stringify(unAssignedTasksList)),
            ...props,
            missionsList: JSON.parse(JSON.stringify(missionsList))
        })
    }

    return (<>
        {props.sectionType === appDefaults.SECTIONS.SUPERVISE_CONTROL || props.sectionType === appDefaults.SECTIONS.RESIZE_CONTAINER ?
            <div className="missions-section">
                <div className="headers">
                    <div className={`left ${activeTab === 'left' ? 'active' : ''}`} onDragOver={() => handleTabClick('left')} onClick={() => handleTabClick('left')}>
                        <p className={`${props.sectionType !== appDefaults.SECTIONS.RESIZE_CONTAINER ? "text-size" : ""}`}>Unassigned</p>
                    </div>
                    <div className={`middle ${activeTab === 'middle' ? 'active' : ''}`} onDragOver={() => handleTabClick('middle')} onClick={() => handleTabClick('middle')}>
                        <p className={`${props.sectionType !== appDefaults.SECTIONS.RESIZE_CONTAINER ? "text-size" : ""}`}>Assigned</p>
                    </div>
                    <div className={`right ${activeTab === 'right' ? 'active' : ''}`} onClick={() => handleTabClick('right')}>
                        <p className={`${props.sectionType !== appDefaults.SECTIONS.RESIZE_CONTAINER ? "text-size" : ""}`}>Completed</p>
                    </div>
                </div>
                {props.sectionType === appDefaults.SECTIONS.SUPERVISE_CONTROL ? <div className="containers" style={{ minHeight: props.minHeight }}>
                    <div className={`container container-left active`}>
                        {activeTab === "left" && unAssignedTasksList?.map((task, index) => {
                            return (<div className="task-wrapper" key={index}
                                onClick={(e) => {
                                    taskSingleClickHandler({
                                        e, task, i: index, ...props, unassignedTaskList: unAssignedTasksList, dispatch, taskToUpdate: "unAssignedTasksList"
                                    })
                                }}
                                onContextMenu={(e) => {
                                    taskContextHandler(e, task)
                                    taskSingleClickHandler({
                                        e, task, i: index, ...props, unassignedTaskList: unAssignedTasksList, dispatch, taskToUpdate: "unAssignedTasksList"
                                    })
                                }}>
                                <Tasks task={task} enablePropagation={true} unassignedList={true} dragStart={props.dragStart} />
                            </div>)
                        })}
                        {missionsList?.filter(m => {
                            let statusMatch = TASK_STATUSES.UNASSIGNED;
                            if (activeTab === "middle") {
                                statusMatch = TASK_STATUSES.ASSIGNED
                            }
                            else if (activeTab === "right") {
                                statusMatch = TASK_STATUSES.COMPLETED
                            }
                            return m.missionCategory === statusMatch
                        }).map((mission, index) => {
                            return (<div className="mission-wrapper" id={"missions"}
                                // onDragOver={dragOver}
                                // onDrop={(e) => { if (category !== "completed") onDrop(e, data) }}
                                onClick={(e) => {
                                    props.setSelectedMission(mission)
                                    let obj = {};
                                    obj[mission.id] = !props.toggleState[mission.id];
                                    props.setToggleState({ ...props.toggleState, ...obj });
                                    missionClickhandler({ mission, preventDirection: false, ...props });
                                }}
                                onContextMenu={async (e) => {
                                    e.preventDefault();
                                    missionContextHandler(e, mission)
                                    let obj = {};
                                    obj[mission.id] = !props.toggleState[mission.id];
                                    props.setToggleState({ ...props.toggleState, ...obj });
                                    missionClickhandler({ mission, preventDirection: false, ...props });
                                    await delay(400)
                                    props.setSelectedMission(mission)
                                }} key={index}>
                                <MissionSuprviseSide
                                    updateMission={props.updateMission}
                                    dragOver={props.dragOver}
                                    onDropMission={props.onDropMission}
                                    activeTab={activeTab}
                                    mission={mission} distance={props.distance}
                                    toggleState={props.toggleState}
                                    _onListChange={props._onListChange}
                                    missionTaskContextHandler={missionTaskContextHandler}
                                    missionTaskClickHandler={missionTaskClickHandler} />
                            </div>)
                        })}
                    </div>
                </div> : <div className="containers">
                    <div className={`container container-left active`}>
                        {activeTab === "left" && props.unAssignedTasksList?.map((task, index) => {
                            return (<div className="task-wrapper" key={index}
                                onClick={(e) => {
                                    taskSingleClickHandler({
                                        e, task, i: index, ...props, unassignedTaskList: tasksList, dispatch, taskToUpdate: "tasksList"
                                    })
                                }}
                                onContextMenu={(e) => {
                                    taskContextHandler(e, task)
                                    taskSingleClickHandler({
                                        e, task, i: index, ...props, unassignedTaskList: tasksList, dispatch, taskToUpdate: "tasksList"
                                    })
                                }}>
                                <Tasks task={task} enablePropagation={true} unassignedList={true} dragStart={props.dragStart} />
                            </div>)
                        })}
                        {props.missionsList?.filter(m => {
                            let statusMatch = TASK_STATUSES.UNASSIGNED;
                            if (activeTab === "middle") {
                                statusMatch = TASK_STATUSES.ASSIGNED
                            }
                            else if (activeTab === "right") {
                                statusMatch = TASK_STATUSES.COMPLETED
                            }
                            return m.missionCategory === statusMatch
                        }).map((mission, index) => {
                            return (<div className="mission-wrapper" id={"missions"}
                                // onDragOver={dragOver}
                                // onDrop={(e) => { if (category !== "completed") onDrop(e, data) }}
                                onClick={(e) => {
                                    props.setSelectedMission(mission)
                                    let obj = {};
                                    obj[mission.id] = !props.toggleState[mission.id];
                                    props.setToggleState({ ...props.toggleState, ...obj });
                                    missionClickhandler({ mission, preventDirection: false, ...props });
                                }}
                                onContextMenu={async (e) => {
                                    e.preventDefault();
                                    missionContextHandler(e, mission)
                                    let obj = {};
                                    obj[mission.id] = !props.toggleState[mission.id];
                                    props.setToggleState({ ...props.toggleState, ...obj });
                                    missionClickhandler({ mission, preventDirection: false, ...props });
                                    await delay(400)
                                    props.setSelectedMission(mission)
                                }} key={index}>
                                <MissionSuprviseSide
                                    updateMission={props.updateMission}
                                    dragOver={props.dragOver}
                                    onDropMission={props.onDropMission}
                                    activeTab={activeTab}
                                    mission={mission} distance={props.distance}
                                    toggleState={props.toggleState}
                                    _onListChange={props._onListChange}
                                    missionTaskContextHandler={missionTaskContextHandler}
                                    missionTaskClickHandler={missionTaskClickHandler} />
                            </div>)
                        })}
                    </div>
                </div>}
            </div> : <div className="missions-section">
                <div className="headers">
                    <div className={`left ${activeTab === 'left' ? 'active' : ''}`} onClick={() => handleTabClick('left')}>
                        <p className={`${props.sectionType !== appDefaults.SECTIONS.RESIZE_CONTAINER ? "text-size" : ""}`}>New</p>
                    </div>
                    <div className={`middle ${activeTab === 'right' || activeTab === 'middle' ? 'active' : ''}`} onClick={() => handleTabClick('right')}>
                        <p className={`${props.sectionType !== appDefaults.SECTIONS.RESIZE_CONTAINER ? "text-size" : ""}`}>Pending customer reply</p>
                    </div>
                </div>
                <div className="containers" style={{ minHeight: props.minHeight }} onDragOver={(e) => props.dragOver(e)} onDrop={(e) => props.onDrop(e, {}, `unassignedTaskList`)}>
                    <div className={`container container-left active`}>
                        {activeTab === "left" && tasksListWithouDate?.map((task, index) => {
                            return (<div className="task-wrapper" key={index}
                                onClick={(e) => {
                                    taskSingleClickHandler({
                                        e, task, i: index, ...props, unassignedTaskList: tasksListWithouDate, dispatch, taskToUpdate: "tasksListWithouDate"
                                    })
                                }}
                                onContextMenu={(e) => {
                                    taskContextHandler(e, task)
                                    taskSingleClickHandler({
                                        e, task, i: index, ...props, unassignedTaskList: tasksListWithouDate, dispatch, taskToUpdate: "tasksListWithouDate"
                                    })
                                }}>
                                <Tasks task={task} enablePropagation={true} unassignedList={true} dragStart={props.dragStart} />
                            </div>)
                        })}
                        {(activeTab === "right" || activeTab === "middle") && tasksListPendingAction?.map((task, index) => {
                            console.log("task, index", task, index)
                            return (<div className="task-wrapper" key={index}
                                onClick={(e) => {
                                    taskSingleClickHandler({
                                        e, task, i: index, ...props, unassignedTaskList: tasksListPendingAction, dispatch, taskToUpdate: "tasksListPendingAction"
                                    })
                                }}
                                onContextMenu={(e) => {
                                    taskContextHandler(e, task)
                                    taskSingleClickHandler({
                                        e, task, i: index, ...props, unassignedTaskList: tasksListPendingAction, dispatch
                                    })
                                }}>
                                <Tasks task={task} enablePropagation={true} unassignedList={true} dragStart={props.dragStart} />
                            </div>)
                        })}
                    </div>
                </div>
            </div>}
    </>
    )
}
export default Sections