import moment from "moment-timezone"

const selectTasksPolygon = ({ tasks, bookMarks, unassignedTaskList, setBookMarks, setSelectedTasks, setUnassignedTaskList }) => {
    let newSelectedTasks = []
    let newBookMarks = [...bookMarks]
    let newUnassignedTaskList = [...unassignedTaskList]
    newBookMarks = newBookMarks.map(i => {
        i.selected = false;
        return i
    })
    newUnassignedTaskList = newUnassignedTaskList.map(i => {
        i.active = false;
        return i
    })
    for (let task of tasks) {
        if (task.missioned) {

        }
        else {
            let findSelectedIndex = newSelectedTasks.findIndex(i => i.id === task.id);
            if (findSelectedIndex < 0) {
                newSelectedTasks.push(task.id)
            }
            let unassignedTaskListIndex = newUnassignedTaskList.findIndex(i => i.id === task.id);
            if (unassignedTaskListIndex >= 0) {
                newUnassignedTaskList[unassignedTaskListIndex].active = true
            }
            let findBookmarkIndex = newBookMarks.findIndex(i => i.id === task.id)
            if (findBookmarkIndex >= 0) {
                newBookMarks[findBookmarkIndex].selected = true
            }
        }

    }

    setBookMarks(newBookMarks)
    setSelectedTasks(newSelectedTasks)
    setUnassignedTaskList(newUnassignedTaskList)
}


const taskSingleClickHandler = ({ selectedTasks, setToggleState, setSelectedTasks,
    setSelectedMissions, setMissionDirection, unassignedTaskList, bookMarks, setBookMarks,
    setTaskList, lastSelectedTaskIndex,
    setUnassignedTaskList, setTaskDetail, setSelectedTaskIndex,
    setSelectedCenter, setDefaultCenter, e, task, i, datedTaskList, setDatedTaskList, datedTasks }) => {
    if (setSelectedMissions) setMissionDirection(null);
    setToggleState({})
    if (setMissionDirection) setMissionDirection(null)
    let selectedCenter = {
        ...task.location, address: task.address, from: "task", id: task.id
    };
    if (task.lat) {
        selectedCenter.lat = task.lat
    }
    if (task.lng) {
        selectedCenter.lng = task.lng
    }
    let defaultcenter = {
        id: task.id,
        ...task.location,
        address: task.address,
    }
    if (task.lat) {
        defaultcenter.lat = task.lat
    }
    if (task.lng) {
        defaultcenter.lng = task.lng
    }
    if (e && (!e.ctrlKey && !e.metaKey)) {
        setSelectedCenter(selectedCenter)
        setDefaultCenter(defaultcenter)
    }
    //  console.log("task:::",task)
    if (e && (e.ctrlKey || e.metaKey)) {
        if (!selectedTasks.includes(task.id)) {
            setSelectedTasks((prev) => {
                //  console.log("selected items:::",[...selectedTasks,task.id])
                return [...prev, task.id];
            });
            if (selectedTasks.length) {
                taskSelectHandler(task, false, unassignedTaskList, setUnassignedTaskList, datedTaskList, setDatedTaskList);
            } else {
                taskSelectHandler(task, true, unassignedTaskList, setUnassignedTaskList, datedTaskList, setDatedTaskList);
            }
        } else {
            //unselect the current task
            const tasks = unassignedTaskList.map((t) => {
                if (task.id === t.id) return { ...t, active: false };
                return t;
            });
            setUnassignedTaskList(tasks);
            const datedTasks = datedTaskList.map((t) => {
                if (task.id === t.id) return { ...t, active: false };
                return t;
            });
            setDatedTaskList(datedTasks);
            //remove for selected array list
            const filteredArray = selectedTasks.filter((st) => st !== task.id);
            setSelectedTasks(filteredArray);
        }
    }
    else if (e && e.shiftKey) {
        let newTaskList
        newTaskList = [...unassignedTaskList]
        if (datedTasks) {
            newTaskList = [...datedTaskList]
        }
        if (!selectedTasks.includes(task.id)) {

            let select = []
            if (selectedTasks.length) {
                let newTasks = selectMultiTaskShiftKey(i, newTaskList, select, lastSelectedTaskIndex, selectedTasks, setSelectedTasks, setTaskList, unassignedTaskList)
                removeTaskAfterIndex(i, newTaskList.length, newTasks, setSelectedTasks, setTaskList)
            }
        }
        else {
            removeTaskAfterIndex(i, newTaskList.length, newTaskList, setSelectedTasks, setTaskList)
        }
    }
    else if (e.detail === 2) {
        if (setTaskDetail) setTaskDetail(task);
        let selectedCenter = {
            ...task.location, address: task.address, from: "task", id: task.id
        };
        if (task.lat) {
            selectedCenter.lat = task.lat
        }
        if (task.lng) {
            selectedCenter.lng = task.lng
        }
        setSelectedCenter(selectedCenter)
    }
    else {
        if (e.button === 2) {
            if (selectedTasks.length <= 1) {
                setSelectedTasks([task.id]);
                taskSelectHandler(task, true, unassignedTaskList, setUnassignedTaskList, datedTaskList, setDatedTaskList);
            }
        }
        else {
            setSelectedTasks([task.id]);
            taskSelectHandler(task, true, unassignedTaskList, setUnassignedTaskList, datedTaskList, setDatedTaskList);
        }
    }

    if (i || i === 0) { setSelectedTaskIndex(i) }
    let newBookmarks = JSON.parse(JSON.stringify(bookMarks));
    let findBookmarkIndex = newBookmarks.findIndex(item => item.id === task.id);

    if (findBookmarkIndex > -1) {
        newBookmarks[findBookmarkIndex].selected = true;
    }
    if (findBookmarkIndex > -1 && e && (e.ctrlKey || e.metaKey)) {
        if (bookMarks[findBookmarkIndex].selected === true) {
            newBookmarks[findBookmarkIndex].selected = false;
        }
    }
    else {
        newBookmarks = newBookmarks.map(i => {
            if (i.id !== task.id) {
                i.selected = false;
            }
            return i
        })
    }
    setBookMarks(newBookmarks)
};

const removeTaskAfterIndex = (i, taskListLength, newTaskList, setSelectedTasks, setTaskList) => {
    let unselect = [];
    // console.log("i, taskListLength, newTaskList", i, taskListLength, newTaskList)
    for (let index = i + 1; index <= taskListLength - 1; index++) {
        newTaskList[index].active = false;
        unselect.push(newTaskList[index])
        setSelectedTasks((prev) => {
            let indexOfTask = prev.indexOf(newTaskList[index].id)
            if (indexOfTask > -1) {
                prev.splice(indexOfTask, 1)
            }
            return [...prev];
        });
    }
    newTaskList.splice(i + 1, (taskListLength - (i + 1)), ...unselect)
    if (setTaskList) setTaskList(newTaskList)
    return newTaskList
}

const selectMultiTaskShiftKey = (i, newTaskList, select, lastSelectedTaskIndex, selectedTasks, setSelectedTasks, setTaskList, unassignedTaskList) => {
    // console.log("i, newTaskList, select", i, newTaskList, select, lastSelectedTaskIndex)
    let findTasksBWIndexes = newTaskList.slice(lastSelectedTaskIndex, i + 1)
    for (let task of findTasksBWIndexes) {
        if (!selectedTasks.includes(task.id)) {
            setSelectedTasks((prev) => {
                return [...prev, task.id];
            });
        }
        let findFromTasks = newTaskList.find(item => {
            return item.id === task.id
        })
        findFromTasks.active = true;
        select.push(findFromTasks)
    }
    newTaskList.splice(lastSelectedTaskIndex, (i - lastSelectedTaskIndex) + 1, ...select)
    if (setTaskList) setTaskList(newTaskList)
    return newTaskList
}

const taskSelectHandler = (task, removeSelected = false, unassignedTaskList, setUnassignedTaskList, datedTaskList, setDatedTaskList) => {
    if (removeSelected) {
        const tasks = unassignedTaskList.map((t) => {
            if (task.id === t.id) return { ...t, active: true };
            return { ...t, active: false };
        });
        setUnassignedTaskList(tasks);
        const datedTasks = datedTaskList.map((t) => {
            if (task.id === t.id) return { ...t, active: true };
            return { ...t, active: false };
        });
        setDatedTaskList(datedTasks);
    } else {
        const tasks = unassignedTaskList.map((t) => {
            if (task.id === t.id) return { ...t, active: true };
            return t;
        });
        setUnassignedTaskList(tasks);
        const datedTasks = datedTaskList.map((t) => {
            console.log("task.id === t.id", task.id === t.id)
            if (task.id === t.id) return { ...t, active: true };
            // return { ...t, active: false };
            return { ...t };
        });
        setDatedTaskList(datedTasks);
    }
};

const delay = ms => new Promise(res => setTimeout(res, ms));

const KmToMiles = (value) => {
    return Number(value) * 0.621371
}

const clean = (obj) => {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
    }
    return obj
}

const timeZoneHeader = () => {
    let timezone = "";
    let getTimezone = moment.tz.guess();
    if (getTimezone) {
        timezone = getTimezone;
    }
    return { timezone: timezone };
}

const getdateQuery = (date, key) => {
    let currentDate = new Date(date);
    let nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);

    let dataToReturn = {}
    dataToReturn[key] = {
        $gte: moment(currentDate).tz(timeZoneHeader().timezone).startOf("d")._d,
        $lt: moment(currentDate).tz(timeZoneHeader().timezone).endOf("d")._d
    }

    return dataToReturn

}

const serializeArray = (array, key) => {
    return array.map((n, index) => `${key}[${index}]=${n}`).join('&')
}

const renderCustomerName = (obj) => {
    if (obj?.customer && obj?.customer.firstName) {
        return `${obj.customer.firstName ? obj.customer.firstName : ''}`
    }
    else if (obj?.customerId) {
        return `${obj.customerId.firstName ? obj.customerId.firstName : ''} ${obj.customerId.lastName ?
            obj.customerId.lastName : ''}`
    }
    else {
        return ``
    }
}
const formatAMPM = (seconds) => {
    let timezone = "";
    let getTimezone = moment.tz.guess();
    if (getTimezone) {
        timezone = getTimezone;
    }
    return moment().tz(timezone).startOf("d").add(seconds, "seconds").format("hh:mm A")
}

const escapeRegex = (str) => {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "").replace(/ /g, "");
};



function getQuarterToDate() {
    const currentDate = moment().endOf("D");
    const currentMonth = moment().get("month"); // 0-based index: January is 0, December is 11
    let startMonth;
    if (currentMonth < 3) { // Q1: January-March
        startMonth = 0; // January
    } else if (currentMonth < 6) { // Q2: April-June
        startMonth = 3; // April
    } else if (currentMonth < 9) { // Q3: July-September
        startMonth = 6; // July
    } else { // Q4: October-December
        startMonth = 9; // October
    }

    const startDate = moment().set("month", startMonth).startOf("M");
    const endDate = currentDate;

    return { startDate, endDate };
}

function getYearToDate() {
    const currentDate = moment().startOf("D");
    const startDate = moment().startOf("Y"); // January 1st of the current year
    const endDate = currentDate;

    return { startDate, endDate };
}

function getMonthToDate() {
    const currentDate = moment().endOf("D");
    const startDate = moment().startOf("M"); // First day of the current month
    const endDate = currentDate;

    return { startDate, endDate };
}

function getLast7Days() {
    const currentDate = moment().endOf("D");
    const startDate = moment().subtract(7, "days").startOf("D"); // First day of the current month
    const endDate = currentDate;

    return { startDate, endDate };
}


let getDates = (startDate, stopDate) => {
    const timezone = { ...timeZoneHeader() }
    let dateArray = [];
    let currentDate = moment(startDate).tz(timezone.timezone);
    stopDate = moment(stopDate).tz(timezone.timezone);
    while (currentDate <= stopDate) {
        let obj = {
            date: moment(currentDate).tz(timezone.timezone).format("YYYY-MM-DD"),
            startDate: moment(currentDate).tz(timezone.timezone).startOf("d"),
            endDate: moment(currentDate).tz(timezone.timezone).endOf("d"),
            dayOfWeek: moment(currentDate).tz(timezone.timezone).day(),
            day: moment(currentDate).get("D"),
            MMDD: moment(currentDate).format("MMM DD"),
            // ...colors[i],
            today: false,
        };
        if (
            moment(currentDate).tz(timezone.timezone).format("YYYY-MM-DD") ===
            moment().tz(timezone.timezone).format("YYYY-MM-DD")
        ) {
            obj.today = true;
        }
        dateArray.push(obj);
        currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
};

function getMonthsBetweenDates(startDate, endDate) {
    const timezone = { ...timeZoneHeader() }
    let start = moment(startDate).tz(timezone.timezone);
    let end = moment(endDate).tz(timezone.timezone);

    let result = [];
    let current = start.clone();

    while (current.isBefore(end) || current.isSame(end, 'month')) {
        result.push(current.format('YYYY-MM'));
        current.add(1, 'month');
    }

    return result;
}

export {
    taskSingleClickHandler,
    delay,
    selectTasksPolygon,
    KmToMiles,
    clean,
    timeZoneHeader,
    getdateQuery,
    serializeArray,
    renderCustomerName,
    formatAMPM,
    escapeRegex,
    getLast7Days,
    getMonthToDate,
    getQuarterToDate,
    getYearToDate,
    getDates,
    getMonthsBetweenDates
}