
import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, ErrorMessage, Form as FormF } from "formik";
import {
    Card,
    Row,
    Col,
    Form,
    InputGroup,
    FormControl,
    Button,
    Dropdown,
    Modal,
    Container,
    NavDropdown,
} from "react-bootstrap";

import Union from "../../assets/image/Union.png";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import uploadplusicon from "../../assets/image/plusuploadicon.png";
import closeicon from "../../assets/image/close-icon.png";
import axios from "axios"
import fileDownload from "js-file-download";

const TaskMissionTab = (props) => {
    const uploadInput = useRef(null);
    let type = props.settingTypes.OPERATIONS;
    let subType = props.settingSubType.TASK_AND_MISSION;
    if (props.userData && props.userData.role === 1 && !props.userData.subAdmin) {
        type = props.settingTypes.OPERATIONS
        subType = props.settingSubType.COMMON_TASK_AND_MISSION
    }
    let roleBasedDisabled = false;
    if (props.userData && props.userData.userId && !props.userData.subAdmin && props.userData.userRole !== "admin") {
        roleBasedDisabled = true
    }
    const [images, setImages] = useState([]);
    const [imageError, setImageError] = useState("")
    const finalTheme = createTheme({
        components: {
            MuiClockPicker: {
                styleOverrides: {
                    root: {
                        "backgroundColor": "#272B30"
                    }
                }
            }
        }
    })
    const changeProfileClick = (e) => {
        uploadInput.current.click();
    };
    const uploadFiles = async (event) => {
        let img = []
        for (let i = 0; i < event.target.files.length; i++) {
            console.log("event.target.files[i]", event.target.files[i])
            // img.push(event.target.files[i])
            let response = await props.handleDocUpload(event.target.files[i])
            console.log({ response })
            // setImages([...props.settingFormData.documents, response]);
            img.push(response)

        }
        props.handleChangeSetting(null, { key: [{ "documents": [...props.settingFormData.documents, ...img] }], type: type, subType: subType })
    }

    const deleteFile = async (index) => {
        let docs = JSON.parse(JSON.stringify(props.settingFormData.documents))
        docs.splice(index, 1)
        console.log(docs)
        props.handleChangeSetting(null, { key: [{ "documents": docs }], type: type, subType: subType })
    }

    const handleDownloadClick = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                console.log(res.data)
                fileDownload(res.data, filename)
            })
    }

    const minStyle = {
        color: "white", padding: "6px", fontSize: "13px", fontWeight: "500"
    }
    return (<div className="st-oplist">
        <div className="st-optimization">
            <div className="st-opheading">
                <h1>Time at stop</h1>
            </div>
            <div className="st-oplistbox">
                <Form>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Pick up
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                            <Col sm={1.5}>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.settingFormData.defaultPickup}
                                    placeholder="Pickup"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskDuration": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultPickup": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                            <span style={minStyle}>min</span>
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Drop
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                            <Col sm={1.5}>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.settingFormData.defaultDrop}
                                    placeholder="Drop"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskCapacity": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultDrop": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                            <span style={minStyle}>min</span>
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Drop and Service
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                            <Col sm={1.5}>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.settingFormData.defaultDropService}
                                    placeholder="Drop and Service"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskCapacity": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultDropService": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                            <span style={minStyle}>min</span>
                        </div>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Return
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                            <Col sm={1.5}>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.settingFormData.defaultReturn}
                                    placeholder="Return"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskCapacity": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultReturn": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                            <span style={minStyle}>min</span>
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Exchange
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                            <Col sm={1.5}>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.settingFormData.defaultExchange}
                                    placeholder="Exchange"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultExchange": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultExchange": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                            <span style={minStyle}>min</span>
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Service
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                            <Col sm={1.5}>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.settingFormData.defaultService}
                                    placeholder="Service"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultService": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultService": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                            <span style={minStyle}>min</span>
                        </div>

                    </Form.Group>
                </Form>
            </div>
        </div>

        {/* new field */}
        <div className="st-optimization">
            <div className="st-opheading">
                <h1>Optimization</h1>
            </div>
            <div className="st-oplistbox">
                <Form>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Maximum task per mission
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                            <Col sm={1.5}>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.settingFormData.defaultMaxTaskPerMission}
                                    placeholder="Pickup"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskDuration": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultMaxTaskPerMission": e.target.value }], type: type, subType: subType }) }} />
                            </Col>

                        </div>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Maximum mission duration
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                            <Col sm={1.5}>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.settingFormData.defaultMaxMissionDuration}
                                    placeholder="Drop"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskCapacity": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultMaxMissionDuration": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                            <span style={minStyle}>min</span>
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Maximum mission capacity
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                            <Col sm={1.5}>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.settingFormData.defaultMaxMissionCapacity}
                                    placeholder="Drop and Service"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskCapacity": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultMaxMissionCapacity": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                            <span style={minStyle}>{props.settingFormData.defaultMaxMissionCapacityUnit}</span>
                        </div>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Default task volume
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                            <Col sm={1.5}>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.settingFormData.defaultTaskVolume}
                                    placeholder="Return"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskCapacity": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultTaskVolume": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                            <span style={minStyle}>{props.settingFormData.defaultTaskCapacityUnit}</span>
                        </div>
                    </Form.Group>
                    {/* <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Task delay alert
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                            <Col sm={1.5}>
                                <Form.Control
                                    type="text"
                                    defaultValue={props.settingFormData.defaultTaskDelayAlert}
                                    placeholder="Exchange"
                                    disabled={roleBasedDisabled}
                                    // onChange={(e) => { handleChangeSetting(e, { key: [{ "defaultTaskCapacity": e.target.value }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION }) }} />
                                    onChange={(e) => { props.setInputValue(e.target.value); props.setInputSettingValue({ key: [{ "defaultTaskDelayAlert": e.target.value }], type: type, subType: subType }) }} />
                            </Col>
                            <span style={minStyle}>m</span>
                        </div>
                    </Form.Group> */}
                </Form>
            </div>
        </div>
        <div className="st-TimeRange">
            <div className="st-opheading">
                <h1>Default time range for delivery</h1>
            </div>
            <div className="st-oplistbox">
                <Form>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            After
                        </Form.Label>

                        <div className="after-before-time">
                            <ThemeProvider theme={finalTheme}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        inputProps={{ placeholder: "After" }}
                                        // inputFormat={"hh:mm a"}
                                        ampm={props.settingFormData.timeFormat === "24" ? false : true}
                                        open={props.afterOpen}
                                        onOpen={() => props.setAfterOpen(true)}
                                        onClose={() => props.setAfterOpen(false)}
                                        value={props.settingFormData.defaultAfterTimePicker}
                                        disabled={roleBasedDisabled}
                                        disableClock={roleBasedDisabled}
                                        onChange={(e) => {
                                            // setFieldValue("afterTimePicker", e)
                                            let date = new Date(e)
                                            console.log("date", date)
                                            if (!isNaN(date)) {
                                                let options = { hour12: false }
                                                let timeSplit = date.toLocaleTimeString("en", options).split(":")
                                                let afterTime = `${timeSplit[0]}:${timeSplit[1]}`
                                                let afterSeconds = Number((date.getHours() * 3600) + (date.getMinutes() * 60))

                                                props.handleChangeSetting(e, { key: [{ "defaultAfterTime": afterTime }, { "defaultAfterTimeSeconds": afterSeconds }, { "defaultAfterTimePicker": e }], type: type, subType: subType })
                                                // setInputValue(afterTime)
                                                // setInputSettingValue({ key: [{ "defaultAfterTime": afterTime }, { "defaultAfterTimeSeconds": afterSeconds }, { "defaultAfterTimePicker": e }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION })
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            onClick={(e) => props.setAfterOpen(true)}
                                        />
                                        }
                                    />
                                </LocalizationProvider>
                            </ThemeProvider>
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Before
                        </Form.Label>

                        <div className="after-before-time">
                            <ThemeProvider theme={finalTheme}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        inputProps={{ placeholder: "Before" }}
                                        // inputFormat={"hh:mm a"}
                                        open={props.beforeOpen}
                                        ampm={props.settingFormData.timeFormat === "24" ? false : true}
                                        onOpen={() => props.setBeforeOpen(true)}
                                        onClose={() => props.setBeforeOpen(false)}
                                        value={props.settingFormData.defaultBeforeTimePicker}
                                        disabled={roleBasedDisabled}
                                        disableClock={roleBasedDisabled}
                                        onChange={(e) => {
                                            // setFieldValue("afterTimePicker", e)
                                            let date = new Date(e)
                                            if (!isNaN(date)) {
                                                let options = { hour12: false }
                                                let timeSplit = date.toLocaleTimeString("en", options).split(":")
                                                let beforeTime = `${timeSplit[0]}:${timeSplit[1]}`;
                                                let beforeSeconds = Number((date.getHours() * 3600) + (date.getMinutes() * 60))
                                                props.handleChangeSetting(e, { key: [{ "defaultBeforeTime": beforeTime }, { "defaultBeforeTimeSeconds": beforeSeconds }, { "defaultBeforeTimePicker": e }], type: type, subType: subType })
                                                // setInputValue(beforeTime)
                                                // setInputSettingValue({ key: [{ "defaultBeforeTime": beforeTime }, { "defaultBeforeTimeSeconds": beforeSeconds }, { "defaultBeforeTimePicker": e }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION })
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            onClick={(e) => props.setBeforeOpen(true)}
                                        />
                                        }
                                    />
                                </LocalizationProvider>
                            </ThemeProvider>
                        </div>
                    </Form.Group>
                    {/* <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={2}>
                            Schedule Time
                        </Form.Label>

                        <div className="after-before-time">
                            <ThemeProvider theme={finalTheme}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        inputProps={{ placeholder: "Schedule Time" }}
                                        // inputFormat={"hh:mm a"}
                                        open={props.scheduleOpen}
                                        onOpen={() => props.setScheduleOpen(true)}
                                        onClose={() => props.setScheduleOpen(false)}
                                        value={props.settingFormData.defaultScheduleTimePicker}
                                        onChange={(e) => {
                                            // setFieldValue("afterTimePicker", e)
                                            let date = new Date(e)
                                            if (!isNaN(date)) {
                                                let options = { hour12: false }
                                                let timeSplit = date.toLocaleTimeString("en", options).split(":")
                                                let beforeTime = `${timeSplit[0]}:${timeSplit[1]}`;
                                                let beforeSeconds = Number((date.getHours() * 3600) + (date.getMinutes() * 60))
                                                props.handleChangeSetting(e, { key: [{ "defaultScheduleTime": beforeTime }, { "defaultScheduleTimeSeconds": beforeSeconds }, { "defaultScheduleTimePicker": e }], type: type, subType: subType })
                                                // setInputValue(beforeTime)
                                                // setInputSettingValue({ key: [{ "defaultBeforeTime": beforeTime }, { "defaultBeforeTimeSeconds": beforeSeconds }, { "defaultBeforeTimePicker": e }], type: settingTypes.OPERATIONS, subType: settingSubType.TASK_AND_MISSION })
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            onClick={(e) => props.setScheduleOpen(true)}
                                        />
                                        }
                                    />
                                </LocalizationProvider>
                            </ThemeProvider>
                        </div>
                    </Form.Group> */}
                </Form>
            </div>
        </div>
        <div className="st-Documents">
            <div className="st-opheading">
                <h1>Documents & Tutorial</h1>
            </div>
            <div className="st-oplistbox">
                <Form>
                    <Form.Group as={Row} className="mb-input">
                        <Form.Label column sm={3}>
                            Add default installation file
                        </Form.Label>
                        <Col sm={9}>
                            <div className="adddoc-settings">
                                <div className="adddocbtn">
                                    <button className="add-docbtn"
                                        type="button"
                                        onClick={changeProfileClick}
                                        disabled={roleBasedDisabled}
                                    >
                                        <img src={uploadplusicon} alt={"plus"} />
                                        <span>Add Doc</span>
                                    </button>
                                    <input
                                        className="file-upload"
                                        ref={uploadInput}
                                        type="file"
                                        name="images"
                                        multiple
                                        onChange={(e) => {
                                            uploadFiles(e);
                                        }}
                                        style={{ 'display': 'none' }}
                                    />
                                    <span>{imageError}</span>
                                </div>
                                {/* <span>{images && images.length}</span> */}
                                <div className="adddoc-img">
                                    {props.settingFormData.documents && props.settingFormData.documents.length > 0 &&
                                        props.settingFormData.documents.map((item, index) => {
                                            return (
                                                <div className="imgbox" key={index}>
                                                    <span onClick={(e) => { handleDownloadClick(item.original, item.fileName) }} className="imgname">{item.fileName}</span>
                                                    <button
                                                        className="remove-limg"
                                                        type="button"
                                                        onClick={() => deleteFile(index)}
                                                    >
                                                        <img src={closeicon} alt="icon" />
                                                    </button>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </Col>
                    </Form.Group>
                </Form>
            </div>
        </div>
    </div>)
}

export default TaskMissionTab