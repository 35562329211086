import React from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Label, Tooltip, Legend } from 'recharts';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const PieChartComponent = ({ data, textValue }) => {
    const demoUrl = 'https://codesandbox.io/s/pie-chart-with-padding-angle-7ux0o';
    const onPieEnter = (e) => {
    }

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                    <p>{`${payload[0].name}: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };
    return (
        <PieChart width={300} height={320} onMouseEnter={onPieEnter}>
            <Pie
                data={data}
                cx={150}
                cy={220}
                startAngle={180}
                endAngle={0}
                innerRadius={108}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
                // label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                labelLine={false}  // Hide lines connecting labels to slices
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                ))
                }
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Label
                    x={150} y={180} dy={textValue.textTop?-65:-35} value="Summary" position="centerBottom" className='label-top' fontSize='17px' fill="#6f767e"
                />
                {textValue.textTop && <Label
                    x={150} y={200} dy={-50} value={`${textValue.textTop}`} position="centerTop" className='label' fill="white" fontSize='28px'
                />}
                <Label
                    x={150} y={200} dy={-20} value={`${textValue.value} ${textValue.text}`} position="centerTop" className='label' fill="white" fontSize='28px'
                />
                {/* <Label content={<CustomLabel value1="Total" value2={"dasdasd"} />} position="center" /> */}
            </Pie>
        </PieChart>
    );
}

export default PieChartComponent
