import React, { useState } from "react";
import {
  Button,
} from "react-bootstrap";
import "./Export.scss";
import taskService from "../../services/task.service";
import closeicon from "../../assets/image/close-icon.png";
import uploading from "../../assets/image/importimg.png";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { timeZoneHeader } from "../../helpers/helperFunctions";
const timezone = { ...timeZoneHeader() }
function OrderTypeFormatter(item) {
  if (item.orderType === "D") return "Drop"
  if (item.orderType === "DI") return "Drop & Service"
  if (item.orderType === "S") return "Service"
  if (item.orderType === "R") return "Return"
  if (item.orderType === "P") return "Pick Up"
  if (item.orderType === "E") return "Exchange"
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ActualTimeAtStop = (row) => {
  let duration = Number(row.orderCompletedTimeStamp) - Number(row.orderReachedTimeStamp);
  return Math.ceil((duration / 1000) / 60)
}
const ExportFile = (props) => {
  const [value, onChange] = useState([new Date(), new Date()]);


  const renderOrderDate = (task) => {
    let orderDate = moment(task.orderDate).tz(timezone.timezone)._a;
    return moment(orderDate).tz(timezone.timezone).format("YYYY-MM-DD");
  }

  const renderMerchantName = (row) => {
    if (row.createdFor) {
      return row.createdFor.companyName ? row.createdFor.companyName : `${row.createdFor.firstName}${row.createdFor.lastName}`
    }
    else if (row.merchantId) {
      return row.merchantId.companyName ? row.merchantId.companyName : `${row.merchantId.firstName}${row.merchantId.lastName}`
    }
    else {
      return row.userId.companyName ? row.userId.companyName : `${row.userId.firstName}${row.userId.lastName}`
    }
  }
  const handleSubmit = async () => {
    let params = {
      limit: 10000,
      startDate: value[0],
      endDate: value[1],
      all: true
    }
    const response = await taskService.getTaskList(params);
    if (response.status === 200 && response.data) {
      let data = [];
      response.data?.result?.results?.map((item, index) => {
        console.log("moment(moment(item.createdDate)._a).format", item.createdDate)
        console.log("moment(moment(item.createdDate)._a).format", moment(item.createdDate).format("LL"))
        let d = {
          "Task Type": OrderTypeFormatter(item) || "N/A",
          "Recipient Name": item.customer && item.customer.firstName ? `${item.customer.firstName}` : "N/A",
          "Phone Number": item.customer && item.customer.phoneNumber ? `${item.customer.phoneNumber}` : "N/A",
          "Contact Email": item.customer && item.customer.email ? `${item.customer.email}` : "N/A",
          "Address": item.address || "N/A",
          "Task Status": capitalizeFirstLetter(item.taskStatus) || "N/A",
          "Planned Date": renderOrderDate(item),
          "Between": item.after || "N/A",
          "And": item.before || "N/A",
          "Started On": item.orderStartedDate ? moment(item.orderStartedDate).format("LL") : "N/A",
          "Completed On": item.orderCompletedDate ? moment(item.orderCompletedDate).format("LL") : "N/A",
          "Task Notes": item.notes || "N/A",
          "Agent Name": item.agentId ? `${item.agentId.firstName ? item.agentId.firstName : ""}${item.agentId.lastName ? item.agentId.lastName : ""}` : "N/A",
          "Team Name": item.teamId ? item.teamId.teamName : "N/A",
          "Picture(s)": item.picturesBefore?.length > 0 || item.picturesAfter?.length > 0 ? "Yes" : "No",
          "Signature": item.signature?.original ? "Yes" : "No",
          "Driver Notes": item.agentNotes ? item.agentNotes : "",
          "Assigned Organization": renderMerchantName(item),
          "Visibilty": item.teamIds ? item.teamIds.map(t => t.teamName).join(", ") : "All Teams",
          "Expected Time at stop": item.durationTime,
          "Actual Time at Stop": ActualTimeAtStop(item) ? `${ActualTimeAtStop(item)} ${"min"}` : "N/A",
          "Task Volume": item.capacity || "N/A",
          "Failed Reason": item.reason || "N/A",
          "Task Cost": item.price ? item.price : "0",
          "Created Date": item.createdDate ? moment(item.createdDate).format("LL") : "N/A",
          "Task Created By": item.merchantId ? `${item.userId.merchantId ? item.userId.merchantId : ""}${item.merchantId.lastName ? item.merchantId.lastName : ""}` : "N/A",
          "Task Created For": item.userId ? `${item.userId.companyName ? item.userId.companyName : ""}` : "N/A",
        };
        data.push(d);
      });

      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "Tasks List",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "tasks",
      };

      const csvExporter = new ExportToCsv(options);
      if (data.length > 0) {
        csvExporter.generateCsv(data);
      } else {
        props.errorAlert("No data found");
      }
    } else {
      props.errorAlert(response.message ? response.message : "Something went wronng");
    }
  }

  return (
    <>
      <div className="openimport-popup" >
        <div className="addimport-popup">
          <div className="addimport-slide">

            <div className="customer-import">

              <Button
                className="import-close"
                onClick={() => props.setExportTasks(false)}
              >
                <img src={closeicon} alt="icon" />
              </Button>
            </div>
            <div className="addimport-filebox">
              <div className="customer-form">
                <div className="import-pheading">
                  <div className="import-heading">
                    <h2>Export your tasks</h2>
                    <p>Select the date or the date range required.</p>
                  </div>
                </div>

                <div className="export-upload-img">
                  <div className="picker_wrapper">
                    <DateRangePicker
                      showDoubleView={true}
                      onChange={onChange}
                      value={value} />
                  </div>

                  <div className="upload-img">
                    <img src={uploading} alt="img" />
                    <div className="upload-btn">
                      <button className="btn" onClick={() => { handleSubmit() }}>Export</button>
                    </div>
                    <div className="drag-upload">

                    </div>
                  </div>
                  {/* </FileUploader> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportFile;
