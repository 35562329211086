import { useRef, useState } from "react";
import appDefaults from "../../../../helpers/app-defaults";
import ContentLayout from "../../../../components/contentlayout";
import PlanAndSchedule from "../planner/Planner";
import Supervise from "../supervise/Supervise";
import { useEffect } from "react";
import "./main.scss";
import momentTz from "moment-timezone";
import routes from "../../components/ResizeMap/routes3.json";
import { KmToMiles, delay } from "../../../../helpers/helperFunctions";
import moment from "moment-timezone";
import timeZoneHeader from "../../../../services/timezone-header";
import { useDispatch, useSelector } from "react-redux";
import MapContextMenu from "../../components/ContextMenu/MapContextMenu";
import { useHistory } from "react-router-dom";

//---- Asset Image Imports -----//
import agentMarker from "../../../../assets/image/agentLocation.png";
import unassignedTaskMarker from "../../../../assets/image/unassignedMarker.png";
import assignedTaskMarker from "../../../../assets/image/assignedMarker.png";
import delayTaskMarker from "../../../../assets/image/delayMarker.png";
import completedTaskMarker from "../../../../assets/image/completedMarker.png";
import failedTaskMarker from "../../../../assets/image/failedTaskMarker.png";
import startedTaskMarker from "../../../../assets/image/startedMarker.png";
import selectedUnassignedTaskMarker from "../../../../assets/image/selectedUnassignedTask.png";
import selectedAssignedMarker from "../../../../assets/image/selectedAssignedMarker.png";
import selectedDelayMarker from "../../../../assets/image/selectedDelayMarker.png";
import selectedCompletedMarker from "../../../../assets/image/selectedCompletedMarker.png";
import selectedFailedTaskMarker from "../../../../assets/image/selectedFailedTaskMarker.png";
import selectedStartedMarker from "../../../../assets/image/selectedStartedMarker.png";
import startLocationMarker from "../../../../assets/image/startLocationMarker.png";
import endLocationMarker from "../../../../assets/image/endLocationMarker.png";
import Friday_pin from "../../../../assets/image/Planner_map_pins/Friday_pin.png";
import Monday_pin from "../../../../assets/image/Planner_map_pins/Monday_pin.png";
import Saturday_pin from "../../../../assets/image/Planner_map_pins/Saturday_pin.png";
import Sunday_pin from "../../../../assets/image/Planner_map_pins/Sunday_pin.png";
import Thursday_pin from "../../../../assets/image/Planner_map_pins/Thursday_pin.png";
import Tuesday_pin from "../../../../assets/image/Planner_map_pins/Tuesday_pin.png";
import UnFriday_pin from "../../../../assets/image/Planner_map_pins/UnFriday_pin.png";
import UnMonday_pin from "../../../../assets/image/Planner_map_pins/UnMonday_pin.png";
import UnSaturday_pin from "../../../../assets/image/Planner_map_pins/UnSaturday_pin.png";
import UnSunday_pin from "../../../../assets/image/Planner_map_pins/UnSunday_pin.png";
import UnThursday_pin from "../../../../assets/image/Planner_map_pins/UnThursday_pin.png";
import UnTuesday_pin from "../../../../assets/image/Planner_map_pins/UnTuesday_pin.png";
import UnWed_pin from "../../../../assets/image/Planner_map_pins/UnWed_pin.png";
import Wed_pin from "../../../../assets/image/Planner_map_pins/Wed_pin.png";
import Pending_pin from "../../../../assets/image/Planner_map_pins/Pending_pin.png";
import UnPending_pin from "../../../../assets/image/Planner_map_pins/UnPending_pin.png";

// Drop markers


// Service markers

// Return markers

// Drop and Service markers

// Exchange markers

// Pickup markers


import axios from "axios";
import { GET_COMMON_SERVICES, HIDE_LOADER, SHOW_LOADER } from "../../../../redux/auth/actionTypes";
import { TASK_STATUSES, USER_ROLES, commonServicesTypes } from "../../../../helpers/configDefaults";
import { ADD_TASKS_TO_MISSION_API, ASSIGN_MISSION_API, CREATE_MISSION_API, CREATE_MULTIPLE_TASKS_API, CUSTOM_SAVE_MISSIONS_REDUX, CUSTOM_SAVE_TASKS_REDUX, DELETE_MISSION_API, DELETE_TASKS_API, DUPLICATE_MISSION_API, GET_MISSIONS_API, GET_TASKS_API, MAIN_SEARCH_API, OPTIMIZE_MISSION_API, REMOVE_TASKS_API, SAVE_TASK_POSITION_API, UNASSIGN_MISSION_API, UPDATE_MISSION_API, UPDATE_MISSION_DIRECTIONS_API } from "../../../../redux/task/actionTypes";
import { GET_HUB_LIST } from "../../../../redux/hub/actionTypes";
import {
    GET_MULTI_TYPE_USER_API,
    GET_USER_API,
} from "../../../../redux/user/actionTypes";
import { GET_TEAM_LIST } from "../../../../redux/team/actionTypes";
import Swal from "sweetalert2";
import optimize from "../../../../helpers/OptimizeMission";
import { MapDirectionsRenderer } from "../../components/DirectionService";
import {
    errorAlert,
    infoAlert,
    successAlert,
} from "../../../../services/toast.service";
import ModalPopUp from "../../../../components/UI/Modal/Modal";
import { Button, Col, InputGroup, Row, Form as FormB } from "react-bootstrap";
import { ErrorMessage, FastField, Field, Form, Formik } from "formik";
import { AssignPopup } from "../../components/Popups";
import { Calendar } from "react-calendar";
import Select from "react-select";
import DatePicker from "react-date-picker";
import taskService from "../../../../services/task.service";
import fileDownload from "js-file-download";
import { Flatfile, PartialRejection, RecordError } from "@flatfile/sdk";
import authService from "../../../../services/auth.service";
import {
    missionClickhandler,
    selectTasksPolygon,
} from "../../components/clickHandlers";
import ListView from "../listView/ListView";
import { ToastContainer } from "react-toastify";
import { Loader } from "../../../../components/Loader/loader";
import {
    listenToEvent,
    socketConnection,
} from "../../../../services/socket.service";
const timezone = { ...timeZoneHeader() };

const topbarheight = 81;
const defaultDurationTime = 60;
const defaultMaxMissionDuration = 20;
const defaultMaxMissionTasks = 8;
const defaultMissionCapacity = 1000;

const MainContainer = () => {
    const dispatch = useDispatch();
    let localCurrentDate = localStorage.getItem("currentDate");
    let currentFormattedDate = moment().format("YYYY-MM-DD");
    let lastDateChanged = localStorage.getItem("lastDateChanged");
    if (!localCurrentDate) {
        localStorage.setItem("currentDate", new Date());
        localStorage.setItem("lastDateChanged", moment().format("YYYY-MM-DD"));
        localCurrentDate = localStorage.getItem("currentDate");
        lastDateChanged = localStorage.getItem("lastDateChanged");
    }
    if (lastDateChanged !== currentFormattedDate) {
        localStorage.setItem("currentDate", new Date());
        localStorage.setItem("lastDateChanged", moment().format("YYYY-MM-DD"));
    }
    let localView = localStorage.getItem("viewType");

    //Redux data
    const defaultSubscriptionData = useSelector(
        (state) => state.defaultSubscription
    );
    const extensionData = useSelector((state) => state.extensions);
    const userData = useSelector((state) => state.auth.user);
    const superAdminRole =
        userData && userData.role && userData.role === 1 ? true : false;
    const {
        tasksList,
        missionsList,
        taskPageCount,
        taskPageLimit,
        missionPageCount,
        missionPageLimit,
        taskCount,
        unAssignedTasksList,
    } = useSelector((state) => state.task);
    const { commonServices, countryCode, settings, loading } = useSelector(
        (state) => state.authSaga
    );
    let { agentList, userMerchantList } = useSelector((state) => state.user);
    const { hubList } = useSelector((state) => state.hub);
    const { teamList } = useSelector((state) => state.team);
    const [hoverHub, setHoverHub] = useState(null);
    const [datedBookMarks, setDatedBookMarks] = useState([]);
    const [changeTaskStatus, setChangeStatus] = useState(false);
    const [userTeams, setUserTeams] = useState([]);
    // Redux data

    // Ref
    const ref = useRef(null);
    const mySetMissionToggleRef = useRef(false);
    const containerRef = useRef();
    const agentListRef = useRef(agentList);
    const teamListRef = useRef(teamList);
    const contextMenuRef = useRef({
        show: false,
        position: null,
        menu: [],
        type: "",
        data: null,
    });
    const contextMenuDivRef = useRef(null);
    const rightSidebarOpenRef = useRef(false);
    const currentDateRef = useRef(new Date(localCurrentDate));
    const allDatesRef = useRef([]);
    let userMerchantListRef = useRef(userMerchantList);
    //Ref
    const history = useHistory();

    const [viewType, setViewType] = useState(
        localView ? localView : appDefaults.VIEWS.SUPERVISE_CONTROL
    );
    const viewTypeRef = useRef(localView ? localView : appDefaults.VIEWS.SUPERVISE_CONTROL)
    let initialValuesTask = {
        id: "",
        orderType: "D",
        merchantId: "",
        createdFor: "",
        customerId: "",
        userId: "",
        notes: "",
        orderNo: [],
        orderDate:
            viewType && viewType === appDefaults.VIEWS.PLANNER_CONTROL
                ? null
                : new Date(),
        after: "",
        afterTimePicker: null,
        before: "",
        beforeTimePicker: null,
        scheduledTimePicker: null,
        scheduledTime: "",
        afterSeconds: 0,
        beforeSeconds: 0,
        scheduledTimeSeconds: 0,
        durationTime: defaultDurationTime,
        capacity: defaultMissionCapacity,
        capacityUnit: "m3",
        firstName: "",
        lastName: "",
        ISO: "ca",
        countryCode: "+1",
        phoneNumber: "",
        email: "",
        address: "",
        apartment: "",
        afterOpen: false,
        beforeOpen: false,
        scheduleTimeOpen: false,
        taskStatus: "",
        documents: [],
        price: "",
        priceUnitSymbol: "$",
        priceUnit: "USD",
        libraryFiles: [],
        teamIds: [],
        dateChanged: false,
        recurringId: null,
        // beforeTime: "",
        // afterTime: "",
        // scheduleTime: "",
    };
    const initialAgentValues = {
        agentId: "",
        locationType: "",
        address: "",
    };

    const initialValuesOptimize = {
        missionId: "",
        startFrom: "",
        endAt: "",
        maxMissionTasks: defaultMaxMissionTasks,
        maxMissionDuration: defaultMaxMissionDuration,
        maxMissionCapacity: defaultMissionCapacity,
        multiMission: false,
        optimiseMode: "time",
        missionDate: moment(new Date(localCurrentDate)).toDate(),
        teamId: "",
        agentId: "",
    };
    // Local State
    const [searchText, setSearchText] = useState("");
    const [searchData, setSearchData] = useState({});
    const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
    const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
    const [createTask, setCreateTask] = useState(false);
    const [editTask, setEditTask] = useState(false);
    const [windowSize, setWindowSize] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date(localCurrentDate));
    const [toggleState, setToggleState] = useState({});
    const [defaultCenter, setDefaultCenter] = useState({
        lat: routes[0].lat,
        lng: routes[0].lng,
    });
    const [mapSize, setMapSize] = useState({
        // width: "100%",
        // marginLeft: "10px",
        // marginRight: "10px",
        height: "95vh",
    });
    const [size, setSize] = useState({
        width: "100%",
        height: "320px",
    });
    const [map, setMap] = useState(null);
    const [allDates, setAllDates] = useState([]);
    const [plannerLabels, setPlannerLabels] = useState([]);
    const [date, setDate] = useState({
        startDate: momentTz().startOf("D"),
        endDate: momentTz().endOf("D"),
    });
    const dateRef = useRef({
        startDate: momentTz().startOf("D"),
        endDate: momentTz().endOf("D"),
    });

    const [contextMenu, setContextMenu] = useState({
        show: false,
        position: null,
        menu: [],
        type: "",
        data: null,
    });
    const [taskSettingData, setTaskSettingData] = useState(null);
    const [regionalSettingData, setRegionalSettingData] = useState(null);
    const [scheduleDeliverySettingData, setScheduleDeliverySettingData] =
        useState(null);
    const [features, setFeatures] = useState({});
    const [taskSortBy, setTaskSortBy] = useState("_id:asc");
    const [missionSortBy, setMissionSortBy] = useState("_id:asc");
    const [directions, setDirections] = useState(null);
    const [selectedMissions, setSelectedMissions] = useState([]);
    const selectedMissionsRef = useRef([]);
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [taskDetail, setTaskDetail] = useState(null);
    const [lastSelectedTaskIndex, setSelectedTaskIndex] = useState(null);
    const [bookMarks, setBookMarks] = useState([]);
    let bookMarksRef = useRef([]);
    const [showRecurringMissionPopup, setShowRecurringMissionPopup] =
        useState(null);
    const [location, setLocation] = useState("");
    const [buttonText, setButtonText] = useState("Create task");
    const [address, setAddress] = useState("");
    const [isCreateTaskForm, setIsCreateTaskForm] = useState(false);
    const [selectedMission, setSelectedMission] = useState(null);
    const [otherMarkers, setOtherMarkers] = useState([]);
    const [agentBookMarks, setAgentBookMarks] = useState([]);
    const [dummyDirections, setDummyDirections] = useState([]);
    const [currentMission, setcurrentMission] = useState("");
    const currentMissionRef = useRef([]);
    const [taskCounts, setTaskCounts] = useState(0);
    const [lastSelectedPostion, setSelectedTaskPosition] = useState(null);
    const [popup, setPopup] = useState({
        show: false,
        type: "",
    });
    const [missionData, setMissionData] = useState(null);
    const [mapControls, setMapControls] = useState({
        drawPolygon: false,
        sateliteView: false,
    });
    const [assignAgent, setAssignagent] = useState(false);
    const [updateMissionToggle, setUpdateMissionToggle] = useState(false);
    const [customOccurencesToggle, setCustomOccurencesToggle] = useState(false);
    const [duplicateMissionToggle, setDuplicateMissionToggle] = useState(false);
    const [multiMissionToggle, setMultiMissionToggle] = useState(false);
    const [multiMissionOption, setMultiMissionOption] = useState("");
    const [showScheduleDelivery, setShowScheduleDelivery] = useState(false);
    const [selectedBottomMenuTeam, setSelectedBottomMenuTeam] = useState("all");
    const [selectedBottomMenuTeams, setSelectedBottomMenuTeams] = useState([]);
    const [selectedBottomMenuAgent, setSelectedBottomMenuAgent] = useState([]);
    const [selectedBottomMenuMerchant, setSelectedBottomMenuMerchant] = useState(
        []
    );
    const [selectedBottomMenuUser, setSelectedBottomMenuUser] = useState([]);
    const [selectedBottomMenuStatus, setSelectedBottomMenuStatus] = useState([]);
    const [selectedBottomMenuDay, setSelectedBottomMenuDay] = useState([]);
    const [socket, setSocket] = useState(null);
    // Local State

    const myStateRef = useRef(agentBookMarks);

    const [formTaskData, setFormTaskData] = useState(initialValuesTask);
    const [optimiseFormData, setOptimiseFormData] = useState(
        initialValuesOptimize
    );
    const [showOptimiseSection, setShowOptimiseSection] = useState(false);
    const [showSearchComponent, setShowSearchComponent] = useState(false);
    const getWindowDimensions = async () => {
        const { innerWidth: width, innerHeight: height } = window;
        setWindowSize({ width, height });
        let rightsidebarWidth = 370;
        if (!rightSidebarOpenRef.current) rightsidebarWidth = 0;
        let leftSidebarWidth = 270;
        if (!leftSidebarOpen) leftSidebarWidth = 70;
        let mapDiv = document.querySelector(".map-top-container");
        if (mapDiv)
            mapDiv.style.width = `${width - rightsidebarWidth - leftSidebarWidth}px`;

        if (!rightSidebarOpenRef.current) {
            let rightSideBarWrapper = document.querySelector(
                ".right-side-bar-wrapper"
            );
            if (rightSideBarWrapper) {
                rightSideBarWrapper.style.display = "none";
            }
            let rightContainer = document.querySelector(".right-sidebar-container");
            if (rightContainer) {
                rightContainer.style.width = "20px";
                rightContainer.style.background = "transparent";
            }
            if (document.querySelector(".side-bar-right-arrow-btns"))
                document.querySelector(".side-bar-right-arrow-btns").style.left = "2px";
            // let taskBarRight = document.querySelector(".task-bar-right")
            // if (taskBarRight) taskBarRight.style.display = "none";
        } else {
            let rightSideBarWrapper = document.querySelector(
                ".right-side-bar-wrapper"
            );
            if (rightSideBarWrapper) {
                rightSideBarWrapper.style.display = "flex";
            }
            let rightContainer = document.querySelector(".right-sidebar-container");
            if (rightContainer) {
                document.querySelector(".right-sidebar-container").style.width =
                    "370px";
                document.querySelector(".right-sidebar-container").style.background =
                    "#39394b";
            }
            if (document.querySelector(".side-bar-right-arrow-btns"))
                document.querySelector(".side-bar-right-arrow-btns").style.left =
                    "-10px";
        }

        let resizableCompHeight = 0;
        if (viewType === appDefaults.VIEWS.PLANNER_CONTROL) {
            resizableCompHeight = Number(size.height.split("px")[0]) + 135;
        }
        document.querySelector(".main-container-bottom-menu").style.width = `${width - rightsidebarWidth - leftSidebarWidth
            }px`;
        setMapSize({
            width: width - rightsidebarWidth - leftSidebarWidth,
            // marginLeft: "10px",
            // marginRight: "10px",
            height: `${height - topbarheight - resizableCompHeight}px`,
        });
    };

    const handleClick = async () => {
        await delay(200);
        setContextMenu((prev) => {
            return { ...prev, show: false };
        });
        contextMenuRef.current.show = false;
    };

    useEffect(() => {
        window.navigator.geolocation.getCurrentPosition((position) => {
            setDefaultCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        });
        const listener = (e) => {
            if (e.key === "Escape") {
                setSelectedCenter(null);
            }
        };

        window.addEventListener("keydown", listener);
        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, []);

    useEffect(() => {
        let socketData
        if (userData) {
            socketData = socketConnection({ user: userData })
            setSocket(socketData)
        }
        return () => {
            if (socketData) {
                socketData.socket.close()
            }
        };
    }, []);

    useEffect(() => {
        listenToEvent("COMMON_EVENT", async (socketData) => {
            if (socketData.type === "DRIVER_LOCATION") {
                let newBook = [...myStateRef.current];
                let findIndex = newBook.findIndex((i) => i.id === socketData.agentId);
                if (findIndex > -1) {
                    let data = {
                        ...newBook[findIndex],
                        ...socketData.agentCoordinates,
                        id: socketData.agentId,
                        markerImage: agentMarker,
                        notClickable: false,
                        showMarkerLabel: false,
                        ...(socketData.taskId && { taskId: socketData.taskId }),
                        ...(socketData.missionId && { missionId: socketData.missionId }),
                        markerType: "AGENT",
                    };
                    newBook[findIndex] = data;
                } else {
                    let data = {
                        firstName: socketData.firstName,
                        lastName: socketData.lastName,
                        teamId: socketData.teamId,
                        ...socketData.agentCoordinates,
                        id: socketData.agentId,
                        markerImage: agentMarker,
                        notClickable: false,
                        showMarkerLabel: false,
                        ...(socketData.taskId && { taskId: socketData.taskId }),
                        ...(socketData.missionId && { missionId: socketData.missionId }),
                        markerType: "AGENT",
                    };
                    newBook.push(data);
                }
                myStateRef.current = newBook;

                setAgentBookMarks(newBook);
                if (socketData.missionId) {
                    let missionLists = await getMissionListSocket(false, false, true)
                    let mission = missionLists.find(item => { return item.id === socketData.missionId })
                    await delay(1000)
                    if (mission) getDirections(mission, true, bookMarksRef.current, false, missionLists);
                }
            }
        });

        listenToEvent("TASK_EVENT", async (data) => {
            if (data.type === "TASK_UPDATED") {
                if (data.taskId) {
                    getTaskList(true)
                    let missionLists = await getMissionListSocket(false, false, true)
                    let mission = missionLists.find(item => { return item.id === data.missionId })
                    let missions = missionLists
                    dispatch({
                        type: CUSTOM_SAVE_MISSIONS_REDUX,
                        missionList: missions,
                        key: "missionsList"
                    })
                    await delay(1000)
                    if (mission) getDirections(mission, true, bookMarksRef.current, false, missionLists);
                }
            }
            else if (data.type === "MISSION_UPDATED") {
                getTaskList(true)
                let missionLists = await getMissionListSocket(false, false, true)
                let mission = missionLists.find(item => { return item.id === data.missionId })
                await delay(1000)
                if (mission) getDirections(mission, true, bookMarksRef.current, false, missionLists);
            }
            else if (data.type === "MISSION_UPDATED_ADMIN") {
                await getMissionList(false, false, true)
                getTaskList(true)
            }
            else if (data.type === "TASK_UPDATED_ADMIN") {
                await getMissionList(false, false, true)
                getTaskList(true)
            }
        })
    }, [])

    // Use Effect
    useEffect(() => {
        function handleResize() {
            getWindowDimensions();
        }
        getWindowDimensions();
        window.addEventListener("resize", handleResize);
        window.addEventListener("click", handleClick);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("click", handleClick);
        };
    }, []);

    // useEffect(() => {
    //     if (commonServices) {
    //         let planners = commonServices.filter(i => i.type === commonServicesTypes.PLANNER_LABELS)
    //         setPlannerLabels(planners)
    //     }
    // }, [commonServices])

    useEffect(() => {
        if (date && viewType === appDefaults.VIEWS.PLANNER_CONTROL) {
            let allDates = getDates(date.startDate, date.endDate, [], []);
            let dayOfWeeks = allDates.map((i) => i.dayOfWeek);
            getPlannerLabels({ dayOfWeeks });
        }
        getTaskList(true, null, date.startDate, date.endDate);
        getMissionList(true, true, true, null, date.startDate, date.endDate);
    }, [date]);

    useEffect(() => {
        let apiData = {};
        if (selectedBottomMenuMerchant.length) {
            apiData.merchantIds = selectedBottomMenuMerchant;
        }
        if (selectedBottomMenuAgent.length) {
            apiData.agentIds = selectedBottomMenuAgent;
        }
        if (selectedBottomMenuTeam.length) {
            apiData.teamIds = selectedBottomMenuTeam;
        }
        if (selectedBottomMenuTeams.length) {
            apiData.teamIds = selectedBottomMenuTeams;
        }
        if (selectedBottomMenuUser.length) {
            apiData.userIds = selectedBottomMenuUser;
        }
        if (selectedBottomMenuStatus.length) {
            apiData.taskStatuses = selectedBottomMenuStatus;
        }
        let apiKeys = Object.keys(apiData);
        if (apiKeys.length) {
            getTaskList(true, apiData, date.startDate, date.endDate);
        }
        setDirections(null);
    }, [
        selectedBottomMenuMerchant,
        selectedBottomMenuTeams,
        selectedBottomMenuAgent,
        selectedBottomMenuStatus,
        selectedBottomMenuTeam,
        selectedBottomMenuUser,
    ]);

    useEffect(() => {
        if (settings) saveSettings();
    }, [settings]);

    useEffect(() => {
        if (viewType === appDefaults.VIEWS.SUPERVISE_CONTROL) {
            setDate({
                startDate: momentTz().startOf("D"),
                endDate: momentTz().endOf("D"),
            });
            dateRef.current = {
                startDate: momentTz().startOf("D"),
                endDate: momentTz().endOf("D"),
            }
        } else {
            setDate({
                startDate: momentTz().startOf("W"),
                endDate: momentTz().endOf("W"),
            });
            dateRef.current = {
                startDate: momentTz().startOf("W"),
                endDate: momentTz().endOf("W"),
            }
        }

        // getTaskList(true);
        getMissionList(true, true, true);
        currentDateRef.current = currentDate;
        if (currentDate) {
            setFormTaskData((prev) => {
                return {
                    ...prev,
                    orderDate:
                        viewType && viewType === appDefaults.VIEWS.PLANNER_CONTROL
                            ? null
                            : new Date(currentDate),
                };
            });
        }
        setDirections(null);
    }, [currentDate, viewType]);

    useEffect(() => {
        getTeamList();
        let role = USER_ROLES.AGENT;
        getUserList(role);
        getHubList();
        getTaskCount();
        let roles = [];
        if (userData.role === 1) {
            if (userData.subAdmin) {
                roles = [USER_ROLES.USER, USER_ROLES.MERCHANT];
            } else {
                roles = [USER_ROLES.USER];
            }
        } else if (userData.role === 2 && userData.userId) {
            roles = [USER_ROLES.USER];
        } else {
            roles = [USER_ROLES.MERCHANT];
        }
        // roles.push(USER_ROLES.AGENT)
        getMultiTypeUserList(roles);
    }, []);

    useEffect(() => {
        extensionData &&
            extensionData.extension &&
            extensionData.extension.length &&
            extensionData.extension.map((data) => {
                // if (data.isEnabled) {
                data.userExtensions.map((extension) => {
                    setFeatures((prev) => {
                        return {
                            ...prev,
                            ...extension.features,
                            additionalTaskNumber: Number(
                                extension.features.additionalTaskNumber
                            ),
                        };
                    });
                    return {};
                });
                // }
            });
    }, [extensionData]);

    useEffect(() => {
        if (
            defaultSubscriptionData &&
            defaultSubscriptionData.extension &&
            defaultSubscriptionData.extension.data &&
            defaultSubscriptionData.extension.data.results.length
        ) {
            setFeatures((prev) => {
                return {
                    ...prev,
                    ...defaultSubscriptionData.extension.data.results[0].features,
                };
            });
        }
    }, [defaultSubscriptionData]);

    useEffect(() => {
        getWindowDimensions();
    }, [rightSidebarOpen, leftSidebarOpen, size]);

    useEffect(() => {
        getWindowDimensions();
        localStorage.setItem("viewType", viewType);
    }, [viewType]);

    useEffect(() => {
        let currentState = mySetMissionToggleRef.current;
        if (missionsList && missionsList.length && currentState) {
            let mappedObject = {};
            missionsList.map((m) => {
                let obj = {};
                obj[m.id] = false;
                mappedObject = { ...obj, ...mappedObject };
                return obj;
            });
            setToggleState(mappedObject);
        }
        mySetMissionToggleRef.current = false;
    }, [missionsList]);

    useEffect(() => {
        let div = document.querySelector(".right-side-bar-wrapper");
        console.log(" div", div);
        if (createTask || !rightSidebarOpen) {
            if (div) div.style.display = "none";
        } else {
            if (div) div.style.display = "flex";
        }
    }, [createTask, showOptimiseSection, showSearchComponent]);

    useEffect(() => {
        if (tasksList) {
            const data = tasksList.map((t) => {
                if (t && t.location) {
                    let data = {
                        id: t.id,
                        ...t.location,
                        address: t.address,
                        position: t.position,
                        orderType: t.orderType,
                        customerId: t.customerId,
                        agentId: t.agentId,
                        teamId: t.teamId,
                        taskStatus: t.taskStatus,
                        optimised:
                            t.missionId && t.missionId.optimised
                                ? t.missionId.optimised
                                : false,
                        missionDetails:
                            t.missionId && t.missionId.missionDetails
                                ? t.missionId.missionDetails
                                : {},
                        merchantId: t.merchantId,
                        createdFor: t.createdFor,
                        after: t.after,
                        before: t.before,
                        scheduledTime: t.scheduledTime,
                        capacity: t.capacity,
                        capacityUnit: t.capacityUnit,
                        eta: "__",
                        etaSeconds: t.etaSeconds,
                        markerType: "TASK",
                        missioned: t.missioned,
                        updatedAt: t.updatedAt,
                        orderCompletedDate: t.orderCompletedDate,
                        customer: t.customer,
                    };
                    data.distanceUnit =
                        regionalSettingData && regionalSettingData.distanceUnit
                            ? regionalSettingData.distanceUnit
                            : "km";
                    if (t.taskStatus === "unassigned") {
                        data.markerImage = unassignedTaskMarker;
                        data.selectedMarkerImage = selectedUnassignedTaskMarker;
                    } else if (t.taskStatus === "assigned") {
                        data.markerImage = assignedTaskMarker;
                        data.selectedMarkerImage = selectedAssignedMarker;
                    } else if (t.taskStatus === "completed") {
                        data.markerImage = completedTaskMarker;
                        data.selectedMarkerImage = selectedCompletedMarker;
                    } else if (t.taskStatus === "failed") {
                        data.markerImage = failedTaskMarker;
                        data.selectedMarkerImage = selectedFailedTaskMarker;
                    } else if (t.taskStatus === "delayed") {
                        data.markerImage = delayTaskMarker;
                        data.selectedMarkerImage = selectedDelayMarker;
                    } else if (t.taskStatus === "started") {
                        data.markerImage = startedTaskMarker;
                        data.selectedMarkerImage = selectedStartedMarker;
                    } else if (t.taskStatus === "reached") {
                        data.markerImage = startedTaskMarker;
                        data.selectedMarkerImage = selectedStartedMarker;
                    }
                    return data;
                }
                return {};
            });

            setBookMarks(data);
            bookMarksRef.current = data;
            if (viewType === appDefaults.VIEWS.SUPERVISE_CONTROL) {
                setDatedBookMarks([])
            }
        }
    }, [tasksList])

    useEffect(() => {
        if (hubList && hubList.length) {
            let otherMarkers = hubList.map((i) => {
                return {
                    id: i.id,
                    orderType: "SL",
                    markerImage: startLocationMarker,
                    hover: true,
                    hubName: i.hubName,
                    notClickable: true,
                    ...i.location,
                };
            });
            setOtherMarkers(otherMarkers);
        }
    }, [hubList]);

    useEffect(() => {
        let agentCoordinates = [];
        if (agentList && agentList.length) {
            agentList.forEach((item) => {
                if (item.coordinates) {
                    agentCoordinates.push({
                        firstName: item.firstName,
                        lastName: item.lastName,
                        teamId: item.teamId,
                        id: item.id,
                        ...item.coordinates,
                        markerImage: agentMarker,
                        notClickable: false,
                        showMarkerLabel: false,
                        profilePic: item.profilePic,
                        markerType: "AGENT",
                    });
                }
            });
            setAgentBookMarks(agentCoordinates);
        }
    }, [agentList]);

    useEffect(() => {
        let currentState = mySetMissionToggleRef.current;
        if (missionsList && missionsList.length && currentState) {
            let mappedObject = {};
            missionsList.map((m) => {
                let obj = {};
                obj[m.id] = false;
                mappedObject = { ...obj, ...mappedObject };
                return obj;
            });
            setToggleState(mappedObject);
        }
        mySetMissionToggleRef.current = false;
    }, [missionsList]);

    useEffect(() => {
        if (contextMenuRef && contextMenuRef.current.show) {
            let divName = ".context-menu-container";
            let div = document.querySelector(divName);
            if (div) {
                let posX = contextMenuRef.current.position.clientX;
                let posY = contextMenuRef.current.position.clientY;
                if (
                    posX + contextMenuDivRef.current?.offsetWidth + 30 >
                    window.innerWidth
                ) {
                    posX = posX - contextMenuDivRef.current?.offsetWidth;
                }
                if (
                    posY + contextMenuDivRef.current?.offsetHeight + 60 >
                    window.innerHeight
                ) {
                    posY = posY - contextMenuDivRef.current?.offsetHeight;
                }
                div.style.top = `${posY}px`;
                div.style.left = `${posX}px`;
            }
        }
    }, [contextMenu]);

    // useEffect(() => {
    //     if (dummyDirections.length) {
    //         let directionsResultsReturned = 0
    //         let combinedResults
    //         for (let k = 0; k < dummyDirections.length; k++) {
    //             if (directionsResultsReturned === 0) { // first bunch of results in. new up the combinedResults object
    //                 combinedResults = dummyDirections[k];
    //                 directionsResultsReturned++;
    //             }
    //             else {
    //                 // only building up legs, overview_path, and bounds in my consolidated object. This is not a complete
    //                 // directionResults object, but enough to draw a path on the map, which is all I need
    //                 combinedResults.routes[0].legs = combinedResults.routes[0].legs.concat(dummyDirections[k].routes[0].legs);
    //                 combinedResults.routes[0].overview_path = combinedResults.routes[0].overview_path.concat(dummyDirections[k].routes[0].overview_path);

    //                 combinedResults.routes[0].bounds = combinedResults.routes[0].bounds.extend(dummyDirections[k].routes[0].bounds.getNorthEast());
    //                 combinedResults.routes[0].bounds = combinedResults.routes[0].bounds.extend(dummyDirections[k].routes[0].bounds.getSouthWest());
    //                 directionsResultsReturned++;
    //             }
    //             if (directionsResultsReturned === dummyDirections.length) // we've received all the results. put to map
    //                 setDirections(combinedResults);
    //         }
    //     }
    // }, [dummyDirections])

    // Use Effect

    const saveSettings = () => {
        let taskAndMission =
            userData.role === 1 && !userData.subAdmin
                ? "COMMON_TASK_AND_MISSION"
                : "TASK_AND_MISSION";
        let regionalSettings =
            userData.role === 1 && !userData.subAdmin
                ? "COMMON_REGIONAL_SETTINGS"
                : "REGIONAL_SETTINGS";
        let scheduleDeliverySubType =
            userData.role === 1 && !userData.subAdmin
                ? "COMMON_SCHEDULE_DELIVERY"
                : "SCHEDULE_DELIVERY";
        let findTaskSetting = settings.find((i) => {
            return i.subType === taskAndMission;
        });
        console.log(findTaskSetting, "this data is seted in task setting ")
        setTaskSettingData(findTaskSetting);
        if (findTaskSetting) {
            let taskSettingData = findTaskSetting;
            setFormTaskData((prev) => {
                return {
                    ...prev,
                    after:
                        taskSettingData &&
                            (taskSettingData.defaultAfterTime ||
                                taskSettingData.defaultAfterTime === "")
                            ? taskSettingData.defaultAfterTime
                            : "",
                    afterTimePicker:
                        taskSettingData &&
                            (taskSettingData.defaultAfterTimePicker ||
                                taskSettingData.defaultAfterTimePicker === "")
                            ? taskSettingData.defaultAfterTimePicker
                            : null,
                    before:
                        taskSettingData &&
                            (taskSettingData.defaultBeforeTime ||
                                taskSettingData.defaultBeforeTime === "")
                            ? taskSettingData.defaultBeforeTime
                            : "",
                    beforeTimePicker:
                        taskSettingData &&
                            (taskSettingData.defaultBeforeTimePicker ||
                                taskSettingData.defaultBeforeTimePicker === "")
                            ? taskSettingData.defaultBeforeTimePicker
                            : null,
                    scheduledTimePicker:
                        taskSettingData &&
                            (taskSettingData.defaultScheduleTimePicker ||
                                taskSettingData.defaultScheduleTimePicker === "")
                            ? taskSettingData.defaultScheduleTimePicker
                            : null,
                    scheduledTime:
                        taskSettingData &&
                            (taskSettingData.defaultScheduleTime ||
                                taskSettingData.defaultScheduleTime === "")
                            ? taskSettingData.defaultScheduleTime
                            : "",
                    afterSeconds:
                        taskSettingData &&
                            (taskSettingData.defaultAfterTimeSeconds ||
                                taskSettingData.defaultAfterTimeSeconds === 0)
                            ? taskSettingData.defaultAfterTimeSeconds
                            : 0,
                    beforeSeconds:
                        taskSettingData &&
                            (taskSettingData.defaultBeforeTimeSeconds ||
                                taskSettingData.defaultBeforeTimeSeconds === 0)
                            ? taskSettingData.defaultBeforeTimeSeconds
                            : 0,
                    scheduledTimeSeconds:
                        taskSettingData &&
                            (taskSettingData.defaultScheduleTimeSeconds ||
                                taskSettingData.defaultScheduleTimeSeconds === 0)
                            ? taskSettingData.defaultScheduleTimeSeconds
                            : 0,
                    durationTime:
                        taskSettingData &&
                            (taskSettingData.defaultDrop ||
                                taskSettingData.defaultDrop === 0)
                            ? taskSettingData.defaultDrop
                            : defaultDurationTime,
                    capacity:
                        taskSettingData &&
                            (taskSettingData.defaultTaskCapacity ||
                                taskSettingData.defaultTaskCapacity === 0)
                            ? taskSettingData.defaultTaskCapacity
                            : defaultMissionCapacity,
                    documents:
                        taskSettingData &&
                            (taskSettingData.documents ||
                                taskSettingData.documents.length === 0)
                            ? taskSettingData.documents
                            : [],
                };
            });

            setOptimiseFormData((prev) => {
                return {
                    ...prev,
                    maxMissionTasks: taskSettingData &&
                        (taskSettingData.defaultMaxTaskPerMission ||
                            taskSettingData.defaultMaxTaskPerMission === 0)
                        ? taskSettingData.defaultMaxTaskPerMission
                        : defaultMaxMissionTasks,
                    maxMissionDuration: taskSettingData &&
                        (taskSettingData.defaultMaxMissionDuration ||
                            taskSettingData.defaultMaxMissionDuration === 0)
                        ? taskSettingData.defaultMaxMissionDuration
                        : defaultMaxMissionDuration,
                    maxMissionCapacity: taskSettingData &&
                        (taskSettingData.defaultMaxMissionCapacity ||
                            taskSettingData.defaultMaxMissionCapacity === 0)
                        ? taskSettingData.defaultMaxMissionCapacity
                        : defaultMissionCapacity
                }
            })
        }

        let findRegionalSetting = settings.find((i) => {
            return i.subType === regionalSettings;
        });
        if (findRegionalSetting) {
            initialValuesTask = {
                ...initialValuesTask,
                capacityUnit:
                    findRegionalSetting && findRegionalSetting.volumeUnit
                        ? findRegionalSetting.volumeUnit
                        : "m3",
            };
            setFormTaskData((prev) => {
                return {
                    ...prev,
                    capacityUnit:
                        findRegionalSetting && findRegionalSetting.volumeUnit
                            ? findRegionalSetting.volumeUnit
                            : "m3",
                };
            });
        }
        setRegionalSettingData(findRegionalSetting);

        let scheduleDelivery = settings.find((i) => {
            return i.subType === scheduleDeliverySubType;
        });
        setScheduleDeliverySettingData(scheduleDelivery);
    };

    const getPlannerLabels = async ({ dayOfWeeks }) => {
        let params = {
            dayOfWeeks: dayOfWeeks,
        };
        if (userData.subAdmin) {
            params.userId = userData.id;
        }
        let data = {
            payload: params,
            saveInRedux: false,
            resCall: (res) => {
                if (res && res.data && res.data.results) {
                    let planners = res.data.results.filter(
                        (i) => i.type === commonServicesTypes.PLANNER_LABELS
                    );
                    setPlannerLabels(planners);
                }
            },
        };
        dispatch({ type: GET_COMMON_SERVICES, data });
    };

    const handleArrowClickS = (direction) => {
        const date = new Date(currentDate);
        if (direction === "back") {
            date.setDate(date.getDate() - 1);
        } else if (direction === "forward") {
            date.setDate(date.getDate() + 1);
        }
        setDate({
            startDate: momentTz(date).startOf("D"),
            endDate: momentTz(date).endOf("D"),
        });
        dateRef.current = {
            startDate: momentTz(date).startOf("D"),
            endDate: momentTz(date).endOf("D"),
        }
        setCurrentDate(date);
        localStorage.setItem("currentDate", date);
    };

    const _onListChange = async (newList, mission) => {
        let found = false,
            missionId;
        let taskIds = newList.map((t) => t.id);
        // if (missionsList && missionsList.length) {
        //     let missions = missionsList.map(m => {
        //         if (m.id === newList[0].missionId) {
        //             found = true
        //             missionId = m.id
        //             m.tasks = [...newList]
        //             missionClickhandler({ mission: m, })
        //         }
        //         return m
        //     })
        //     if (found) dispatch({
        //         type: CUSTOM_SAVE_TASKS_REDUX,
        //         missionsList: missions,
        //         key: "missionsList"
        //     })
        // }

        let maxMissionDuration = 0;
        let maxMissionCapacity = 0;
        let startFrom,
            endAt,
            missionDetails = {
                totalTime: 0,
                totalDist: 0,
            },
            tasks = {
                locations: [],
            };
        let tasksDistTime = [];
        if (taskIds.length) {
            if (mission) {
                if (mission.startFrom) {
                    startFrom = mission.startFrom;
                }
                if (mission.endAt) {
                    endAt = mission.endAt;
                }
                for (let task in taskIds) {
                    let findTask = mission.tasks.find((item) => {
                        return item.id === taskIds[task];
                    });

                    if (findTask) {
                        maxMissionCapacity += findTask.capacity
                            ? Number(findTask.capacity)
                            : 0;
                        if (findTask.durationTime)
                            maxMissionDuration += Number(findTask.durationTime);
                        if (!startFrom && Number(task) === 0) {
                            startFrom = {
                                location: findTask.location,
                            };
                        } else if (!endAt && Number(task) === Number(taskIds.length) - 1) {
                            endAt = {
                                location: findTask.location,
                            };
                        } else {
                            tasks.locations.push({
                                location: findTask.location,
                                id: findTask.id,
                            });
                        }
                    }
                }
            }
            dispatch({ type: SHOW_LOADER });
            if (startFrom && endAt) {
                let result = await optimize.directionsServiceHere(
                    tasks,
                    false,
                    [],
                    startFrom,
                    endAt
                );
                if (result) {
                    let myroute = result[0];
                    missionDetails.totalDist += Number(myroute.distance);
                    missionDetails.totalTime += Number(myroute.time);

                    if (mission.startFrom && mission.endAt) {
                        for (let i = 0; i < myroute.interconnections.length - 1; i++) {
                            tasksDistTime.push({
                                id: taskIds[i],
                                distanceFromPrevTask:
                                    myroute.interconnections[i].distance / 1000,
                                timeFromPrevTask: Number(myroute.interconnections[i].time) / 60,
                            });
                        }
                    } else {
                        for (let i = 0; i < myroute.interconnections.length; i++) {
                            tasksDistTime.push({
                                id: taskIds[i + 1],
                                distanceFromPrevTask:
                                    myroute.interconnections[i].distance / 1000,
                                timeFromPrevTask: Number(myroute.interconnections[i].time) / 60,
                            });
                        }
                    }
                }
            }
            missionDetails.totalTime += Number(maxMissionDuration * 60);
            if (missionDetails.totalTime) {
                missionDetails.totalTime = missionDetails.totalTime / 3600;
                missionDetails.totalTime = missionDetails.totalTime.toFixed(1);
            }
            if (missionDetails.totalDist) {
                missionDetails.totalDist = missionDetails.totalDist / 1000;
                missionDetails.totalDist = missionDetails.totalDist.toFixed(1);
            }
        }
        let apiData = {
            taskIds,
            missionId: mission.id,
            tasks: tasksDistTime,
            missionTaskCapacity: maxMissionCapacity,
            maxMissionDuration: maxMissionDuration,
            missionDetails: missionDetails,
        };

        dispatch({
            type: SAVE_TASK_POSITION_API,
            data: {
                payload: {
                    ...apiData,
                },
                loading: true,
                resCall: (res) => {
                    missionClickhandler({
                        mission: res.mission,
                        preventDirection: false,
                        setSelectedMissions,
                        selectedMissionsRef,
                        setDefaultCenter,
                        getDirections,
                        setcurrentMission,
                        currentMissionRef,
                        setSelectedCenter,
                    });
                },
            },
        });
        // await taskService.saveTaskPosition(apiData);
        // let results = await props.getMissionList(true, true);
        // if (results && results.length) {
        //     let mission = results.find((i) => i.id === missionId);
        //     if (mission) {
        //         props.getDirections(mission);
        //     }
        // }
        // setList(newList);
    };

    let colors = [
        {
            color: "#AC6AF0",
            markerImage: Monday_pin,
            selectedMarkerImage: UnMonday_pin,
        },
        {
            color: "#E78B87",
            markerImage: Tuesday_pin,
            selectedMarkerImage: UnTuesday_pin,
        },
        {
            color: "#80BD5E",
            markerImage: Wed_pin,
            selectedMarkerImage: UnWed_pin,
        },
        {
            color: "#E49051",
            markerImage: Thursday_pin,
            selectedMarkerImage: UnThursday_pin,
        },
        {
            color: "#2681C4",
            markerImage: Friday_pin,
            selectedMarkerImage: UnFriday_pin,
        },
        {
            color: "#F7ED8A",
            markerImage: Saturday_pin,
            selectedMarkerImage: UnSaturday_pin,
        },
        {
            color: "#8fbdfcff",
            markerImage: Sunday_pin,
            selectedMarkerImage: UnSunday_pin,
        },
    ];

    let getDates = (startDate, stopDate, taskList, missionList) => {
        let dateArray = [];
        let currentDate = momentTz(startDate).tz(timezone.timezone);
        stopDate = momentTz(stopDate).tz(timezone.timezone);
        let i = 0;
        while (currentDate <= stopDate) {
            let obj = {
                date: momentTz(currentDate).tz(timezone.timezone).format("YYYY-MM-DD"),
                startDate: momentTz(currentDate).tz(timezone.timezone).startOf("d"),
                endDate: momentTz(currentDate).tz(timezone.timezone).endOf("d"),
                dayOfWeek: momentTz(currentDate).tz(timezone.timezone).day(),
                ...colors[i],
                today: false,
            };
            if (
                momentTz(currentDate).tz(timezone.timezone).format("YYYY-MM-DD") ===
                momentTz().tz(timezone.timezone).format("YYYY-MM-DD")
            ) {
                obj.today = true;
            }
            obj.taskList = taskList.filter((items) => {
                let orderDate = moment(items.orderDate)._a;
                return (
                    items.orderDate &&
                    items.orderDate !== null &&
                    items.taskStatus === TASK_STATUSES.UNASSIGNED &&
                    items.taskCategory === TASK_STATUSES.UNASSIGNED &&
                    !items.missioned &&
                    moment(orderDate).format("YYYY-MM-DD") === obj.date
                );
            });
            obj.bookmarkTaskList = taskList.filter((item) => {
                let orderDate = moment(item.orderDate)._a;
                return moment(orderDate).format("YYYY-MM-DD") === obj.date;
            });
            obj.missionList = missionList.filter((item) => {
                let orderDate = moment(item.missionDate)._a;
                return moment(orderDate).format("YYYY-MM-DD") === obj.date;
            });
            dateArray.push(obj);
            i++;
            currentDate = moment(currentDate).add(1, "days");
        }
        allDatesRef.current = dateArray;
        return dateArray;
    };

    const openSidebar = async () => {
        setRightSidebarOpen(true);
        rightSidebarOpenRef.current = true;
        document.querySelector(".right-sidebar-container").style.width = "370px";
        await delay(100);
        let div = document.querySelector(".right-side-bar-wrapper");
        if (div) div.style.display = "flex";
        // document.querySelector(".month-calendar-container").style.width = "100%";
    };

    const closeSidebar = async () => {
        setRightSidebarOpen(false);
        rightSidebarOpenRef.current = false;
        setCreateTask(false);
        document.querySelector(".right-sidebar-container").style.width = "20px";
        await delay(100);
        if (!createTask)
            document.querySelector(".right-side-bar-wrapper").style.display = "none";
        // document.querySelector(".month-calendar-container").style.width = "100%";
    };

    const findAddressFromLatLng = async ({ lat, lng }) => {
        let result = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        );
        return result;
    };

    const getTaskList = async (
        saveInRedux = false,
        apiData,
        startDate,
        endDate,
        setSelectedTask
    ) => {
        let data = { date: currentDate, limit: 100 };
        if (apiData) {
            data = { ...data, ...apiData };
        }
        if (viewTypeRef.current === appDefaults.VIEWS.PLANNER_CONTROL) {
            data = await getPlannerApiData(startDate, endDate, apiData);
        }

        if (
            userData.role === 1 &&
            userData.userId &&
            userData.permittedUsers &&
            userData.permittedUsers.length
        ) {
            data.userIds = userData.permittedUsers;
        }
        const currentUser = userData;
        if (currentUser) data.userId = currentUser.id;
        if (viewType === appDefaults.VIEWS.LIST_VIEW) {
            data.limit = 10;
        }
        dispatch({
            type: GET_TASKS_API,
            data: {
                payload: {
                    ...data,
                    all: true,
                },
                auth: true,
                saveInRedux,
                resCall: (res) => {
                    if (setSelectedTask) {
                        selectTasksPolygon({
                            tasks: res.result?.results,
                            tasksList,
                            setBookMarks,
                            setSelectedTasks,
                            bookMarks,
                            dispatch,
                        });
                    }
                },
            },
        });
    };

    const selectedDateRange = (startDate, endDate) => {
        let timezone = { ...timeZoneHeader() };
        return {
            $gte: momentTz(startDate).tz(timezone.timezone).startOf("d")._d,
            $lt: momentTz(endDate).tz(timezone.timezone).endOf("d")._d,
        };
    };
    let getApiData = () => {
        let apiData = {};
        if (selectedBottomMenuStatus.length) {
            let allDates = allDatesRef.current;
            let dateFilters = [];

            let timezone = { ...timeZoneHeader() };
            for (let status of selectedBottomMenuStatus) {
                if (status === "new") {
                    apiData.new = true;
                } else if (status === "pending") {
                    apiData.pending = true;
                }
            }
            for (let day of selectedBottomMenuDay) {
                if (allDates[day]) {
                    dateFilters.push(
                        JSON.stringify({
                            $gte: momentTz(allDates[day].startDate)
                                .tz(timezone.timezone)
                                .startOf("d")._d,
                            $lte: momentTz(allDates[day].endDate)
                                .tz(timezone.timezone)
                                .endOf("d")._d,
                        })
                    );
                }
            }
            if (dateFilters.length) {
                apiData.dateFilters = dateFilters;
            }
        }
        return apiData;
    };
    const getPlannerApiData = async (startDate, endDate, apiData) => {
        if (!startDate || !endDate) {
            startDate = dateRef.current.startDate;
            endDate = dateRef.current.endDate;
        }
        let data = {
            limit: 1000,
            orderDate: selectedDateRange(startDate, endDate),
            withoutDate: true,
        };
        let newApiData = await getApiData();
        if (
            !apiData &&
            (newApiData.teamIds ||
                newApiData.dateFilters ||
                newApiData.new ||
                newApiData.pending)
        ) {
            apiData = newApiData;
        }
        if (apiData) {
            if (apiData.dateFilters) {
                delete data.orderDate;
                delete data.withoutDate;
            }
            data = { ...data, ...apiData };
        }
        return data;
    };

    const getMissionList = async (
        updateState = true,
        setToggleState = false,
        saveInRedux,
        date,
        startDate,
        endDate
    ) => {
        let data = {
            date: date ? date : currentDate,
        };
        if (viewType === appDefaults.VIEWS.PLANNER_CONTROL) {
            data = await getPlannerApiData(startDate, endDate);
        }
        if (
            userData.role === 1 &&
            userData.userId &&
            userData.permittedUsers &&
            userData.permittedUsers.length
        ) {
            data.userIds = userData.permittedUsers;
        }
        const currentUser = userData;
        mySetMissionToggleRef.current = setToggleState;
        if (currentUser) data.userId = currentUser.id;
        dispatch({
            type: GET_MISSIONS_API,
            data: {
                payload: {
                    ...data,
                },
                auth: true,
                saveInRedux,
            },
        });
    };
    const getMissionListSocket = async (
        updateState = true,
        setToggleState = false,
        saveInRedux,
        date,
        startDate,
        endDate
    ) => {
        let data = {
            date: date ? date : currentDate,
        };
        console.log("viewTypeviewType", viewType, viewTypeRef)
        if (viewTypeRef.current === appDefaults.VIEWS.PLANNER_CONTROL) {
            data = await getPlannerApiData(startDate, endDate);
        }
        if (
            userData.role === 1 &&
            userData.userId &&
            userData.permittedUsers &&
            userData.permittedUsers.length
        ) {
            data.userIds = userData.permittedUsers;
        }
        const currentUser = userData;
        mySetMissionToggleRef.current = setToggleState;
        if (currentUser) data.userId = currentUser.id;
        const response = await taskService.getMissionList(data);
        let result = [];
        if (response.status === 200) {
            result = response.data.results || [];
            return result;
        }
    };

    const getTeamList = async (params, setFieldValue) => {
        let payload = {
            limit: 100,
        };
        if (
            userData.role === 1 &&
            userData.userId &&
            userData.permittedUsers &&
            userData.permittedUsers.length
        ) {
            payload.userIds = userData.permittedUsers;
        }
        if (userData.teamIds) {
            payload.teamIds = userData.teamIds;
        }
        if (userData.subAdmin) {
            delete payload.teamIds;
            delete payload.userIds;
        }

        const currentUser = userData;
        if (currentUser && currentUser.role !== 1) payload.userId = currentUser.id;

        if (params && params.userId) {
            payload.userId = params.userId;
        }
        dispatch({ type: GET_TEAM_LIST, data: { payload } });
    };

    const getMultiTypeUserList = async (roles) => {
        let payload = {
            limit: 1000,
            roles,
        };

        const currentUser = userData;
        if (currentUser && currentUser.role !== 1) payload.userId = currentUser.id;

        if (
            userData.role === 1 &&
            userData.userId &&
            userData.permittedUsers &&
            userData.permittedUsers.length
        ) {
            payload.userIds = userData.permittedUsers;
        }

        if (userData.role === 2 && userData.userId) {
            payload.userIds = [userData.userId];
        }
        // if (userData.subAdmin) {
        //     delete payload.userIds;
        // }
        dispatch({
            type: GET_MULTI_TYPE_USER_API,
            data: {
                payload,
                saveInRedux: true,
            },
        });
    };

    const getUserList = async (role) => {
        let payload = {
            limit: 1000,
            role,
        };

        const currentUser = userData;
        if (currentUser && currentUser.role !== 1) payload.userId = currentUser.id;

        if (
            userData.role === 1 &&
            userData.userId &&
            userData.permittedUsers &&
            userData.permittedUsers.length
        ) {
            payload.userIds = userData.permittedUsers;
        }

        if (userData.role === 2 && userData.userId) {
            payload.userIds = [userData.userId];
        }
        // if (userData.subAdmin) {
        //     delete payload.userIds;
        // }
        dispatch({
            type: GET_USER_API,
            data: {
                payload,
                saveInRedux: true,
            },
        });
    };
    const getHubList = async () => {
        let payload = {
            limit: 100,
        };
        const currentUser = userData;
        if (currentUser && currentUser.role !== 1) payload.userId = currentUser.id;
        dispatch({ type: GET_HUB_LIST, data: { payload } });
    };

    const editMenuHandler = async ({ selectedTask }) => {
        let currentTask = "";
        let taskSelected =
            selectedTasks && selectedTasks.length ? selectedTasks : selectedTask;
        if (taskSelected && taskSelected.length) {
            if (tasksList && tasksList.length) {
                currentTask = tasksList.find(
                    (t) => t.id === taskSelected[0] && !t.missioned
                );
            }
            if (!currentTask) {
                if (missionsList && missionsList.length) {
                    for (let i = 0; i < missionsList.length; i++) {
                        if (missionsList[i].tasks && missionsList[i].tasks.length) {
                            currentTask = missionsList[i].tasks.find((t) => {
                                return t.id === taskSelected[0];
                            });

                            if (currentTask) {
                                break;
                            }
                        }
                    }
                }
            }
        }
        if (currentTask && currentTask.recurring) {
            setShowRecurringMissionPopup({
                show: true,
                type: "EDIT_TASK",
                text: "Edit recurring task",
                currentTask,
            });
        } else {
            await editMenu({ currentTask });
        }
    };

    const editMenu = async ({ option, currentTask }) => {
        setButtonText("Edit Task");
        if (currentTask) {
            setCreateTask(true);
            setEditTask(true)
            setIsCreateTaskForm(true);
            if (currentTask.location) {
                setLocation(currentTask.location);
            }
            if (currentTask.address) {
                setAddress(currentTask.address);
            }
            // if (currentTask && currentTask.docs && currentTask.docs.length) {
            //     editDocs(currentTask.docs)

            // } else {
            //     setSelectedDocs([])
            //     // getLibraryList()
            // }

            let createdFor = currentTask.merchantId.id;
            let orderDate;
            if (currentTask.orderDate) {
                orderDate = momentTz(currentTask.orderDate).tz(timezone.timezone)._a;
            }
            let missionId = null;
            if (currentTask.missionId) {
                if (currentTask.missionId.id) {
                    missionId = currentTask.missionId.id;
                } else if (currentTask.missionId._id) {
                    missionId = currentTask.missionId._id;
                } else missionId = currentTask.missionId;
            }
            let formData = {
                id: currentTask.id,
                orderType: currentTask.orderType,
                merchantId: currentTask.merchantId
                    ? currentTask.merchantId.id
                    : userData.id,
                userId: currentTask.userId ? currentTask.userId.id : userData.id,
                createdFor: currentTask.createdFor
                    ? currentTask.createdFor.id
                    : createdFor
                        ? createdFor
                        : userData.id,
                customerId: currentTask.customerId.id,
                notes: currentTask.notes,
                orderDate: orderDate ? momentTz(orderDate).startOf("d")._d : null,
                after: currentTask.after,
                before: currentTask.before,
                beforeTimePicker: currentTask.beforeTimePicker || null,
                afterTimePicker: currentTask.afterTimePicker || null,
                scheduledTime: currentTask.scheduledTime,
                scheduledTimePicker: currentTask.scheduledTimePicker || null,
                durationTime: currentTask.durationTime,
                currentDurationTime: currentTask.durationTime,
                orderNo: currentTask.orderNo,
                capacity: currentTask.capacity,
                capacityUnit: currentTask.capacityUnit,
                price: currentTask.price,
                priceUnit: currentTask.priceUnit,
                priceUnitSymbol: currentTask.priceUnitSymbol,
                firstName:
                    currentTask.customer && currentTask.customer.firstName
                        ? `${currentTask.customer.firstName} ${currentTask.customer.lastName
                            ? currentTask.customer.lastName
                            : ""
                        }`
                        : currentTask.customerId.firstName,
                lastName:
                    currentTask.customer && currentTask.customer.lastName
                        ? currentTask.customer.lastName
                        : currentTask.customerId.lastName,
                phoneNumber: currentTask.customer.phoneNumber,
                countryCode: currentTask.customer.countryCode || "+1",
                email:
                    currentTask.email && currentTask.customer.email
                        ? currentTask.customer.email
                        : currentTask.customerId.email,
                address: currentTask.address ? currentTask.address : "",
                apartment: currentTask.apartment,
                afterOpen: false,
                beforeOpen: false,
                scheduleTimeOpen: false,
                documents: currentTask.documents,
                libraryFiles: currentTask.libraryFiles,
                values: currentTask.teamIds,
                missionId: missionId ? missionId : null,
                teamIds: currentTask.teamIds.map((i) => i.id),

                // beforeTime: currentTask.beforeTime,
                // afterTime: currentTask.afterTime,
                // scheduleTime: currentTask.scheduleTime
            };
            if (option === "ALL_FOLLOWING_TASKS") {
                formData.recurringId = currentTask.recurringId;
                formData.taskString = currentTask.taskString;
            }
            if (formData.userId !== userData.id) {
                formData.values = [];
            }
            setShowRecurringMissionPopup(false);
            setFormTaskData(formData);
            openSidebar();
            // setTeamList(currentTask.teamIds.length > 1 ? [selectAllOption, ...teams] : [selectAllOption, ...currentTask.teamIds])
        }
    };

    const deleteTaskFunction = async (result, option, taskSelected) => {
        if (result.isConfirmed) {
            let data = {
                taskIds: taskSelected,
            };
            if (option === "ALL_FOLLOWING_TASKS") {
                data.taskStrings = showRecurringMissionPopup.taskStrings;
                data.recurringIds = showRecurringMissionPopup.recurringIds;
                data.currentDate = currentDate;
            }
            dispatch({
                type: DELETE_TASKS_API,
                data: {
                    payload: data,
                    resCall: (res) => {
                        handleDeleteTask(res);
                    },
                },
            });
        }
    };
    const distance = (totalDist) => {
        let value = totalDist;
        let distanceMetric = "KM";
        if (
            regionalSettingData &&
            regionalSettingData.distanceUnit &&
            regionalSettingData.distanceUnit === "m"
        ) {
            value = KmToMiles(value);
            distanceMetric = "Miles";
        }
        return `${value.toFixed(2)} ${distanceMetric}`;
    };

    const handleDeleteTask = (response) => {
        let missionIds = [];
        for (let task of response.taskList) {
            if (task.missionId) {
                let checkMission = missionIds.includes(task.missionId);
                if (!checkMission) {
                    missionIds.push(task.missionId._id);
                }
            }
        }
        if (missionIds.length) {
            dispatch({
                type: UPDATE_MISSION_DIRECTIONS_API,
                data: {
                    payload: { missionIds }, resCall: () => {
                        getMissionList(true, false, true)
                    }
                },
            });
        }
    };

    const removeTaskHandler = async ({ selectedTask }) => {
        let taskSelected =
            selectedTasks && selectedTasks.length ? selectedTasks : selectedTask;
        let recurring = false;
        let taskStrings = [];
        let recurringIds = [];
        if (taskSelected && taskSelected.length) {
            if (tasksList && tasksList.length) {
                for (let task of selectedTasks) {
                    let findTasks = tasksList.find((t) => t.id === task);
                    if (findTasks && findTasks.recurring && findTasks.missionId) {
                        if (!recurring && findTasks.recurring) {
                            recurring = true;
                        }
                        taskStrings.push(findTasks.taskString);
                        recurringIds.push(findTasks.recurringId);
                    }
                }
            }
        }
        if (recurring) {
            setShowRecurringMissionPopup({
                show: true,
                type: "REMOVE_TASK",
                text: "Remove recurring task",
                taskStrings,
                recurringIds,
            });
        } else {
            await removeMenu(null, selectedTask);
        }
    };

    const removeMenu = async (option, selectedTask) => {
        setShowRecurringMissionPopup(false);
        if (selectedTask && selectedTask.length) {
            let data = { taskIds: selectedTask };
            if (option === "ALL_FOLLOWING_TASKS") {
                data.taskStrings = showRecurringMissionPopup.taskStrings;
                data.recurringIds = showRecurringMissionPopup.recurringIds;
                data.currentDate = currentDate;
            }
            dispatch({
                type: REMOVE_TASKS_API,
                data: {
                    payload: data,
                    viewType: viewType,
                    resCall: (res) => {
                        handleDeleteTask(res);
                    },
                },
            });
        }
    };

    const deleteMenuHandler = async ({ selectedTask, list }) => {
        let recurring = false;
        let taskStrings = [];
        let recurringIds = [];
        let taskSelected =
            selectedTasks && selectedTasks.length && !list ? selectedTasks : selectedTask;
        if (taskSelected && taskSelected.length) {
            if (tasksList && tasksList.length) {
                for (let task of taskSelected) {
                    let findTasks = tasksList.find((t) => t.id === task);
                    if (findTasks && findTasks.recurring && findTasks.missionId) {
                        if (!recurring && findTasks.recurring) {
                            recurring = true;
                        }
                        taskStrings.push(findTasks.taskString);
                        recurringIds.push(findTasks.recurringId);
                    }
                }
            }
        }
        if (recurring) {
            setShowRecurringMissionPopup({
                show: true,
                type: "DELETE_TASK",
                text: "Delete recurring task",
                taskStrings,
                recurringIds,
            });
        } else {
            await deleteMenu(null, taskSelected);
        }
    };

    const deleteMenu = async (option, taskSelected) => {
        setShowRecurringMissionPopup(false);
        if (taskSelected && taskSelected.length) {
            Swal.fire({
                title: "Are you sure?",
                text: "You want to delete task(s)!",
                icon: "warning",
                showCancelButton: true,
                color: "#ffffff",
                background: "#151719",
                confirmButtonColor: "#1A1D1F",
                cancelButtonColor: "#1A1D1F",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => deleteTaskFunction(result, option, taskSelected));
        }
    };

    const duplicateTaskHandler = ({ selectedTask }) => {
        setButtonText("Create Task");
        let currentTask = "";
        let taskSelected =
            selectedTasks && selectedTasks.length ? selectedTasks : selectedTask;
        if (taskSelected && taskSelected.length) {
            if (tasksList && tasksList.length) {
                currentTask = tasksList.find((t) => t.id === taskSelected[0]);
            }
            if (!currentTask) {
                if (missionsList && missionsList.length) {
                    for (let i = 0; i < missionsList.length; i++) {
                        if (missionsList[i].tasks && missionsList[i].tasks.length) {
                            currentTask = missionsList[i].tasks.find((t) => {
                                return t.id === taskSelected[0];
                            });

                            if (currentTask) {
                                break;
                            }
                        }
                    }
                    // missionList.forEach(m=>{
                    //   if(m.tasks && m.tasks)
                    // })
                }
            }
        }
        if (currentTask) {
            if (currentTask.location) {
                setLocation(currentTask.location);
            }
            if (currentTask.address) {
                setAddress(currentTask.address);
            }
            // if (currentTask && currentTask.docs && currentTask.docs.length) {
            //     editDocs(currentTask.docs)

            // } else {
            //     setSelectedDocs([])
            //     // getLibraryList()
            // }
            let orderDate
            if (currentTask.orderDate) {
                orderDate = momentTz(currentTask.orderDate).tz(timezone.timezone)._a;
            }
            let form = {
                orderType: currentTask.orderType,
                merchantId: currentTask.merchantId
                    ? currentTask.merchantId.id
                    : userData.id,
                userId: currentTask.userId ? currentTask.userId.id : userData.id,
                createdFor: currentTask.createdFor
                    ? currentTask.createdFor.id
                    : userData.id,
                customerId: currentTask.customerId.id,
                notes: currentTask.notes,
                orderDate: orderDate
                    ? momentTz(orderDate).startOf("d")._d
                    : null,
                after: currentTask.after,
                before: currentTask.before,
                beforeTimePicker: currentTask.beforeTimePicker || null,
                afterTimePicker: currentTask.afterTimePicker || null,
                scheduledTime: currentTask.scheduledTime,
                scheduledTimePicker: currentTask.scheduledTimePicker || null,
                durationTime: currentTask.durationTime,
                capacity: currentTask.capacity,
                firstName:
                    currentTask.customer && currentTask.customer.firstName
                        ? currentTask.customer.firstName
                        : currentTask.customerId.firstName,
                lastName:
                    currentTask.customer && currentTask.customer.lastName
                        ? currentTask.customer.lastName
                        : currentTask.customerId.lastName,
                phoneNumber: currentTask.customerId.phoneNumber,
                countryCode: currentTask.customerId.countryCode || "",
                email:
                    currentTask.customer && currentTask.customer.email
                        ? currentTask.customer.email
                        : currentTask.customerId.email,
                address: currentTask.address ? currentTask.address : "",
                apartment: currentTask.apartment,
                afterOpen: false,
                beforeOpen: false,
                scheduleTimeOpen: false,
                values: currentTask.teamIds,
                teamIds: currentTask.teamIds.map((i) => i.id),
            };
            if (form.userId !== userData.id) {
                form.values = [];
            }
            setFormTaskData(form);
            setCreateTask(true);
            setIsCreateTaskForm(true);
        }
    };

    const handleTaskClick = async () => {
        let findAddress = await findAddressFromLatLng({ ...contextMenuRef?.current?.data })
        let address = findAddress?.data?.results[0]?.formatted_address
        let location = findAddress?.data?.results[0]?.geometry?.location
        setAddress(address);
        setLocation(location)
        setFormTaskData((prev) => {
            return {
                ...prev,
                address: address
            }
        })
        setCreateTask(true)
        setIsCreateTaskForm(true)
        openSidebar()
        setContextMenu({ show: false })
    }
    const createMissionApiHandler = async () => {
        let date = currentDate
        if (selectedTasks && selectedTasks.length) {
            let maxMissionDuration = 0;
            let maxMissionCapacity = 0;
            let missionTaskCapacityUnit = taskSettingData && taskSettingData.defaultMaxMissionCapacityUnit ? taskSettingData.defaultMaxMissionCapacityUnit : "Cap";
            let startFrom, endAt, missionDetails = {
                totalTime: 0,
                totalDist: 0
            }, tasks = {
                locations: []
            };
            for (let task in selectedTasks) {
                let findTask = tasksList.find(item => {
                    return item.id === selectedTasks[task]
                })
                if (Number(task) === 0) {
                    date = findTask?.orderDate
                }
                if (findTask) {
                    maxMissionCapacity += findTask.capacity ? Number(findTask.capacity) : 0;
                    if (findTask.durationTime) maxMissionDuration += Number(findTask.durationTime);
                    if (Number(task) === 0) {
                        startFrom = {
                            location: findTask.location
                        }
                    }
                    else if (Number(task) === Number(selectedTasks.length) - 1) {
                        endAt = {
                            location: findTask.location
                        }
                    }
                    else {
                        tasks.locations.push({ location: findTask.location, id: findTask.id })
                    }
                }
            }
            let tasksDistTime = []
            if (startFrom && endAt) {
                let result = await optimize.directionsServiceHere(tasks, false, [], startFrom, endAt);
                if (result) {
                    let myroute = result[0];
                    missionDetails.totalDist += Number(myroute.distance);
                    missionDetails.totalTime += Number(myroute.time);
                    for (let i = 0; i < myroute.interconnections.length; i++) {
                        tasksDistTime.push({
                            id: selectedTasks[i + 1],
                            distanceFromPrevTask: myroute.interconnections[i].distance / 1000,
                            timeFromPrevTask: Number(myroute.interconnections[i].time) / 60
                        })
                    }
                }
            }
            missionDetails.totalTime += Number(maxMissionDuration * 60);
            if (missionDetails.totalTime) {
                missionDetails.totalTime = missionDetails.totalTime / 3600
                missionDetails.totalTime = missionDetails.totalTime.toFixed(1)
            }
            if (missionDetails.totalDist) {
                missionDetails.totalDist = missionDetails.totalDist / 1000
                missionDetails.totalDist = missionDetails.totalDist.toFixed(1)
            }
            dispatch({
                type: CREATE_MISSION_API,
                data: {
                    payload: {
                        taskIds: selectedTasks,
                        date: date,
                        tasks: tasksDistTime,
                        missionTaskCapacity: maxMissionCapacity,
                        maxMissionDuration: maxMissionDuration,
                        missionTaskCapacityUnit: missionTaskCapacityUnit,
                        missionDetails: missionDetails,
                    },
                    viewType: viewType,
                    sortBy: missionSortBy,
                    pageLimit: missionPageLimit,
                    pageCount: missionPageCount,
                    resCall: () => {
                        setSelectedTasks([])
                    }
                }
            })
        }
    };
    const assignedUnassignedETA = (currentTime, mission, task, index, string) => {
        // if (mission.missionCategory === "unassigned") {
        //     currentTime = "__"
        // }
        // else {
        if (Number(index) === 0) {
            if (mission.startFrom && mission.endAt) {
                let defaultStartTime = taskSettingData && taskSettingData.defaultAfterTimeSeconds ? taskSettingData.defaultAfterTimeSeconds : "8:00"
                // if (task.after) {
                //     defaultStartTime = task.afterSeconds;
                // }
                let newDate = moment().format("YYYY-MM-DD");
                let time = moment(newDate).add(defaultStartTime, "s").add(Number(task.timeFromPrevTask), "m");
                mission.allTasks[index].calculatedeta = time._d;
                currentTime = time.format("hh:mm a");
            }
            else {
                let defaultStartTime = taskSettingData && taskSettingData.defaultAfterTimeSeconds ? taskSettingData.defaultAfterTimeSeconds : "8:00"
                // if (task.after) {
                //     defaultStartTime = task.afterSeconds;
                // }
                let newDate = moment().format("YYYY-MM-DD");
                let time = moment(newDate).add(defaultStartTime, "s")
                mission.allTasks[index].calculatedeta = time._d;
                currentTime = time.format("hh:mm a");
            }
        }
        else {
            let addTime = 0;
            addTime += Number(mission.allTasks[Number(index) - 1].durationTime)
            addTime += Number(mission.allTasks[Number(index) - 1].etaSeconds || 0) / 60;
            addTime += Number(task.timeFromPrevTask)
            let time = moment(mission.allTasks[Number(index) - 1].calculatedeta)
            time = time.add(addTime, "m")
            mission.allTasks[index].calculatedeta = time._d;
            currentTime = time.format("hh:mm a");

            let a = moment(mission.allTasks[Number(index) - 1].calculatedeta);
            let diff = time.diff(a, 'm')
            let hours = Math.floor(diff / 60);
            let minutes = diff % 60;
            if (hours > 0) {
                string += ` ${hours} hour`
            }
            if (minutes > 0) {
                string += ` ${minutes} mins`
            }
            string = `(${string})`
        }
        // }
        return { currentTime, string }
    }
    const agentDirections = async ({ places, travelMode }) => {
        let { result, props, status } = await MapDirectionsRenderer({ places, travelMode });
        if (status === window.google.maps.DirectionsStatus.OK) {
            return result
        }
        else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
            await delay(3000);
            return await agentDirections({ places, travelMode })
        }
        else {
            return errorAlert("No Route Found")
        }
    }
    const saveOptimisedMision = async (data) => {
        dispatch({
            type: OPTIMIZE_MISSION_API,
            data: {
                payload: data,
                resCall: (res) => { handleOptimizeResponse(res) }
            }
        })
    }
    const handleOptimizeResponse = (response) => {
        if (response) {
            delete initialValuesOptimize.maxMissionCapacity
            delete initialValuesOptimize.maxMissionDuration
            delete initialValuesOptimize.maxMissionTasks
            setOptimiseFormData(initialValuesOptimize)
            setShowOptimiseSection(false)
            successAlert("Mission optimised successfully")
            let mission = response && response.mission ? response.mission : {}
            if (mission) {
                setSelectedMissions([mission.id]);
                selectedMissionsRef.current = [mission.id]
                setcurrentMission(mission.id);
                currentMissionRef.current = mission.id
                getDirections(mission, false, bookMarksRef.current, false)
            }
            // getMissionList(true, false, true)
        }
    }

    const handleOptimiseSubmit = async (formValue, optimiseFormValue) => {
        console.log(formValue, optimiseFormValue);
        try {
            // setLoading(true)
            let formData = formValue;
            if (optimiseFormValue) formData = optimiseFormValue;
            else formData = optimiseFormData
            if (formValue && formValue.multiMissionOption === "cancel") {
                // setLoading(false)
                return;
            } else {
                if (
                    (formValue &&
                        formValue.multiMissionOption === "proceedMultiMission") ||
                    formData.multiMission
                ) {
                    if ((features && features.multiMission) || superAdminRole) {
                        let { allMissionTasks, totalDist, totalTime, tasks } =
                            await optimize.handleMultiMission(
                                formData,
                                defaultDurationTime,
                                [selectedMission],
                                hubList,
                                moment
                            );
                        let missionData = [selectedMission].find(
                            (i) => i.id === formData.missionId
                        );
                        let findEmptyArray = allMissionTasks.some(
                            (i) => Number(i.length) === 0
                        );
                        if (findEmptyArray) {
                            return Swal.fire({
                                title: "Error!",
                                text: "Some tasks do not fit the criteria, Please try with new criteria.",
                                icon: "error",
                                color: "#ffffff",
                                background: "#151719",
                                confirmButtonColor: "#1A1D1F",
                                cancelButtonColor: "#1A1D1F",
                                confirmButtonText: "Okay",
                            });
                        }
                        for (let task in allMissionTasks) {
                            if (Number(task) === 0) {
                                let maxMissionCapacity = 0;
                                maxMissionCapacity = allMissionTasks[task].reduce(
                                    (partialSum, a) => partialSum + a.capacity,
                                    0
                                );
                                let data = {
                                    ...formData,
                                    taskIds: allMissionTasks[task].map((i) => i.id),
                                    missionDetails: {
                                        totalDist,
                                        totalTime,
                                    },
                                    tasks: tasks,
                                    missionTaskCapacity: maxMissionCapacity,
                                };
                                // if (data.missionDate) {
                                //     let timezone = { ...timeZoneHeader() };
                                //     data.missionDate = momentTz(data.missionDate).tz(
                                //         timezone.timezone
                                //     )._d;
                                // }
                                dispatch({
                                    type: OPTIMIZE_MISSION_API,
                                    data: {
                                        payload: data,
                                        resCall: (res) => {
                                            handleOptimizeResponse(res);
                                        },
                                    },
                                });
                                continue;
                            } else {
                                const waypointss = allMissionTasks[task].map((p) => {
                                    return {
                                        location: { lat: p.location.lat, lng: p.location.lng },
                                        id: p.id,
                                    };
                                });
                                const origin = waypointss[0];
                                const destination = waypointss[waypointss.length - 1];
                                let waypoints = [];
                                for (let i = 1; i < waypointss.length; i++) {
                                    waypoints.push(waypointss[i]);
                                }
                                let directionService;
                                if (origin && destination) {
                                    directionService = await optimize.directionsServiceHere(
                                        { locations: waypoints },
                                        false,
                                        [],
                                        { location: origin.location },
                                        { location: destination.location }
                                    );
                                }
                                let missionDetails = {
                                    totalDist: 0,
                                    totalTime: 0,
                                },
                                    tasksDistTime = [],
                                    maxMissionDuration = 0,
                                    maxMissionCapacity = 0;

                                if (directionService) {
                                    let myroute = directionService[0];

                                    missionDetails.totalDist += Number(myroute.distance);
                                    missionDetails.totalTime += Number(myroute.time);
                                    for (
                                        let i = 0;
                                        i < myroute.interconnections.length - 1;
                                        i++
                                    ) {
                                        tasksDistTime.push({
                                            id: allMissionTasks[task][i + 1].id,
                                            distanceFromPrevTask:
                                                myroute.interconnections[i].distance / 1000,
                                            timeFromPrevTask:
                                                Number(myroute.interconnections[i].time) / 60,
                                        });
                                    }

                                    missionDetails.totalTime += maxMissionDuration * 60;
                                    if (missionDetails.totalTime) {
                                        missionDetails.totalTime = missionDetails.totalTime / 3600;
                                        missionDetails.totalTime = Number(
                                            missionDetails.totalTime.toFixed(1)
                                        );
                                    }
                                    if (missionDetails.totalDist) {
                                        missionDetails.totalDist = missionDetails.totalDist / 1000;
                                        missionDetails.totalDist = Number(
                                            missionDetails.totalDist.toFixed(1)
                                        );
                                    }
                                }

                                let data = {
                                    taskIds: allMissionTasks[task].map((i) => i.id),
                                    date: missionData.missionDate,
                                    missionDetails,
                                    tasks: tasksDistTime,
                                };
                                maxMissionCapacity = allMissionTasks[task].reduce(
                                    (partialSum, a) => partialSum + a.capacity,
                                    0
                                );
                                data.missionTaskCapacity = maxMissionCapacity;
                                dispatch({
                                    type: CREATE_MISSION_API,
                                    data: {
                                        payload: data,
                                        sortBy: missionSortBy,
                                        pageLimit: missionPageLimit,
                                        pageCount: missionPageCount,
                                    },
                                    viewType: viewType,
                                });
                            }
                        }
                        setShowOptimiseSection(false);
                        getMissionList(false, false, true);
                        // setLoading(false)
                        return Swal.fire(
                            "Mission optimized!",
                            `${allMissionTasks.length} missions were created for optimization.`,
                            "success"
                        );
                    } else {
                        history.push({
                            pathname: "/Business/multiMission",
                        });
                    }
                } else {
                    let returnedValues;
                    if (formData.optimiseMode === "distance") {
                        console.log("hubList", hubList);
                        returnedValues = await optimize.calculateTimeDistace(
                            formData,
                            defaultDurationTime,
                            [selectedMission],
                            hubList
                        );
                        console.log("returnedValues", returnedValues);
                    } else {
                        console.log("hubList", hubList);
                        returnedValues = await optimize.optimizeDataTimeMode(
                            formData,
                            defaultDurationTime,
                            [selectedMission],
                            hubList,
                            moment
                        );
                    }
                    if (!returnedValues) {
                        return infoAlert("No routes found");
                    }
                    // setLoading(false)
                    return saveOptimisedMision(returnedValues);
                }
            }
        } catch (err) {
            console.log("error:::", err);
            // setLoading(false)
            return errorAlert(err ? err : "Something went wrong");
        }
    };

    const updateMission = async (apiData, type) => {
        if (type === "UPDATE") {
            dispatch({
                type: UPDATE_MISSION_API,
                data: {
                    payload: apiData,
                    resCall: (res) => {
                        handleUpdateMission(res);
                    },
                },
            });
        } else {
            if (apiData.repeatType) apiData.repeatType = apiData.repeatType.id;
            if (apiData.repeatType === "MONTH" && apiData.repeatOnDays) {
                if (apiData.repeatOnDays.id === "DAY")
                    apiData.repeatOnDay = [apiData.repeatOnDays.value];
                else apiData.repeatForDay = [apiData.repeatOnDays.value];
            }
            apiData.startDate = momentTz(apiData.missionDate)
                .tz(timezone.timezone)
                .add(32, "d")
                .startOf("D");
            dispatch({
                type: DUPLICATE_MISSION_API,
                data: {
                    payload: apiData,
                    resCall: (res) => {
                        handleUpdateMission(res);
                    },
                },
            });
        }
    };
    const handleUpdateMission = async (response) => {
        if (response && response.success) {
            await getMissionList(false, false, true);
            await getTaskList(true);
        }
        setUpdateMissionToggle(false);
    };

    const deleteMission = async (option) => {
        let data = { missionId: selectedMissionsRef?.current[0], currentDate };
        if (option && option === "ALL_FOLLOWING_MISSIONS") {
            data.recurringId = showRecurringMissionPopup?.missionDetail?.recurringId;
        }
        setDirections(null);
        setUpdateMissionToggle(false);
        setShowRecurringMissionPopup(false);
        dispatch({
            type: DELETE_MISSION_API,
            data: {
                payload: {
                    ...data,
                },
            },
        });
    };

    const deleteMissionClickHandler = async ({ selectedM }) => {
        if (selectedMissionsRef && selectedMissionsRef?.current.length) {
            let missionDetail = missionsList.find(
                (m) => m.id === selectedMissionsRef?.current[0]
            );
            if (missionDetail && missionDetail.recurring) {
                setShowRecurringMissionPopup({
                    show: true,
                    type: "DELETE",
                    text: "Delete recurring mission",
                    missionDetail,
                });
            } else {
                if (window.confirm(`Are you sure ?`)) {
                    await deleteMission();
                }
            }
        }
        return {};
    };

    const calculateETA = async (mission, task, index) => {
        let currentTime = moment().format("hh:mm a");
        let string = ""
        if (mission.missionCategory === "unassigned") {
            let result = assignedUnassignedETA(currentTime, mission, task, index, string)
            currentTime = result.currentTime;
            string = result.string;
        }
        else {
            if (mission.missionStatus === "started" || mission.missionStatus === "failed") {
                if (task.taskStatus === "started") {
                    let time = moment();
                    let result;
                    if (mission.agentCoordinates && mission.agentCoordinates.lat && mission.agentCoordinates.lng && task.location && task.location.lat && task.location.lng) {
                        result = await optimize.directionsServiceHere({ locations: [] }, false, [],
                            { location: mission.agentCoordinates },
                            { location: task.location })
                    }
                    if (result && result[0]) {
                        let myroute = result[0]
                        let eta = Number(myroute.time);
                        if (eta) {
                            time.add(eta, "seconds")
                        }
                    }
                    mission.allTasks[index].calculatedeta = time._d;
                    currentTime = time.format("hh:mm a");
                    let durationTime = Math.floor(task.etaSeconds / 60)
                    if (task.etaSeconds) string = `(${durationTime} min)`
                }
                else {
                    if (task.taskStatus !== "completed" && task.taskStatus !== "failed") {
                        if (Number(index) === 0) {
                            let time = moment().add(task.durationTime, "m")
                            mission.allTasks[index].calculatedeta = time._d;
                            currentTime = time.format("hh:mm a");
                            let durationTime = Math.floor(task.durationTime)
                            string = `(${durationTime} min)`
                        }
                        else {
                            let addTime = 0;
                            addTime += Number(mission.allTasks[Number(index) - 1].durationTime)
                            addTime += Number(mission.allTasks[Number(index) - 1].etaSeconds || 0) / 60;
                            addTime += Number(task.timeFromPrevTask)
                            let time = moment(mission.allTasks[Number(index) - 1].calculatedeta).add(addTime, "m");
                            mission.allTasks[index].calculatedeta = time._d;
                            currentTime = time.format("hh:mm a");
                            let a = moment();
                            let diff = time.diff(a, 'm')
                            let hours = Math.floor(diff / 60);
                            let minutes = Math.floor(diff % 60);
                            if (hours > 0) {
                                string += ` ${hours} hour`
                            }
                            if (minutes > 0) {
                                string += ` ${minutes} mins`
                            }
                            string = `(${string})`
                        }
                    }
                }
            }
            else {
                let result = assignedUnassignedETA(currentTime, mission, task, index, string)
                currentTime = result.currentTime;
                string = result.string;
            }
        }

        return { currentTime, string };
    };

    const updateMissionDateHandler = () => {
        let missionCurrent = currentMissionRef?.current ? currentMissionRef?.current : currentMission
        let currentMissionData = missionsList.find(item => {
            return item.id === missionCurrent
        })
        if (currentMissionData) {
            setMissionData({ id: selectedMissionsRef?.current[0], userId: currentMissionData.userId.id, missionDate: currentMissionData.missionDate, name: currentMissionData.name, type: "UPDATE" });
            setUpdateMissionToggle(true)
        }
    }
    const duplicateMissionHandler = () => {
        let missionCurrent = currentMissionRef?.current ? currentMissionRef?.current : currentMission
        let currentMissionData = missionsList.find(item => {
            return item.id === missionCurrent
        })
        setMissionData({
            id: selectedMissionsRef?.current[0],
            userId: currentMissionData.userId.id,
            missionDate: currentMissionData.missionDate,
            name: currentMissionData.name,
            type: "DUPLICATE",
            repeat: false,
            endsOn: "NEVER",
            repeatEvery: 1,
            endAfterOccurence: 1,
            repeatOnDays: { id: "DAY", value: momentTz().tz(timezone.timezone).date(), name: `Monthly on day ${momentTz().tz(timezone.timezone).date()}` },
            repeatOnDay: [],
            repeatType: { id: "DAY", name: "Day" },
            selectedRepeatValue: { id: "notRepeat", name: "Does not repeat" }
        });
        setUpdateMissionToggle(true)
    };

    let getAllETA = async (mission) => {
        if (mission.allTasks && mission.allTasks.length) {
            let maxMissionCapacity = 0, maxMissionDuration = 0;
            let startFrom, endAt, missionDetails = {
                totalTime: 0,
                totalDist: 0
            }, tasks = {
                locations: []
            };
            if (mission.startFrom) {
                startFrom = mission.startFrom
            }
            if (mission.endAt) {
                endAt = mission.endAt
            }
            for (let task in mission.allTasks) {
                maxMissionCapacity += mission.allTasks[task].capacity ? Number(mission.allTasks[task].capacity) : 0;
                if (mission.allTasks[task].durationTime) maxMissionDuration += Number(mission.allTasks[task].durationTime);
                if (!startFrom && Number(task) === 0) {
                    startFrom = {
                        location: mission.allTasks[task].location
                    }
                }
                else if (!endAt && Number(task) === Number(mission.allTasks.length) - 1) {
                    endAt = {
                        location: mission.allTasks[task].location
                    }
                }
                else {
                    tasks.locations.push({ location: mission.allTasks[task].location, id: mission.allTasks[task].id })
                }
            }
            if (startFrom && endAt) {
                let result = await optimize.directionsServiceHere(tasks, false, [], startFrom, endAt);
                if (result) {
                    let myroute = result[0];
                    missionDetails.totalDist += Number(myroute.distance);
                    missionDetails.totalTime += Number(myroute.time);

                    if (mission.startFrom && mission.endAt) {
                        for (let i = 0; i < myroute.interconnections.length - 1; i++) {
                            mission.allTasks[i].distanceFromPrevTask = myroute.interconnections[i].distance / 1000;
                            mission.allTasks[i].timeFromPrevTask = Number(myroute.interconnections[i].time) / 60;
                        }
                    }
                    else {
                        for (let i = 0; i < myroute.interconnections.length; i++) {
                            mission.allTasks[i + 1].distanceFromPrevTask = myroute.interconnections[i].distance / 1000;
                            mission.allTasks[i + 1].timeFromPrevTask = Number(myroute.interconnections[i].time) / 60;
                        }
                    }

                    missionDetails.totalTime += (maxMissionDuration * 60);
                    if (missionDetails.totalTime) {
                        missionDetails.totalTime = missionDetails.totalTime / 3600
                        missionDetails.totalTime = missionDetails.totalTime.toFixed(1)
                    }
                    if (missionDetails.totalDist) {
                        missionDetails.totalDist = missionDetails.totalDist / 1000
                        missionDetails.totalDist = missionDetails.totalDist.toFixed(1)
                    }
                }
            }

        }
        return mission
    }

    const getDirections = async (missionInput, missionClicked, currentMarkers, showLoader = true, tempMissionList, datedCurrentMarkers) => {
        if (showLoader) {
            dispatch({
                type: SHOW_LOADER
            })
        }
        let mission = JSON.parse(JSON.stringify(missionInput))
        let cordinates = [], allowedCoordinates = [], bookMarksToUpdate = [], newMarkers = [];
        let markers = [...bookMarks];
        if (currentMarkers) {
            markers = [...currentMarkers]
        }
        markers = markers.map((i) => {
            delete i.markerIndex;
            delete i.showMarkerLabel;
            return i
        })
        setBookMarks(markers)
        bookMarksRef.current = markers;
        let datedMarkers = [...datedBookMarks];
        if (datedCurrentMarkers) {
            datedMarkers = [...datedCurrentMarkers]
        }
        datedMarkers = datedMarkers.map((i) => {
            delete i.markerIndex;
            delete i.showMarkerLabel;
            return i
        })
        setDatedBookMarks(datedMarkers)
        let allowedTasks = mission.tasks.map(i => i.id)
        mission = await getAllETA(mission)
        if (mission && mission.missionCategory === "unassigned") {
            if (mission.allTasks && mission.allTasks.length) {
                for (let index in mission.allTasks) {
                    let t = mission.allTasks[index];
                    if (t && t.location) {
                        let data = {
                            id: t.id, ...t.location, address: t.address,
                            agentId: mission.agentId, customerId: t.customerId, position: t.position, orderType: t.orderType, teamId: mission.teamId, taskStatus: t.taskStatus,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            capacity: t.capacity, capacityUnit: t.capacityUnit, eta: t.eta, etaSeconds: t.etaSeconds, missioned: t.missioned, updatedAt: t.updatedAt, orderCompletedDate: t.orderCompletedDate,
                            customer: t.customer
                        }
                        data.distanceUnit = regionalSettingData && regionalSettingData.distanceUnit ? regionalSettingData.distanceUnit : "km"
                        mission = JSON.parse(JSON.stringify(mission))
                        let eta = await calculateETA(mission, t, index)
                        // data.calculatedeta = eta;
                        data.eta = `${eta.currentTime}${eta.string}`;

                        if (eta.currentTime && allowedTasks.includes(t.id)) {
                            let findTaskIndex = mission.tasks.findIndex(i => i.id === t.id);
                            if (findTaskIndex >= 0) {
                                mission.tasks[findTaskIndex].eta = eta.currentTime
                            }
                        }
                        if (t.taskStatus === "unassigned") {
                            data.markerImage = unassignedTaskMarker;
                            data.selectedMarkerImage = selectedUnassignedTaskMarker;
                        }
                        else if (t.taskStatus === "assigned") {
                            data.markerImage = assignedTaskMarker;
                            data.selectedMarkerImage = selectedAssignedMarker;
                        }
                        else if (t.taskStatus === "completed") {
                            data.markerImage = completedTaskMarker;
                            data.selectedMarkerImage = selectedCompletedMarker;
                        }
                        else if (t.taskStatus === "failed") {
                            data.markerImage = failedTaskMarker;
                            data.selectedMarkerImage = selectedFailedTaskMarker;
                        }
                        else if (t.taskStatus === "delayed") {
                            data.markerImage = delayTaskMarker;
                            data.selectedMarkerImage = selectedDelayMarker;
                        }
                        else if (t.taskStatus === "started") {
                            data.markerImage = startedTaskMarker;
                            data.selectedMarkerImage = selectedStartedMarker;
                        }
                        else if (t.taskStatus === "reached") {
                            data.markerImage = startedTaskMarker;
                            data.selectedMarkerImage = selectedStartedMarker;
                        }
                        if (data.eta) {
                            let newBookmarks = [...bookMarks]
                            if (currentMarkers) {
                                newBookmarks = [...currentMarkers]
                            }
                            let findBookmark = newBookmarks.findIndex(i => { return i.id === t.id });

                            let newDatedBookmarks = [...datedBookMarks]
                            if (datedCurrentMarkers) {
                                newDatedBookmarks = [...datedCurrentMarkers]
                            }
                            let findDatedBookmark = newDatedBookmarks.findIndex(i => { return i.id === t.id });
                            if (findBookmark >= 0) {
                                newBookmarks[findBookmark].eta = data.eta
                                newBookmarks[findBookmark].showMarkerLabel = true;
                                newBookmarks[findBookmark].taskStatus = t.taskStatus;
                                newBookmarks[findBookmark].markerImage = data.markerImage;
                                newBookmarks[findBookmark].selectedMarkerImage = data.selectedMarkerImage;
                                newBookmarks[findBookmark].position = data.position;
                                newBookmarks[findBookmark].markerIndex = Number(index) + 1;
                                bookMarksToUpdate.push(newBookmarks[findBookmark])
                            }
                            else if (findDatedBookmark >= 0) {
                                findDatedBookmark[findBookmark].eta = data.eta
                                newDatedBookmarks[findBookmark].showMarkerLabel = true;
                                newDatedBookmarks[findBookmark].markerIndex = Number(index) + 1;
                                newDatedBookmarks[findBookmark].taskStatus = t.taskStatus;
                                newDatedBookmarks[findBookmark].markerImage = data.markerImage;
                                newDatedBookmarks[findBookmark].position = data.position;
                                newDatedBookmarks[findBookmark].selectedMarkerImage = data.selectedMarkerImage;
                                bookMarksToUpdate.push(newDatedBookmarks[findBookmark])
                            }
                            else {
                                if (allowedTasks.includes(t.id)) {
                                    data.showMarkerLabel = true;
                                    data.markerIndex = Number(index) + 1;
                                    newMarkers.push(data)
                                }
                            }
                        }
                        cordinates.push(data);
                    }
                }
                if (bookMarksToUpdate.length) {
                    let newBookmarks = [...bookMarks];
                    if (currentMarkers) {
                        newBookmarks = [...currentMarkers]
                    }
                    for (let marker of bookMarksToUpdate) {
                        let findBookmark = newBookmarks.findIndex(i => { return i.id === marker.id });
                        if (findBookmark >= 0) {
                            newBookmarks[findBookmark] = marker
                        }
                    }
                    setBookMarks(newBookmarks)
                    bookMarksRef.current = newBookmarks;
                    let newDatedBookmarks = [...datedBookMarks];
                    if (datedCurrentMarkers) {
                        newDatedBookmarks = [...datedCurrentMarkers]
                    }
                    for (let marker of bookMarksToUpdate) {
                        let findBookmark = newDatedBookmarks.findIndex(i => { return i.id === marker.id });
                        if (findBookmark >= 0) {
                            newDatedBookmarks[findBookmark] = marker
                        }
                    }
                    setDatedBookMarks(newDatedBookmarks)

                }
                if (newMarkers.length) {
                    setBookMarks((prev) => {
                        return [...prev, ...newMarkers]
                    })
                    setDatedBookMarks((prev) => {
                        return [...prev, ...newMarkers]
                    })
                }
                if (mission.optimised) {
                    cordinates.unshift({
                        id: mission.id, ...mission.startFrom.location, address: mission.startFrom.address,
                        agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                        taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                        markerImage: startLocationMarker
                    })
                    cordinates.push({
                        id: mission.id + 1, ...mission.endAt.location, address: mission.endAt.address,
                        agentId: mission.agentId, customerId: "", position: "", orderType: "EL", teamId: mission.teamId,
                        taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                        markerImage: endLocationMarker
                    })
                    setOtherMarkers([
                        {
                            id: mission.id, ...mission.startFrom.location, address: mission.startFrom.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: startLocationMarker
                        },
                        {
                            id: mission.id + 1, ...mission.endAt.location, address: mission.endAt.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "EL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: endLocationMarker
                        }
                    ])
                }
                for (let coor of cordinates) {
                    let ifExists = allowedTasks.includes(coor.id)
                    if (coor.orderType === "SL" || coor.orderType === "EL" || ifExists) {
                        allowedCoordinates.push(coor)
                    }
                }
                if (allowedCoordinates.length && allowedCoordinates.length > 1) {
                    let directions = allowedCoordinates
                    // let parts = [], max = 25 - 1;
                    // for (let i = 0; i < allowedCoordinates.length; i = i + max)
                    //     parts.push(allowedCoordinates.slice(i, i + max + 1));

                    // for (let i = 0; i < parts.length; i++) {
                    //     let { result, props, status } = await MapDirectionsRenderer({ places: parts[i], travelMode: window.google.maps.TravelMode.DRIVING });
                    //     if (status === window.google.maps.DirectionsStatus.OK) {
                    //         directions.push(result)
                    //     }
                    //     else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                    //         await delay(2000);
                    //         i--;
                    //     }
                    //     else {
                    //         errorAlert("No Route Found")
                    //     }
                    // }
                    if (directions.length) {
                        // setDummyDirections(directions)
                        setDirections(directions);
                    } else {
                        errorAlert("No Route Found");
                    }
                } else {
                    setDirections(null);
                }
            }
        }
        if (mission && mission.missionCategory === "assigned") {
            let agent = {};
            if (mission.allTasks && mission.allTasks.length) {
                for (let index in mission.allTasks) {
                    let t = mission.allTasks[index];
                    if (t && t.location) {
                        let data = {
                            id: t.id,
                            ...t.location,
                            address: t.address,
                            agentId: mission.agentId,
                            customerId: t.customerId,
                            position: t.position,
                            orderType: t.orderType,
                            teamId: mission.teamId,
                            taskStatus: t.taskStatus,
                            taskLength: mission.tasks ? mission.tasks.length : 0,
                            optimised: mission.optimised,
                            missionDetails: mission.missionDetails,
                            capacity: t.capacity,
                            capacityUnit: t.capacityUnit,
                            eta: t.eta,
                            etaSeconds: t.etaSeconds,
                            missioned: t.missioned,
                            updatedAt: t.updatedAt,
                            orderCompletedDate: t.orderCompletedDate,
                            customer: t.customer,
                        };
                        data.distanceUnit =
                            regionalSettingData && regionalSettingData.distanceUnit
                                ? regionalSettingData.distanceUnit
                                : "km";
                        mission = JSON.parse(JSON.stringify(mission));
                        let eta = await calculateETA(mission, t, index);
                        // data.calculatedeta = eta;
                        data.eta = `${eta.currentTime}${eta.string}`;

                        if (eta.currentTime && allowedTasks.includes(t.id)) {
                            let findTaskIndex = mission.tasks.findIndex((i) => i.id === t.id);
                            if (findTaskIndex >= 0) {
                                mission.tasks[findTaskIndex].eta = eta.currentTime;
                                if (
                                    mission.tasks[findTaskIndex].taskStatus ===
                                    TASK_STATUSES.COMPLETED ||
                                    mission.tasks[findTaskIndex].taskStatus ===
                                    TASK_STATUSES.FAILED
                                ) {
                                    mission.tasks[findTaskIndex].eta = moment(
                                        data.orderCompletedDate
                                    ).format("hh:mm a");
                                }
                            }
                        }
                        if (t.taskStatus === "unassigned") {
                            data.markerImage = unassignedTaskMarker;
                            data.selectedMarkerImage = selectedUnassignedTaskMarker;
                        } else if (t.taskStatus === "assigned") {
                            data.markerImage = assignedTaskMarker;
                            data.selectedMarkerImage = selectedAssignedMarker;
                        } else if (t.taskStatus === "completed") {
                            data.markerImage = completedTaskMarker;
                            data.selectedMarkerImage = selectedCompletedMarker;
                        } else if (t.taskStatus === "failed") {
                            data.markerImage = failedTaskMarker;
                            data.selectedMarkerImage = selectedFailedTaskMarker;
                        } else if (t.taskStatus === "delayed") {
                            data.markerImage = delayTaskMarker;
                            data.selectedMarkerImage = selectedDelayMarker;
                        } else if (t.taskStatus === "started") {
                            data.markerImage = startedTaskMarker;
                            data.selectedMarkerImage = selectedStartedMarker;
                        } else if (t.taskStatus === "reached") {
                            data.markerImage = startedTaskMarker;
                            data.selectedMarkerImage = selectedStartedMarker;
                        }
                        if (data.eta) {
                            let newBookmarks = [...bookMarks];
                            if (currentMarkers) {
                                newBookmarks = [...currentMarkers]
                            }
                            let findBookmark = newBookmarks.findIndex((i) => {
                                return i.id === t.id;
                            });
                            let newDatedBookmarks = [...datedBookMarks]
                            if (datedCurrentMarkers) {
                                newDatedBookmarks = [...datedCurrentMarkers]
                            }
                            let findDatedBookmark = newDatedBookmarks.findIndex(i => { return i.id === t.id });
                            if (findBookmark >= 0) {
                                newBookmarks[findBookmark].eta = data.eta
                                newBookmarks[findBookmark].showMarkerLabel = true;
                                newBookmarks[findBookmark].markerIndex = Number(index) + 1;
                                newBookmarks[findBookmark].taskStatus = t.taskStatus;
                                newBookmarks[findBookmark].markerImage = data.markerImage;
                                newBookmarks[findBookmark].selectedMarkerImage = data.selectedMarkerImage;
                                bookMarksToUpdate.push(newBookmarks[findBookmark])
                            }
                            else if (findDatedBookmark >= 0) {
                                findDatedBookmark[findBookmark].eta = data.eta
                                newDatedBookmarks[findBookmark].showMarkerLabel = true;
                                newDatedBookmarks[findBookmark].markerIndex = Number(index) + 1;
                                newDatedBookmarks[findBookmark].taskStatus = t.taskStatus;
                                newDatedBookmarks[findBookmark].markerImage = data.markerImage;
                                newDatedBookmarks[findBookmark].selectedMarkerImage = data.selectedMarkerImage;
                                bookMarksToUpdate.push(newDatedBookmarks[findBookmark])
                            } else {
                                if (allowedTasks.includes(t.id)) {
                                    data.showMarkerLabel = true;
                                    data.markerIndex = Number(index) + 1;
                                    newMarkers.push(data);
                                }
                            }
                        }
                        cordinates.push(data);
                    }
                }

                let findAgentCoordinate = agentBookMarks.find((item) => {
                    return item.id === mission.agentId.id;
                });

                if (findAgentCoordinate) {
                    if (!findAgentCoordinate.taskId) {
                        if (mission.tasks.length) {
                            let task = mission.tasks.find(i => {
                                return i.taskStatus !== "completed" && i.taskStatus !== "assigned" && i.taskStatus !== "failed"
                            })
                            if (task) {
                                findAgentCoordinate.taskId = task.id;
                                findAgentCoordinate.missionId = mission.id;
                            }
                            // else {
                            //     findAgentCoordinate.taskId = mission.tasks[0].id;
                            //     findAgentCoordinate.missionId = mission.id;
                            // }
                        }
                    }
                    agent = findAgentCoordinate
                    myStateRef.current = [findAgentCoordinate]
                    setAgentBookMarks([findAgentCoordinate])
                }
                else {
                    agent = {
                        id: mission.agentId.id,
                        ...(mission.agentCoordinates && { ...mission.agentCoordinates }),
                        markerImage: agentMarker,
                        showMarkerLabel: false,
                        notClickable: false,
                        markerType: "AGENT",
                        firstName: mission.agentId.firstName,
                        lastName: mission.agentId.lastName,
                        teamId: mission.teamId,
                    }
                    if (mission.agentId.coordinates) {
                        agent = {
                            ...agent,
                            ...mission.agentId.coordinates
                        }
                    }
                    if (mission.tasks.length) {
                        let task = mission.tasks.find(i => {
                            return i.taskStatus !== "completed" && i.taskStatus !== "assigned" && i.taskStatus !== "failed"
                        })
                        // console.log("tasktasktask", task)
                        if (task) {
                            agent.taskId = task.id;
                            agent.missionId = mission.id;
                        }
                        // else {
                        //     agent.taskId = mission.tasks[0].id;
                        //     agent.missionId = mission.id;
                        // }
                    }
                    if (agent.taskId && (agent.agentCoordinates || (agent.lat && agent.lng))) {
                        myStateRef.current = [agent]
                        setAgentBookMarks([agent])
                    }
                }
                if (bookMarksToUpdate.length) {
                    let newBookmarks = [...bookMarks];
                    if (currentMarkers) {
                        newBookmarks = [...currentMarkers]
                    }
                    for (let marker of bookMarksToUpdate) {
                        let findBookmark = newBookmarks.findIndex(i => { return i.id === marker.id });
                        if (findBookmark >= 0) {
                            newBookmarks[findBookmark] = marker
                        }
                    }
                    setBookMarks(newBookmarks)
                    bookMarksRef.current = newBookmarks;
                    let newDatedBookmarks = [...datedBookMarks];
                    if (datedCurrentMarkers) {
                        newDatedBookmarks = [...datedCurrentMarkers]
                    }
                    for (let marker of bookMarksToUpdate) {
                        let findBookmark = newDatedBookmarks.findIndex(i => { return i.id === marker.id });
                        if (findBookmark >= 0) {
                            newDatedBookmarks[findBookmark] = marker
                        }
                    }
                    setDatedBookMarks(newDatedBookmarks)
                }
                if (newMarkers.length) {
                    setBookMarks((prev) => {
                        return [...prev, ...newMarkers]
                    })
                    setDatedBookMarks((prev) => {
                        return [...prev, ...newMarkers]
                    })
                    bookMarksRef.current = [...bookMarksRef.current, ...newMarkers];
                }
                if (mission.optimised) {
                    cordinates.unshift({
                        id: mission.id, ...mission.startFrom.location, address: mission.startFrom.address,
                        agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                        taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                        markerImage: startLocationMarker
                    })
                    cordinates.push({
                        id: mission.id + 1, ...mission.endAt.location, address: mission.endAt.address,
                        agentId: mission.agentId, customerId: "", position: "", orderType: "EL", teamId: mission.teamId,
                        taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                        markerImage: endLocationMarker
                    })
                    setOtherMarkers([
                        {
                            id: mission.id, ...mission.startFrom.location, address: mission.startFrom.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: startLocationMarker
                        },
                        {
                            id: mission.id + 1, ...mission.endAt.location, address: mission.endAt.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "EL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: endLocationMarker
                        }
                    ])
                } else {
                    if (mission.startLocation) {
                        cordinates.unshift({
                            id: mission.id, ...mission.startLocation, address: mission.address,
                            agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            markerImage: startLocationMarker
                        })
                        setOtherMarkers([
                            {
                                id: mission.id, ...mission.startLocation, address: mission.address,
                                agentId: mission.agentId, customerId: "", position: "", orderType: "SL", teamId: mission.teamId,
                                taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                                markerImage: startLocationMarker
                            },
                        ])
                    }
                }
                for (let coor of cordinates) {
                    let ifExists = allowedTasks.includes(coor.id)
                    if (coor.orderType === "SL" || coor.orderType === "EL" || ifExists) {
                        allowedCoordinates.push(coor)
                    }
                }
                if (allowedCoordinates.length && allowedCoordinates.length > 0) {
                    let directions = allowedCoordinates
                    // let parts = [], max = 25 - 1;
                    // for (let i = 0; i < allowedCoordinates.length; i = i + max)
                    //     parts.push(allowedCoordinates.slice(i, i + max + 1));

                    // for (let i = 0; i < parts.length; i++) {
                    //     let { result, props, status } = await MapDirectionsRenderer({ places: parts[i], travelMode: window.google.maps.TravelMode.DRIVING });
                    //     if (status === window.google.maps.DirectionsStatus.OK) {
                    //         directions.push(result)
                    //     }
                    //     else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                    //         await delay(2000);
                    //         i--;
                    //     }
                    //     else {
                    //         errorAlert("No Route Found")
                    //     }
                    // }
                    let dummyAgentDirections = [];
                    if (agent && agent.taskId && (agent.agentCoordinates || (agent.lat && agent.lng))) {
                        let findTaskBookMark = cordinates.find(i => i.id === agent.taskId);
                        // if (findTaskBookMark) {
                        //     directions.push(await agentDirections({ places: [agent, findTaskBookMark], travelMode: window.google.maps.TravelMode.DRIVING }))
                        // }
                        dummyAgentDirections.push(agent, findTaskBookMark)

                    }
                    if (dummyAgentDirections) {
                        setDummyDirections(dummyAgentDirections)
                    }
                    if (directions.length) {
                        setDirections(directions)
                    }
                    else {
                        errorAlert("No Route Found")
                    }
                }
                else {
                    setDirections(null)
                }
            }
        }
        if (mission && mission.missionCategory === "completed") {
            if (mission.tasks && mission.tasks.length) {
                for (let index in mission.tasks) {
                    let t = mission.tasks[index];
                    if (t && t.location) {
                        let data = {
                            id: t.id, ...t.location, address: t.address,
                            agentId: mission.agentId, customerId: t.customerId, position: t.position, orderType: t.orderType, teamId: mission.teamId, taskStatus: t.taskStatus,
                            taskLength: mission.tasks ? mission.tasks.length : 0, optimised: mission.optimised, missionDetails: mission.missionDetails,
                            capacity: t.capacity, capacityUnit: t.capacityUnit, eta: t.eta, etaSeconds: t.etaSeconds, missioned: t.missioned,
                            orderCompletedTimeStamp: t.orderCompletedTimeStamp, updatedAt: t.updatedAt,
                            customer: t.customer
                        }
                        data.distanceUnit = regionalSettingData && regionalSettingData.distanceUnit ? regionalSettingData.distanceUnit : "km"
                        if (t.taskStatus === "unassigned") {
                            data.markerImage = unassignedTaskMarker;
                            data.selectedMarkerImage = selectedUnassignedTaskMarker;
                        }
                        else if (t.taskStatus === "assigned") {
                            data.markerImage = assignedTaskMarker;
                            data.selectedMarkerImage = selectedAssignedMarker;
                        }
                        else if (t.taskStatus === "completed") {
                            data.markerImage = completedTaskMarker;
                            data.selectedMarkerImage = selectedCompletedMarker;
                        }
                        else if (t.taskStatus === "failed") {
                            data.markerImage = failedTaskMarker;
                            data.selectedMarkerImage = selectedFailedTaskMarker;
                        }
                        else if (t.taskStatus === "delayed") {
                            data.markerImage = delayTaskMarker;
                            data.selectedMarkerImage = selectedDelayMarker;
                        }
                        else if (t.taskStatus === "started") {
                            data.markerImage = startedTaskMarker;
                            data.selectedMarkerImage = selectedStartedMarker;
                        }
                        else if (t.taskStatus === "reached") {
                            data.markerImage = startedTaskMarker;
                            data.selectedMarkerImage = selectedStartedMarker;
                        }
                        let findTaskIndex = mission.tasks.findIndex(i => i.id === t.id);
                        if (findTaskIndex >= 0) {
                            if (mission.tasks[findTaskIndex].taskStatus === TASK_STATUSES.COMPLETED || mission.tasks[findTaskIndex].taskStatus === TASK_STATUSES.FAILED) {
                                mission.tasks[findTaskIndex].eta = moment(data.orderCompletedTimeStamp).format("hh:mm a");
                            }
                        }
                        let newBookmarks = [...bookMarks]
                        if (currentMarkers) {
                            newBookmarks = [...currentMarkers]
                        }
                        let findBookmark = newBookmarks.findIndex(i => { return i.id === t.id });

                        let newDatedBookmarks = [...datedBookMarks]
                        if (datedCurrentMarkers) {
                            newDatedBookmarks = [...datedCurrentMarkers]
                        }
                        let findDatedBookmark = newDatedBookmarks.findIndex(i => { return i.id === t.id });
                        if (findBookmark >= 0) {
                            newBookmarks[findBookmark].eta = data.eta
                            newBookmarks[findBookmark].showMarkerLabel = true;
                            newBookmarks[findBookmark].markerIndex = Number(index) + 1;
                            newBookmarks[findBookmark].taskStatus = t.taskStatus;
                            newBookmarks[findBookmark].markerImage = data.markerImage;
                            newBookmarks[findBookmark].selectedMarkerImage = data.selectedMarkerImage;
                            bookMarksToUpdate.push(newBookmarks[findBookmark])
                        }
                        else if (findDatedBookmark >= 0) {
                            findDatedBookmark[findBookmark].eta = data.eta
                            newDatedBookmarks[findBookmark].showMarkerLabel = true;
                            newDatedBookmarks[findBookmark].markerIndex = Number(index) + 1;
                            newDatedBookmarks[findBookmark].taskStatus = t.taskStatus;
                            newDatedBookmarks[findBookmark].markerImage = data.markerImage;
                            newDatedBookmarks[findBookmark].selectedMarkerImage = data.selectedMarkerImage;
                            bookMarksToUpdate.push(newDatedBookmarks[findBookmark])
                        }
                        else {
                            data.showMarkerLabel = true;
                            data.markerIndex = Number(index) + 1;
                            newMarkers.push(data)
                        }
                        cordinates.push(data);
                    }
                }
                if (bookMarksToUpdate.length) {
                    let newBookmarks = [...bookMarks];
                    if (currentMarkers) {
                        newBookmarks = [...currentMarkers]
                    }
                    for (let marker of bookMarksToUpdate) {
                        let findBookmark = newBookmarks.findIndex(i => { return i.id === marker.id });
                        if (findBookmark >= 0) {
                            newBookmarks[findBookmark] = marker
                        }
                    }
                    setBookMarks(newBookmarks)
                    bookMarksRef.current = newBookmarks;
                    let newDatedBookmarks = [...datedBookMarks];
                    if (datedCurrentMarkers) {
                        newDatedBookmarks = [...datedCurrentMarkers]
                    }
                    for (let marker of bookMarksToUpdate) {
                        let findBookmark = newDatedBookmarks.findIndex(i => { return i.id === marker.id });
                        if (findBookmark >= 0) {
                            newDatedBookmarks[findBookmark] = marker
                        }
                    }
                    setDatedBookMarks(newDatedBookmarks)
                }
                if (newMarkers.length) {
                    setBookMarks((prev) => {
                        return [...prev, ...newMarkers]
                    })
                    setDatedBookMarks((prev) => {
                        return [...prev, ...newMarkers]
                    })
                    bookMarksRef.current = [...bookMarksRef.current, ...newMarkers];
                }

                if (mission.optimised) {
                    cordinates.unshift({
                        id: mission.id,
                        ...mission.startFrom.location,
                        address: mission.startFrom.address,
                        agentId: mission.agentId,
                        customerId: "",
                        position: "",
                        orderType: "SL",
                        teamId: mission.teamId,
                        taskLength: mission.tasks ? mission.tasks.length : 0,
                        optimised: mission.optimised,
                        missionDetails: mission.missionDetails,
                        markerImage: startLocationMarker,
                    });
                    cordinates.push({
                        id: mission.id + 1,
                        ...mission.endAt.location,
                        address: mission.endAt.address,
                        agentId: mission.agentId,
                        customerId: "",
                        position: "",
                        orderType: "EL",
                        teamId: mission.teamId,
                        taskLength: mission.tasks ? mission.tasks.length : 0,
                        optimised: mission.optimised,
                        missionDetails: mission.missionDetails,
                        markerImage: endLocationMarker,
                    });
                    setOtherMarkers([
                        {
                            id: mission.id,
                            ...mission.startFrom.location,
                            address: mission.startFrom.address,
                            agentId: mission.agentId,
                            customerId: "",
                            position: "",
                            orderType: "SL",
                            teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0,
                            optimised: mission.optimised,
                            missionDetails: mission.missionDetails,
                            markerImage: startLocationMarker,
                        },
                        {
                            id: mission.id + 1,
                            ...mission.endAt.location,
                            address: mission.endAt.address,
                            agentId: mission.agentId,
                            customerId: "",
                            position: "",
                            orderType: "EL",
                            teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0,
                            optimised: mission.optimised,
                            missionDetails: mission.missionDetails,
                            markerImage: endLocationMarker,
                        },
                    ]);
                } else {
                    if (mission.startLocation) {
                        cordinates.unshift({
                            id: mission.id,
                            ...mission.startLocation,
                            address: mission.address,
                            agentId: mission.agentId,
                            customerId: "",
                            position: "",
                            orderType: "SL",
                            teamId: mission.teamId,
                            taskLength: mission.tasks ? mission.tasks.length : 0,
                            optimised: mission.optimised,
                            missionDetails: mission.missionDetails,
                            markerImage: startLocationMarker,
                        });
                        setOtherMarkers([
                            {
                                id: mission.id,
                                ...mission.startLocation,
                                address: mission.address,
                                agentId: mission.agentId,
                                customerId: "",
                                position: "",
                                orderType: "SL",
                                teamId: mission.teamId,
                                taskLength: mission.tasks ? mission.tasks.length : 0,
                                optimised: mission.optimised,
                                missionDetails: mission.missionDetails,
                                markerImage: startLocationMarker,
                            },
                        ]);
                    }
                }

                for (let coor of cordinates) {
                    let ifExists = allowedTasks.includes(coor.id);
                    if (coor.orderType === "SL" || coor.orderType === "EL" || ifExists) {
                        allowedCoordinates.push(coor);
                    }
                }
                if (allowedCoordinates.length && allowedCoordinates.length > 1) {
                    let directions = allowedCoordinates;
                    // let parts = [], max = 25 - 1;
                    // for (let i = 0; i < allowedCoordinates.length; i = i + max)
                    //     parts.push(allowedCoordinates.slice(i, i + max + 1));

                    // for (let i = 0; i < parts.length; i++) {
                    //     let { result, props, status } = await MapDirectionsRenderer({ places: parts[i], travelMode: window.google.maps.TravelMode.DRIVING });
                    //     if (status === window.google.maps.DirectionsStatus.OK) {
                    //         directions.push(result)
                    //     }
                    //     else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                    //         await delay(2000);
                    //         i--;
                    //     }
                    //     else {
                    //         errorAlert("No Route Found")
                    //     }
                    // }
                    if (directions.length) {
                        // setDummyDirections(directions)
                        setDirections(directions)
                    }
                    else {
                        errorAlert("No Route Found")
                    }
                }
                else {
                    setDirections(null)
                }
            }
        }
        if (missionClicked) {
            let missions = JSON.parse(JSON.stringify(missionsList));
            if (tempMissionList) {
                missions = JSON.parse(JSON.stringify(tempMissionList));
            }
            let missionIndex = missions.findIndex(i => i.id === mission.id);
            if (missionIndex >= 0) {
                missions[missionIndex] = mission
            }
            dispatch({
                type: CUSTOM_SAVE_MISSIONS_REDUX,
                missionList: missions,
                key: "missionsList"
            })
        }
        dispatch({
            type: HIDE_LOADER
        })
    };

    const changeStatusHandler = async ({ selectedTask }) => {
        let currentTask = "";
        let taskSelected =
            selectedTasks && selectedTasks.length ? selectedTasks : selectedTask;
        if (taskSelected && taskSelected.length) {
            if (tasksList && tasksList.length) {
                currentTask = tasksList.find((t) => t.id == taskSelected[0]);
            }
            if (!currentTask) {
                if (missionsList && missionsList.length) {
                    for (let i = 0; i < missionsList.length; i++) {
                        if (missionsList[i].tasks && missionsList[i].tasks.length) {
                            currentTask = missionsList[i].tasks.find((t) => {
                                return t.id === taskSelected[0];
                            });

                            if (currentTask) {
                                break;
                            }
                        }
                    }
                    // missionList.forEach(m=>{
                    //   if(m.tasks && m.tasks)
                    // })
                }
            }
        }
        console.log("currentTask", currentTask);
        if (currentTask) {
            if (currentTask.location) {
                setLocation(currentTask.location);
            }
            if (currentTask.address) {
                setAddress(currentTask.address);
            }
            setFormTaskData({
                id: currentTask.id,
                customerId: currentTask.customerId.id,
                firstName:
                    currentTask.customer && currentTask.customer.firstName
                        ? currentTask.customer.firstName
                        : currentTask.customerId.firstName,
                lastName:
                    currentTask.customer && currentTask.customer.lastName
                        ? currentTask.customer.lastName
                        : currentTask.customerId.lastName,
                phoneNumber: currentTask.customerId.phoneNumber,
                countryCode: currentTask.customerId.countryCode || "",
                email:
                    currentTask.customer && currentTask.customer.email
                        ? currentTask.customer.email
                        : currentTask.customerId.email,
                address: currentTask.address ? currentTask.address : "",
                apartment: currentTask.apartment,
                afterOpen: false,
                beforeOpen: false,
                scheduleTimeOpen: false,
                // taskStatus: currentTask.taskStatus,
                orderDate: currentTask.orderDate,
            });
            setChangeStatus(true);
        }
    };

    const unassignmenuClickHandler = async ({ selectedM }) => {
        let missionSelected = selectedMissionsRef?.current && selectedMissionsRef?.current.length
            ? selectedMissionsRef?.current
            : selectedM;
        if (missionSelected && missionSelected.length) {
            let data = {
                missionId: missionSelected[0],
            };
            dispatch({
                type: UNASSIGN_MISSION_API,
                data: {
                    payload: {
                        ...data,
                    },
                    resCall: (res) => {
                        handleAssignMissionRes(res);
                    },
                },
            });
        }
    };

    const handleAssignMissionRes = (response) => {
        setPopup({ show: false });
        let mission = response && response.mission ? response.mission : {};
        if (mission) {
            getDirections(mission, false, bookMarksRef.current);
        }
    };

    const assignmenuClickHandler = ({ selectedM }) => {
        let missionSelected =
            selectedMissionsRef?.current && selectedMissionsRef?.current.length
                ? selectedMissionsRef?.current
                : selectedM;
        if (missionSelected && missionSelected.length) {
            setPopup({
                show: true,
                type: appDefaults.POPUP_TYPE.ASSIGN,
            });
            // setAssignagent(true)
            setSelectedMissions(missionSelected);
            selectedMissionsRef.current = missionSelected
        }
    };

    const handleHubClick = async () => {
        let findAddress = await findAddressFromLatLng({
            ...contextMenuRef?.current?.data,
        });
        let address = findAddress?.data?.results[0]?.formatted_address;
        let location = findAddress?.data?.results[0]?.geometry?.location;
        setContextMenu({ show: false });
        history.push({
            pathname: "/Settings",
            state: {
                createHub: true,
                address,
                location,
            },
        });
    };
    const recurringMissionPopup = () => {
        return (
            <ModalPopUp
                show={showRecurringMissionPopup && showRecurringMissionPopup.show}
                updateState={setShowRecurringMissionPopup}
                left={"38%"}
                width={"20%"}
                background={"#272735"}
                borderColor={"#39394b"}
            >
                <div className="multi-mission-pop-up">
                    <span>
                        {showRecurringMissionPopup && showRecurringMissionPopup.text}
                    </span>
                </div>
                <div className="multi-mission-form">
                    <Formik
                        initialValues={{
                            selectedOption:
                                showRecurringMissionPopup &&
                                    showRecurringMissionPopup.type === "DELETE"
                                    ? "THIS_MISSION"
                                    : "THIS_TASK",
                        }}
                        onSubmit={async (values) => {
                            if (
                                showRecurringMissionPopup &&
                                showRecurringMissionPopup.type === "DELETE"
                            )
                                deleteMission(values.selectedOption);
                            else if (
                                showRecurringMissionPopup &&
                                showRecurringMissionPopup.type === "EDIT_TASK"
                            ) {
                                editMenu({ option: values.selectedOption });
                            } else if (
                                showRecurringMissionPopup &&
                                showRecurringMissionPopup.type === "DELETE_TASK"
                            ) {
                                deleteMenu(values.selectedOption, selectedTasks);
                            } else if (
                                showRecurringMissionPopup &&
                                showRecurringMissionPopup.type === "REMOVE_TASK"
                            ) {
                                removeMenu(values.selectedOption, selectedTasks);
                            }
                        }}
                    >
                        {({ values }) => (
                            <Form>
                                <div
                                    role="group"
                                    aria-labelledby="my-radio-group"
                                    style={{
                                        color: "#ffffff",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    {showRecurringMissionPopup &&
                                        showRecurringMissionPopup.type === "DELETE" && (
                                            <label style={{ marginBottom: "8px" }}>
                                                <Field
                                                    type="radio"
                                                    name="selectedOption"
                                                    value="THIS_MISSION"
                                                />
                                                This mission only.
                                            </label>
                                        )}
                                    {showRecurringMissionPopup &&
                                        (showRecurringMissionPopup.type === "EDIT_TASK" ||
                                            showRecurringMissionPopup.type === "REMOVE_TASK" ||
                                            showRecurringMissionPopup.type === "DELETE_TASK") && (
                                            <label style={{ marginBottom: "8px" }}>
                                                <Field
                                                    type="radio"
                                                    name="selectedOption"
                                                    value="THIS_TASK"
                                                />
                                                This mission only.
                                            </label>
                                        )}
                                    {showRecurringMissionPopup &&
                                        showRecurringMissionPopup.type === "DELETE" && (
                                            <label style={{ marginBottom: "8px" }}>
                                                <Field
                                                    type="radio"
                                                    name="selectedOption"
                                                    value="ALL_FOLLOWING_MISSIONS"
                                                />
                                                This and following missions.
                                            </label>
                                        )}
                                    {showRecurringMissionPopup &&
                                        (showRecurringMissionPopup.type === "EDIT_TASK" ||
                                            showRecurringMissionPopup.type === "REMOVE_TASK" ||
                                            showRecurringMissionPopup.type === "DELETE_TASK") && (
                                            <label style={{ marginBottom: "8px" }}>
                                                <Field
                                                    type="radio"
                                                    name="selectedOption"
                                                    value="ALL_FOLLOWING_TASKS"
                                                />
                                                This and following missions.
                                            </label>
                                        )}
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                        className="cncle-btn"
                                        onClick={() => {
                                            setShowRecurringMissionPopup(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        style={{ width: "105px" }}
                                        className="ctaskbtn"
                                    >
                                        {"Submit"}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </ModalPopUp>
        );
    };
    const optimiseMenuClickHandler = ({ selectedM }) => {
        let missionSelected =
            selectedMissionsRef?.current && selectedMissionsRef?.current.length
                ? selectedMissionsRef?.current
                : selectedM;
        if ((features && features.routeOptimization) || superAdminRole) {
            if (missionSelected && missionSelected.length) {
                rightSidebarOpenRef.current = true;
                setRightSidebarOpen(true);
                setShowOptimiseSection(true);
                // setMissionList(missionList)
                let selectedMission = missionsList.find(
                    (m) => m.id === missionSelected[0]
                );
                if (selectedMission) {
                    let locations = [];
                    if (selectedMission.tasks && selectedMission.tasks.length) {
                        locations = selectedMission.tasks.map((t) => {
                            return { location: t.location, id: t.id };
                        });
                    }
                    setOptimiseFormData((prev) => {
                        return {
                            ...prev,
                            missionId: selectedMission.id,
                            locations,
                            agentId: selectedMission.agentId
                                ? selectedMission.agentId.id
                                : "",
                            teamId: selectedMission.agentId
                                ? selectedMission.agentId.teamId
                                : "",
                            tasks: selectedMission.tasks,
                            missionDate: selectedMission.missionDate,
                        };
                    });
                }
            }
        } else {
            history.push({
                // pathname: userData && userData.role === 1 ? "/ManageBusiness" : "Business",
                pathname: "Business/routeOptimization",
            });
        }
    };

    const linkClickHandler = async () => {

        let missionCurrent = currentMissionRef?.current ? currentMissionRef?.current : currentMission
        if ((features && features.linkedTask) || superAdminRole) {
            if (selectedTasks.length > 4) {
                return errorAlert("You can only select upto 4 tasks to link");
            }
            let findMissionFromList = missionsList.find(
                (item) => item.id === missionCurrent
            );
            let missionTasks = findMissionFromList.tasks;
            let taskForLink = [];
            for (let key of selectedTasks) {
                let filteredTask = missionTasks.find((i) => i.id === key);
                if (filteredTask) {
                    taskForLink.push({
                        id: filteredTask.id,
                        orderType: filteredTask.orderType,
                    });
                }
            }

            let data = {
                missionId: currentMission,
                linkedTasks: taskForLink,
            };

            // const response = await taskService.setLinkedTasks(data)
            // if (response) {
            //     successAlert("Tasks linked successfully")
            //     // setLinkedTaskList([]);
            //     setSelectedTasks([])
            //     getTaskList();
            //     getMissionList();
            // }
            // else {
            //     return errorAlert(
            //         response.data.message
            //             ? response.data.message
            //             : "Something went Wrong  !"
            //     )
            // }
        } else {
            history.push({
                pathname: "/Business/linkedTask",
            });
        }
    };
    const printManifestHandler = async () => {
        if ((features && features.exportMissionPdf) || superAdminRole) {
            let missionCurrent = currentMissionRef?.current ? currentMissionRef?.current : currentMission
            // setPrintToggle(true)
            let data = {
                missionId: missionCurrent,
            };
            let response = await taskService.createMissionPDF(data);
            if (response.data) {
                fileDownload(response.data, "Mission Manifest.pdf");
                // setPDFData(response.data)
            } else {
                return errorAlert("Something went wrong!!!");
            }
        } else {
            history.push({
                pathname: "/Business/exportMissionPdf",
            });
        }
    };

    const dayOfWeekOfMonth = () => {
        const days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        const today = moment();
        const dayOfWeek = today.day();
        const dateOfMonth = today.date(); // get date of month (1-31)
        if (dateOfMonth < 8) {
            // check if the first Friday of the month has not passed yet
            return { number: 1, string: "first", dayString: days[dayOfWeek] };
        } else if (dateOfMonth < 15) {
            // check if the second Friday of the month has not passed yet
            return { number: 2, string: "second", dayString: days[dayOfWeek] };
        } else if (dateOfMonth < 22) {
            // check if the third Friday of the month has not passed yet
            return { number: 3, string: "third", dayString: days[dayOfWeek] };
        } else if (dateOfMonth < 29) {
            // check if the fourth Friday of the month has not passed yet
            return { number: 4, string: "fourth", dayString: days[dayOfWeek] };
        } else {
            return { number: 5, string: "fifth", dayString: days[dayOfWeek] };
        }
    };

    const updateDuplicateMissionPopup = () => {
        return (
            <ModalPopUp
                top="15%"
                show={updateMissionToggle}
                updateState={setUpdateMissionToggle}
                background={"#272735"}
                borderColor={"#39394b"}
            >
                <div className="update-mission-pop-up">
                    <h6>
                        {missionData && missionData.type === "DUPLICATE"
                            ? "Duplicate Mission"
                            : "Change Mission Date"}
                    </h6>
                    <span className="mission-span">
                        {missionData && missionData.name}
                    </span>
                    <div className="mission-calendar">
                        <Calendar
                            onChange={(e) => {
                                setMissionData((prev) => {
                                    return {
                                        ...prev,
                                        missionDate: e,
                                    };
                                });
                            }}
                            value={missionData && new Date(missionData.missionDate)}
                        ></Calendar>
                    </div>
                    {/* {missionData && missionData.type === "DUPLICATE" && (
                        <div>
                            <div className="dropdown">
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    defaultValue={
                                        missionData.selectedRepeatValue
                                            ? missionData.selectedRepeatValue
                                            : { id: "notRepeat", name: "Does not repeat" }
                                    }
                                    value={
                                        missionData.selectedRepeatValue
                                            ? missionData.selectedRepeatValue
                                            : { id: "notRepeat", name: "Does not repeat" }
                                    }
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={false}
                                    name="color"
                                    getOptionLabel={(option) => `${option.name}`}
                                    getOptionValue={(option) => option.id}
                                    onChange={(newValue, actionMeta) => {
                                        if (newValue.id === "repeat") {
                                            setCustomOccurencesToggle(true);
                                            setMissionData((prev) => {
                                                return {
                                                    ...prev,
                                                    repeat: true,
                                                    selectedRepeatValue: newValue,
                                                };
                                            });
                                        } else {
                                            setMissionData((prev) => {
                                                return {
                                                    ...prev,
                                                    repeat: false,
                                                    selectedRepeatValue: newValue,
                                                };
                                            });
                                        }
                                    }}
                                    options={[
                                        { id: "notRepeat", name: "Does not repeat" },
                                        { id: "repeat", name: "Custom" },
                                    ]}
                                />
                            </div>
                        </div>
                    )} */}
                    {missionData && missionData.repeat && RecurrencePopup()}
                    <div className="mission-buttons">
                        <Button
                            className="cncle-btn"
                            onClick={() => setUpdateMissionToggle(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className="ctaskbtn"
                            onClick={() => {
                                updateMission({ ...missionData }, missionData.type);
                            }}
                        >
                            Lets go
                        </Button>
                    </div>
                </div>
            </ModalPopUp>
        );
    };

    const RecurrencePopup = () => {
        return (
            <ModalPopUp
                show={customOccurencesToggle}
                updateState={setCustomOccurencesToggle}
                top="-5%"
                hideCloseButton={true}
                zIndex="1350"
                width="500px"
                background={"#272735"}
                borderColor={"#39394b"}
            >
                <div className="recurrence-container">
                    <span>Custom recurrence</span>
                    <div className="repeat-container">
                        <span className="repeat-span">Repeat every</span>
                        <input
                            className="input-custom"
                            type="number"
                            min="1"
                            defaultValue={1}
                            onChange={(e) => {
                                setMissionData((prev) => {
                                    return { ...prev, repeatEvery: e.target.value };
                                });
                            }}
                        />
                        <div className="repeat-dropdown">
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                defaultValue={{ id: "DAY", name: "Day" }}
                                value={
                                    missionData && missionData.repeatType
                                        ? missionData.repeatType
                                        : { id: "DAY", name: "Day" }
                                }
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isSearchable={false}
                                name="color"
                                onChange={(newValue, actionMeta) => {
                                    setMissionData((prev) => {
                                        return { ...prev, repeatType: newValue };
                                    });
                                }}
                                getOptionLabel={(option) => `${option.name}`}
                                getOptionValue={(option) => option.id}
                                options={[
                                    { id: "DAY", name: "Day" },
                                    { id: "WEEK", name: "Week" },
                                    { id: "MONTH", name: "Month" },
                                ]}
                            />
                        </div>
                    </div>
                    {missionData &&
                        missionData.repeatType &&
                        (missionData.repeatType.id === "WEEK" ||
                            missionData.repeatType.id === "MONTH") && (
                            <div className="repeat-on">
                                {missionData.repeatType.id === "WEEK" ? (
                                    <>
                                        <span>Repeats On</span>
                                        <div className="week-repeat">
                                            {[
                                                { day: 0, dayText: "S" },
                                                { day: 1, dayText: "M" },
                                                { day: 2, dayText: "T" },
                                                { day: 3, dayText: "W" },
                                                { day: 4, dayText: "T" },
                                                { day: 5, dayText: "F" },
                                                { day: 6, dayText: "S" },
                                            ].map((i) => {
                                                return (
                                                    <div
                                                        className={`weeks${missionData.repeatOnDay &&
                                                            missionData.repeatOnDay.includes(i.day)
                                                            ? " selected"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            let prevSelectedDays = missionData.repeatOnDay;
                                                            if (
                                                                prevSelectedDays &&
                                                                prevSelectedDays.includes(i.day)
                                                            ) {
                                                                let findIndex = prevSelectedDays.findIndex(
                                                                    (d) => d === i.day
                                                                );
                                                                if (findIndex >= 0) {
                                                                    prevSelectedDays.splice(findIndex, 1);
                                                                }
                                                            } else {
                                                                prevSelectedDays.push(i.day);
                                                            }
                                                            setMissionData((prev) => {
                                                                return {
                                                                    ...prev,
                                                                    repeatOnDay: prevSelectedDays,
                                                                };
                                                            });
                                                        }}
                                                    >
                                                        {i.dayText}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                ) : (
                                    <div className="month-repeat">
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            defaultValue={{
                                                id: "DAY",
                                                value: momentTz().tz(timezone.timezone).date(),
                                                name: `Monthly on day ${momentTz()
                                                    .tz(timezone.timezone)
                                                    .date()}`,
                                            }}
                                            value={
                                                missionData && missionData.repeatOnDays
                                                    ? missionData.repeatOnDays
                                                    : {
                                                        id: "DAY",
                                                        value: momentTz().tz(timezone.timezone).date(),
                                                        name: `Monthly on day ${momentTz()
                                                            .tz(timezone.timezone)
                                                            .date()}`,
                                                    }
                                            }
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={false}
                                            name="color"
                                            onChange={(newValue, actionMeta) => {
                                                setMissionData((prev) => {
                                                    return { ...prev, repeatOnDays: newValue };
                                                });
                                            }}
                                            getOptionLabel={(option) => `${option.name}`}
                                            getOptionValue={(option) => option.id}
                                            options={[
                                                {
                                                    id: "DAY",
                                                    value: momentTz().tz(timezone.timezone).date(),
                                                    name: `Monthly on day ${momentTz()
                                                        .tz(timezone.timezone)
                                                        .date()}`,
                                                },
                                                {
                                                    id: "WEEKDAY_MONTH",
                                                    value: dayOfWeekOfMonth().number,
                                                    name: `Monthly on ${dayOfWeekOfMonth().string} ${dayOfWeekOfMonth().dayString
                                                        }`,
                                                },
                                            ]}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    <div className="end-text">
                        <span>Ends</span>
                    </div>
                    <div className="ends-on-container">
                        <div className="menu-list">
                            <FormB.Check
                                defaultChecked
                                type="radio"
                                id="Never"
                                name="view"
                                className="checkbox-custom"
                                onChange={(e) => {
                                    setMissionData((prev) => {
                                        return { ...prev, endsOn: "NEVER" };
                                    });
                                }}
                            />
                            <label htmlFor="Never" className="checkbox-custom-label">
                                Never
                            </label>
                        </div>
                        <div className="menu-list">
                            <FormB.Check
                                type="radio"
                                id="One"
                                name="view"
                                className="checkbox-custom"
                                onChange={(e) => {
                                    setMissionData((prev) => {
                                        return { ...prev, endsOn: "ON" };
                                    });
                                }}
                            />
                            <label htmlFor="On" className="checkbox-custom-label">
                                On
                            </label>
                            <DatePicker
                                dateFormat="MM/dd/yyyy"
                                name={"orderDate"}
                                value={
                                    missionData && missionData.endsOnDate
                                        ? missionData.endsOnDate
                                        : new Date()
                                }
                                disabled={
                                    !missionData || (missionData && missionData.endsOn !== "ON")
                                }
                                onChange={(e) => {
                                    setMissionData((prev) => {
                                        return { ...prev, endsOnDate: e };
                                    });
                                }}
                                selectsEnd
                            />
                        </div>
                        <div className="menu-list">
                            <FormB.Check
                                type="radio"
                                id="After"
                                name="view"
                                className="checkbox-custom"
                                onChange={(e) => {
                                    setMissionData((prev) => {
                                        return { ...prev, endsOn: "AFTER" };
                                    });
                                }}
                            />
                            <label htmlFor="After" className="checkbox-custom-label">
                                After
                            </label>
                            <input
                                className="input-custom"
                                type="number"
                                min="1"
                                defaultValue={1}
                                onChange={(e) => {
                                    setMissionData((prev) => {
                                        return { ...prev, endAfterOccurence: e.target.value };
                                    });
                                }}
                            />
                            <div className="occuerence-text">
                                <span>occurences</span>
                            </div>
                        </div>
                    </div>
                    <div className="recurrence-buttons">
                        <Button
                            className="cncle-btn"
                            onClick={() => setCustomOccurencesToggle(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className="ctaskbtn"
                            onClick={() => {
                                setCustomOccurencesToggle(false);
                            }}
                        >
                            Done
                        </Button>
                    </div>
                </div>
            </ModalPopUp>
        );
    };

    const MultiMissionPopup = () => {
        return (
            <ModalPopUp show={multiMissionToggle} updateState={setMultiMissionToggle} background={"#ffffff"} textColor={"000000"}>
                <div className="optimize-header">
                    <span>
                        Optimize Mission
                    </span>
                </div>
                <div className="multi-mission-pop-up" style={{ color: "#000000", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <span>
                        Total duration and/or number of tasks can't be respected.
                    </span>
                    <span>
                        What should we do?
                    </span>
                </div>
                <div className="multi-mission-form">
                    <Formik
                        initialValues={{
                            multiMissionOption: "proceedOptimization",
                        }}
                        onSubmit={async (values) => {
                            setMultiMissionOption(values.multiMissionOption);
                            setMultiMissionToggle(false);
                            handleOptimiseSubmit(values);
                        }}
                    >
                        {({ values }) => (
                            <Form>
                                <div
                                    role="group"
                                    aria-labelledby="my-radio-group"
                                    style={{ color: "#000000", display: "flex", flexDirection: "column", borderBottom: "1px solid grey", marginBottom: "10px" }}
                                >
                                    <label style={{ marginBottom: "8px" }}>
                                        <Field
                                            type="radio"
                                            name="multiMissionOption"
                                            value="proceedOptimization"
                                        />
                                        Continue anyway.
                                    </label>
                                    <label style={{ marginBottom: "8px" }}>
                                        <Field
                                            type="radio"
                                            name="multiMissionOption"
                                            value="proceedMultiMission"
                                        />
                                        Split into multiple missions.
                                    </label>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", gap: "30px", marginBottom: "10px" }}>
                                    <Button type="submit" onClick={(e) => {
                                        e.preventDefault();
                                        setMultiMissionToggle(false);
                                    }}>Cancel</Button>
                                    <Button type="submit">Go!</Button>
                                </div>
                                <div className={"adjust_settings"}>
                                    <span onClick={() => {
                                        history.push({
                                            pathname: "/Settings"
                                        });
                                    }}>
                                        Adjust my settings
                                    </span>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </ModalPopUp>
        );
    };

    function dragStart(e, task) {
        e.dataTransfer.setData("drag-item", task.id);
    }

    const dragOver = async (ev) => {
        ev.preventDefault();
        // ev.dataTransfer.dropEffect = dropEffect;
    };

    const onDropMission = async (ev, data) => {
        let allTasks = JSON.parse(JSON.stringify(data.tasks));
        let missionTaskIds = data.tasks.map((item) => item.id);
        ev.preventDefault();
        let draggedTask = ev.dataTransfer.getData("drag-item");
        let apiData = {};
        let maxMissionDuration = 0;
        let maxMissionCapacity = 0;
        let startFrom,
            endAt,
            missionDetails = {
                totalTime: 0,
                totalDist: 0,
            },
            tasks = {
                locations: [],
            };
        let taskIds = [];

        if (selectedTasks.length && selectedTasks.length > 1) {
            let selectedTasksToAdd = [];
            for (let task in selectedTasks) {
                let findTasks = await tasksList.find(
                    (t) => t.id === selectedTasks[task]
                );
                if (findTasks && !findTasks.missionId) {
                    selectedTasksToAdd.push(task)
                    allTasks.push(findTasks);
                }
            }
            if (selectedTasksToAdd.length > 1) {
                taskIds = [...missionTaskIds, ...selectedTasksToAdd];
                apiData = {
                    missionId: data.id,
                    taskIds: taskIds,
                    taskStatus: data.missionStatus,
                    newTasks: selectedTasksToAdd,
                };
            }
            else if (draggedTask) {
                let findTasks = await tasksList.find((t) => t.id === draggedTask);
                if (findTasks) {
                    allTasks.push(findTasks);
                }
                taskIds = [...missionTaskIds, draggedTask];
                apiData = {
                    missionId: data.id,
                    taskIds: taskIds,
                    taskStatus: data.missionStatus,
                    newTasks: [draggedTask],
                };
            }
        } else if (draggedTask) {
            let findTasks = await tasksList.find((t) => t.id === draggedTask);
            if (findTasks) {
                allTasks.push(findTasks);
            }
            taskIds = [...missionTaskIds, draggedTask];
            apiData = {
                missionId: data.id,
                taskIds: taskIds,
                taskStatus: data.missionStatus,
                newTasks: [draggedTask],
            };
        }
        if (apiData) {
            if (allTasks.length) {
                if (data.startFrom) {
                    startFrom = data.startFrom;
                }
                if (data.endAt) {
                    endAt = data.endAt;
                }
                for (let task in allTasks) {
                    maxMissionCapacity += allTasks[task].capacity
                        ? Number(allTasks[task].capacity)
                        : 0;
                    if (allTasks[task].durationTime)
                        maxMissionDuration += Number(allTasks[task].durationTime);
                    if (Number(task) === 0 && !data.startFrom) {
                        startFrom = {
                            location: allTasks[task].location,
                        };
                    } else if (Number(task) === allTasks.length - 1 && !data.endAt) {
                        endAt = {
                            location: allTasks[task].location,
                        };
                    } else {
                        tasks.locations.push({
                            location: allTasks[task].location,
                            id: allTasks[task].id,
                        });
                    }
                }

                let tasksDistTime = [];
                if (startFrom && endAt) {
                    let result = await optimize.directionsServiceHere(
                        tasks,
                        false,
                        [],
                        startFrom,
                        endAt
                    );
                    if (result) {
                        let myroute = result[0];
                        missionDetails.totalDist += Number(myroute.distance);
                        missionDetails.totalTime += Number(myroute.time);

                        if (data.startFrom && data.endAt) {
                            for (let i = 0; i < myroute.interconnections.length - 1; i++) {
                                tasksDistTime.push({
                                    id: taskIds[i],
                                    distanceFromPrevTask:
                                        myroute.interconnections[i].distance / 1000,
                                    timeFromPrevTask:
                                        Number(myroute.interconnections[i].time) / 60,
                                });
                            }
                        } else {
                            for (let i = 0; i < myroute.interconnections.length; i++) {
                                tasksDistTime.push({
                                    id: taskIds[i + 1],
                                    distanceFromPrevTask:
                                        myroute.interconnections[i].distance / 1000,
                                    timeFromPrevTask:
                                        Number(myroute.interconnections[i].time) / 60,
                                });
                            }
                        }

                        missionDetails.totalTime += maxMissionDuration * 60;
                        if (missionDetails.totalTime) {
                            missionDetails.totalTime = missionDetails.totalTime / 3600;
                            missionDetails.totalTime = missionDetails.totalTime.toFixed(1);
                        }
                        if (missionDetails.totalDist) {
                            missionDetails.totalDist = missionDetails.totalDist / 1000;
                            missionDetails.totalDist = missionDetails.totalDist.toFixed(1);
                        }
                    }
                    apiData.tasks = tasksDistTime;
                }
            }
            apiData.missionDetails = missionDetails;
            apiData.missionTaskCapacity = maxMissionCapacity;
            dispatch({
                type: ADD_TASKS_TO_MISSION_API,
                data: {
                    payload: {
                        ...apiData,
                    },
                    resCall: (res) => {
                        handleRes(res);
                    },
                },
            });
        }
    };

    const handleRes = (res) => {
        setSelectedTasks([])
        getDirections(res.mission);
    };

    async function importPopup() {
        Flatfile.requestDataFromUser({
            // theme: {
            //     hideConfetti: true,
            //     loadingText: "Custom loading text ...",
            //     submitCompleteText: "Custom submit text ...",
            //     displayName: "Display Name",
            // },
            // Development mode = embedId, user, org.
            token: async () => {
                // customize this to match your response payload
                const res = await authService.flatFileAuth();
                return res.data;
            },
            // handle submitted data
            async onData(chunk, next) {
                // do something with the data
                const records = chunk.records;
                let importData = records.map((i) => {
                    i.data.recordId = i.recordId;
                    return i.data;
                });
                let { errors } = await checkForErrorRows([...importData]);
                if (!errors || (errors && !errors.length)) {
                    next();
                } else {
                    let allErrors = [];
                    for (let err of errors) {
                        allErrors.push(
                            // A RecordError should be created with an record (or record id)
                            // and a list of validation errors.
                            new RecordError(err.recordId, err.error)
                        );
                    }
                    console.log("allErrors", allErrors);
                    next(new PartialRejection(allErrors));
                }
            },
            // onInit({ session }) {
            // },
            async onComplete(data) {
                let validData = await data.data();
                await importData(validData);
            },
            onError(error) {
                return errorAlert(error);
            },
        });
    }

    let checkForErrorRows = async (importedTasks) => {
        let invalidTasks = [],
            validTasks = [];
        for (let task of importedTasks) {
            let validMerchant = false,
                validAddress = false;
            if (
                !task["address"] /*|| !task["merchantEmail"]*/ ||
                !task["orderType"] /*|| !task["deliveryDate"]*/
            ) {
                if (!task["address"]) {
                    invalidTasks.push({
                        recordId: task.recordId,
                        error: [{ field: "address", message: "Address is missing" }],
                    });
                }
                // if (!task["merchantEmail"]) {
                //     invalidTasks.push({
                //         recordId: task.recordId,
                //         error: [{ field: "merchantEmail", message: "Merchant email is missing" }]
                //     })
                // }
                if (!task["orderType"]) {
                    invalidTasks.push({
                        recordId: task.recordId,
                        error: [{ field: "orderType", message: "Order Type is missing" }],
                    });
                }
            }
            if (task["merchantEmail"] && task["merchantEmail"] !== userData.email) {
                let findMerchant = userMerchantList.find((i) => {
                    return i.email === task["merchantEmail"];
                });
                if (userData && userData.role === 1) {
                    findMerchant = userMerchantList.find((i) => {
                        return i.email === task["merchantEmail"];
                    });
                }
                if (!findMerchant) {
                    invalidTasks.push({
                        recordId: task.recordId,
                        error: [
                            { field: "merchantEmail", message: "Invalid merchant email" },
                        ],
                    });
                    validMerchant = false;
                } else {
                    validMerchant = true;
                }
            }
            if (task["address"]) {
                task["address"] = task["address"].replace(
                    /[\-\[\]\/\{\}\(\)\*\/\#\+\?\.\\\^\$\|]/g,
                    ""
                );
                let response = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${task["address"]}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                );
                if (
                    !response ||
                    (response && !response.data) ||
                    (response && response.data && !response.data.results[0])
                ) {
                    invalidTasks.push({
                        recordId: task.recordId,
                        error: [{ field: "address", message: "Invalid address" }],
                    });
                    validAddress = false;
                } else {
                    validAddress = true;
                }
            }
            // if (validAddress && validMerchant && task["address"] && task["merchantEmail"] && task["orderType"]) {
            //     validTasks.push(task)
            // }
        }
        return { errors: invalidTasks, validTasks };
    };

    const escapeRegex = (str) => {
        return str
            .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "")
            .replace(/ /g, "");
    };

    const importData = async (validData) => {
        // setLoading(true)
        setCreateTask(false);
        let tasks = [],
            invalidTasks = [];
        let taskData = validData.rows.map((i) => {
            return i.data;
        });

        for (let task of taskData) {
            let findMerchant;
            if (task["merchantEmail"] === userData.email) {
                findMerchant = userData;
            } else if (!task["merchantEmail"]) {
                findMerchant = userData;
            } else {
                findMerchant = userMerchantList.find((i) => {
                    return i.email === task["merchantEmail"];
                });
                if (userData && userData.role === 1) {
                    findMerchant = userMerchantList.find((i) => {
                        return i.email === task["merchantEmail"];
                    });
                }
            }
            if (findMerchant || !task["merchantEmail"]) {
                let defaultTaskDuration = defaultDurationTime;
                let orderType = "D";
                if (
                    taskSettingData &&
                    (taskSettingData.defaultTaskDuration ||
                        taskSettingData.defaultTaskDuration === 0)
                ) {
                    defaultTaskDuration = taskSettingData.defaultTaskDuration;
                }
                if (task["orderType"]) {
                    if (task["orderType"] === "Drop") {
                        orderType = "D";
                        if (
                            taskSettingData &&
                            (taskSettingData.defaultTaskDuration ||
                                taskSettingData.defaultTaskDuration === 0)
                        ) {
                            defaultTaskDuration = taskSettingData.defaultTaskDuration;
                        }
                    } else if (
                        task["orderType"] === "Drop and Install" ||
                        task["orderType"] === "Drop & Install"
                    ) {
                        orderType = "DI";
                        if (
                            taskSettingData &&
                            (taskSettingData.defaultInstallationTime ||
                                taskSettingData.defaultInstallationTime === 0)
                        ) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime;
                        }
                    } else if (
                        task["orderType"] === "Drop and Service" ||
                        task["orderType"] === "Drop & Service"
                    ) {
                        orderType = "DI";
                        if (
                            taskSettingData &&
                            (taskSettingData.defaultInstallationTime ||
                                taskSettingData.defaultInstallationTime === 0)
                        ) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime;
                        }
                    } else if (task["orderType"] === "Return") {
                        orderType = "R";
                        if (
                            taskSettingData &&
                            (taskSettingData.defaultInstallationTime ||
                                taskSettingData.defaultInstallationTime === 0)
                        ) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime;
                        }
                    } else if (task["orderType"] === "Service") {
                        orderType = "S";
                        if (
                            taskSettingData &&
                            (taskSettingData.defaultInstallationTime ||
                                taskSettingData.defaultInstallationTime === 0)
                        ) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime;
                        }
                    } else if (
                        task["orderType"] === "Pick Up" ||
                        task["orderType"] === "Pick up"
                    ) {
                        orderType = "P";
                        if (
                            taskSettingData &&
                            (taskSettingData.defaultTaskDuration ||
                                taskSettingData.defaultTaskDuration === 0)
                        ) {
                            defaultTaskDuration = taskSettingData.defaultTaskDuration;
                        }
                    } else if (task["orderType"] === "Exchange") {
                        orderType = "E";
                        if (
                            taskSettingData &&
                            (taskSettingData.defaultInstallationTime ||
                                taskSettingData.defaultInstallationTime === 0)
                        ) {
                            defaultTaskDuration = taskSettingData.defaultInstallationTime;
                        }
                    }
                }
                let location;
                if (task["longitude"] && task["latitude"]) {
                    location = {
                        lat: Number(task["latitude"]),
                        lng: Number(task["longitude"]),
                    };
                } else {
                    task["address"] = task["address"].replace(
                        /[\-\[\]\/\{\}\(\)\*\/\#\+\?\.\\\^\$\|]/g,
                        ""
                    );
                    let response = await axios.get(
                        `https://maps.googleapis.com/maps/api/geocode/json?address=${task["address"]}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                    );
                    // console.log(`https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${task["address"]}&inputtype=textquery&fields=formatted_address,name,geometry&key=${process.env.REACT_APP_GOOGLE_API_KEY}`)
                    // let response = await axios.get(`https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${task["address"]}&inputtype=textquery&fields=formatted_address,name,geometry&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);

                    if (response && response.data && response.data.results[0]) {
                        location = {
                            lat: response.data.results[0].geometry.location.lat,
                            lng: response.data.results[0].geometry.location.lng,
                        };
                    }
                }
                let timezone = { ...timeZoneHeader() };
                if (location) {
                    let data = {
                        orderType: orderType,
                        merchantId: findMerchant ? findMerchant.id : userData.id,
                        notes: task["notes"],
                        orderDate: task["orderDate"]
                            ? `${momentTz(task["orderDate"]).tz(timezone.timezone).startOf("d")
                                ._d
                            }`
                            : null,
                        after: task["after"],
                        afterTimePicker: null,
                        before: task["before"],
                        beforeTimePicker: null,
                        scheduledTimePicker: null,
                        scheduledTime: task["scheduledTime"],
                        afterSeconds: 0,
                        beforeSeconds: 0,
                        scheduledTimeSeconds: 0,
                        durationTime: task["durationTime"],
                        firstName: task["firstName"],
                        lastName: task["lastName"],
                        ISO: "ca",
                        countryCode: task["countryCode"] ? task["countryCode"] : "+1",
                        phoneNumber:
                            task["phoneNumber"] &&
                            escapeRegex(task["phoneNumber"].toString()),
                        email: task["email"],
                        address: task["address"],
                        apartment: task["apartment"],
                        location: location,
                        capacity: task["capacity"],
                        price: Number(task["price"]) || 0,
                    };
                    if (findMerchant.role === 4) {
                        data.userId = findMerchant.userId;
                    } else if (findMerchant.role === 2 && findMerchant.userId) {
                        data.userId = findMerchant.userId;
                    } else {
                        data.userId = findMerchant.id;
                    }
                    if (data.scheduledTime) {
                        let time = data.scheduledTime.split(":");
                        data.scheduledTimeSeconds = Number(time[0] * 3600 + time[1] * 60);
                    }
                    if (data.before) {
                        let time = data.before.split(":");
                        data.beforeSeconds = Number(time[0] * 3600 + time[1] * 60);
                    }
                    if (data.after) {
                        let time = data.after.split(":");
                        data.afterSeconds = Number(time[0] * 3600 + time[1] * 60);
                    }
                    if (!data.durationTime) {
                        data.durationTime = defaultTaskDuration;
                    }
                    if (!data.capacity) {
                        data.capacity =
                            taskSettingData &&
                                (taskSettingData.defaultTaskCapacity ||
                                    taskSettingData.defaultTaskCapacity === 0)
                                ? taskSettingData.defaultTaskCapacity
                                : defaultMissionCapacity;
                        data.capacityUnit =
                            regionalSettingData && regionalSettingData.volumeUnit
                                ? regionalSettingData.volumeUnit
                                : "m3";
                    }
                    if (teamList && teamList.length) {
                        data.teamIds = teamList.map((i) => i.id);
                    }
                    if (userTeams && userTeams.length) {
                        data.userTeamIds = userTeams.map((i) => i.id);
                    }
                    tasks.push(data);
                }
            }
        }
        if (!tasks.length) {
            // setLoading(false)
            return errorAlert(
                "There are some rows with invalid values please check all values!!!"
            );
        }
        let data = {
            payload: {
                tasks,
            },
            sortBy: taskSortBy,
            currentDate: currentDate,
            // currentPage: currentPage,
            pageLimit: taskPageLimit,
            pageCount: taskPageCount,
        };
        const currentUser = userData;
        if (currentUser) data.userId = currentUser.id;

        dispatch({
            type: CREATE_MULTIPLE_TASKS_API,
            data: {
                ...data,
                resCall: (response) => {
                    let successMsg = "Task imported successfully";
                    if (response && response.success) {
                        if (!invalidTasks.length) {
                            successMsg = "Task imported successfully";
                        } else {
                            errorAlert(
                                "Some tasks were not imported due to invalid values!!!"
                            );
                            return;
                        }
                    } else {
                        errorAlert("Some tasks were not imported due to invalid values!!!");
                        return;
                    }
                    getTaskList(true);
                    return successAlert(successMsg);
                },
            },
        });
    };

    const getTaskCount = async () => {
        let response = await taskService.getTaskCountDaily({
            userId: userData.id,
            orderDate: selectedDateRange(
                moment().startOf("M"),
                moment().add(1, "month").startOf("M")
            ),
        });
        if (response && response.data) {
            if (response.data.result && response.data.result.length) {
                setTaskCounts(response.data.result);
            }
        }
    };

    const scheduleDeliveryHandler = () => {
        setShowScheduleDelivery(true);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (searchText !== "") mainSearch();
            // Send Axios request here
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    const mainSearch = async () => {
        let payload = {
            searchText,
            date: momentTz(currentDate).tz(timezone.timezone).startOf("d")._d,
            startDate: +momentTz(currentDate).tz(timezone.timezone).startOf("M")._d,
            endDate: +momentTz(currentDate).tz(timezone.timezone).endOf("M")._d,
        };
        const currentUser = userData;
        if (currentUser) payload.userId = currentUser.id;
        dispatch({
            type: MAIN_SEARCH_API,
            data: {
                payload: payload,
                resCall: (res) => {
                    rightSidebarOpenRef.current = true;
                    setRightSidebarOpen(true);
                    setShowSearchComponent(true);
                    setSearchData(res.result || {});
                },
            },
        });
    };

    const topbarProps = {
        setButtonText, setDirections,
        searchText, setSearchText,
        setShowSearchComponent, setSearchData,
        date, setDate, viewType, currentDate, dateRef,
        setCurrentDate, handleArrowClickS,
        createTask, setCreateTask,
        setRightSidebarOpen, rightSidebarOpen,
        openSidebar, closeSidebar
    }
    const leftSidebarProps = {
        leftSidebarOpen, setLeftSidebarOpen, createTask, setCreateTask,
        setRightSidebarOpen, rightSidebarOpen,
        openSidebar, closeSidebar, setButtonText
    }
    const bottomMenuProps = {
        setViewType, viewTypeRef,
        viewType, setSelectedTasks,
        mapControls,
        setMapControls,
        selectedBottomMenuTeam, setSelectedBottomMenuTeam,
        selectedBottomMenuAgent, setSelectedBottomMenuAgent,
        selectedBottomMenuMerchant, setSelectedBottomMenuMerchant,
        selectedBottomMenuUser, setSelectedBottomMenuUser,
        selectedBottomMenuStatus, setSelectedBottomMenuStatus,
        selectedBottomMenuTeams, setSelectedBottomMenuTeams,
        selectedBottomMenuDay, setSelectedBottomMenuDay
    }
    const plannerSuperviseProps = {
        showSearchComponent, setShowSearchComponent, searchData, searchText,
        datedBookMarks, setDatedBookMarks, containerRef, rightSidebarOpenRef,
        bottomMenuProps, onDropMission, setSearchText, _onListChange,
        topbarProps, address, setAddress, location, setLocation, buttonText, setButtonText,
        viewType, setViewType, showScheduleDelivery, setShowScheduleDelivery, viewTypeRef,
        size, setSize, map, setMap, mapSize, defaultCenter, setDefaultCenter,
        windowSize, initialValuesTask, hoverHub, setHoverHub,
        date, setDate, getDates, dragStart, dragOver, dateRef,
        rightSidebarOpen, setRightSidebarOpen, setUserTeams,
        handleArrowClickS, isCreateTaskForm, setIsCreateTaskForm,
        createTask, setCreateTask, importPopup,
        drawPolygon: mapControls.drawPolygon, getTaskList,
        openSidebar, closeSidebar, selectedDateRange,
        allDates, setAllDates, allDatesRef, userMerchantListRef,
        plannerLabels, setPlannerLabels, taskCounts, setTaskCounts,
        contextMenuRef, contextMenu, setContextMenu,
        handleTaskClick, handleHubClick, scheduleDeliverySettingData,
        formTaskData, setFormTaskData, features, updateMission,
        currentDate, setCurrentDate, setShowOptimiseSection, showOptimiseSection,
        taskSettingData, regionalSettingData, optimiseFormData,
        taskSortBy, missionSortBy, toggleState, selectedMission,
        directions, agentDirections: dummyDirections, setDirections, setToggleState, setSelectedMission,
        selectedMissions, setSelectedMissions, selectedTasks, setSelectedTasks, selectedMissionsRef,
        selectedCenter, setSelectedCenter, taskDetail, setTaskDetail, changeTaskStatus, setChangeStatus,
        lastSelectedTaskIndex, setSelectedTaskIndex, bookMarks, setBookMarks, setMultiMissionToggle,
        editMenuHandler, removeTaskHandler, deleteMenuHandler, scheduleDeliveryHandler,
        duplicateTaskHandler, createMissionApiHandler, distance, initialValuesOptimize,
        otherMarkers, setOtherMarkers, agentBookMarks, setAgentBookMarks, setOptimiseFormData,
        getDirections, currentMission, setcurrentMission, linkClickHandler, handleOptimiseSubmit, currentMissionRef,
        lastSelectedPostion, setSelectedTaskPosition, optimiseMenuClickHandler, printManifestHandler,
        popup, setPopup, assignmenuClickHandler, updateMissionDateHandler, duplicateMissionHandler,
        assignAgent, setAssignagent, unassignmenuClickHandler, changeStatusHandler, deleteMissionClickHandler,
        setEditTask, editTask
    }

    const contextMenuProps = {
        contextMenuDivRef,
        contextMenuRef: contextMenuRef.current,
        contextMenu: contextMenu,
        selectedTasks,
        selectedMissions,
        selectedMissionsRef
    };

    const handleSubmitAssign = async (formValue, selec) => {
        try {
            if (formValue.hubId) delete formValue.hubId;
            if (selectedMissionsRef?.current[0]) {
                dispatch({
                    type: ASSIGN_MISSION_API,
                    data: {
                        payload: {
                            ...formValue,
                            // startLocation: location,
                            // address,
                            missionId: selectedMissionsRef?.current[0],
                        },
                        resCall: (res) => {
                            handleAssignMissionRes(res);
                        },
                    },
                });
            }
        } catch (err) {
            console.log("error:::", err);
        }
    };
    return (
        <>
            <ContentLayout {...leftSidebarProps}>
                <div className="main-control-container" ref={ref}>
                    {/* <TopBar {...topbarProps} /> */}
                    {viewType && viewType === appDefaults.VIEWS.SUPERVISE_CONTROL && (
                        <Supervise {...plannerSuperviseProps} />
                    )}
                    {viewType && viewType === appDefaults.VIEWS.PLANNER_CONTROL && (
                        <PlanAndSchedule {...plannerSuperviseProps} />
                    )}
                    {viewType && viewType === appDefaults.VIEWS.LIST_VIEW && (
                        <ListView
                            {...plannerSuperviseProps}
                            deleteTaskFunction={deleteTaskFunction}
                        />
                    )}
                    {contextMenu && contextMenu.show && (
                        <MapContextMenu {...contextMenuProps} />
                    )}
                    {popup && popup.show && (
                        <ModalPopUp
                            show={popup.show}
                            updateState={setPopup}
                            background={"#272735"}
                            borderColor={"#39394b"}
                        >
                            <AssignPopup
                                handleSubmitAssign={handleSubmitAssign}
                                initialAgentValues={initialAgentValues}
                                teamList={teamListRef.current}
                                agentList={agentListRef.current}
                                selectedMissions={selectedMissions}
                                selectedMissionsRef={selectedMissionsRef}
                                setPopup={setPopup}
                            />
                        </ModalPopUp>
                    )}
                    {recurringMissionPopup()}
                    {MultiMissionPopup()}
                    {updateDuplicateMissionPopup()}
                </div>
                <ToastContainer
                    style={{ color: "black" }}
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    // closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                {loading ? <Loader /> : null}
            </ContentLayout>
        </>
    );
};

export default MainContainer;
