export const GET_TASKS_API = "GET_TASKS_API";
export const CREATE_TASK_API = "CREATE_TASK_API";
export const CREATE_MISSION_API = "CREATE_MISSION_API";
export const GET_MISSIONS_API = "GET_MISSIONS_API";
export const UPDATE_MULTIPLE_MISSIONS_API = "UPDATE_MULTIPLE_MISSIONS_API";
export const CREATE_MULTIPLE_TASKS_API = "CREATE_MULTIPLE_TASKS_API";
export const UPDATE_MULTIPLE_TASKS_API = "UPDATE_MULTIPLE_TASKS_API";
export const ADD_TASKS_TO_MISSION_API = "ADD_TASKS_TO_MISSION_API";
export const ASSIGN_MISSION_API = "ASSIGN_MISSION_API";
export const UNASSIGN_MISSION_API = "UNASSIGN_MISSION_API";
export const OPTIMIZE_MISSION_API = "OPTIMIZE_MISSION_API";
export const DELETE_TASKS_API = "DELETE_TASKS_API";
export const REMOVE_TASKS_API = "REMOVE_TASKS_API";
export const DELETE_MISSION_API = "DELETE_MISSION_API";
export const CHANGE_TASK_STATUS_API = "CHANGE_TASK_STATUS_API";
export const MAIN_SEARCH_API = "MAIN_SEARCH_API";
export const TASK_COUNT_API = "TASK_COUNT_API";
export const CREATE_MISSION_PDF = "CREATE_MISSION_PDF";
export const SET_LINKED_TASKS_API = "SET_LINKED_TASKS_API";
export const SUBMIT_LEGAL_DOCS_API = "SUBMIT_LEGAL_DOCS_API";
export const SAVE_TASK_POSITION_API = "SAVE_TASK_POSITION_API";
export const GET_TASK_HISTORY_API = "GET_TASK_HISTORY_API";
export const SAVE_TASKS_REDUX = "SAVE_TASKS_REDUX";
export const CUSTOM_SAVE_TASKS_REDUX = "CUSTOM_SAVE_TASKS_REDUX";
export const CUSTOM_SAVE_MISSIONS_REDUX = "CUSTOM_SAVE_MISSIONS_REDUX";
export const SAVE_MISSIONS_REDUX = "SAVE_MISSIONS_REDUX";
export const SAVE_DELETE_TASKS_REDUX = "SAVE_DELETE_TASKS_REDUX";
export const SAVE_REMOVE_TASKS_REDUX = "SAVE_REMOVE_TASKS_REDUX";
export const SAVE_DELETE_MISSION_REDUX = "SAVE_DELETE_MISSION_REDUX";
export const SAVE_MULTI_MISSION_REDUX = "SAVE_MULTI_MISSION_REDUX";
export const SAVE_TASK_HISTORY_REDUX = "SAVE_TASK_HISTORY_REDUX";
export const UPDATE_MISSION_DIRECTIONS_API = "UPDATE_MISSION_DIRECTIONS_API";
export const UPDATE_MISSION_API = "UPDATE_MISSION_API";
export const DUPLICATE_MISSION_API = "DUPLICATE_MISSION_API";
export const SCHEDULE_DELIVERY_API = "SCHEDULE_DELIVERY_API";
export const CUSTOM_SAVE_ALL_TASKS_REDUX = "CUSTOM_SAVE_ALL_TASKS_REDUX";
export const GET_TASK_ANALYTICS = "GET_TASK_ANALYTICS";