import moment from "moment";
import momentTz from "moment-timezone";
import { CUSTOM_SAVE_ALL_TASKS_REDUX, CUSTOM_SAVE_MISSIONS_REDUX, CUSTOM_SAVE_TASKS_REDUX } from "../../../redux/task/actionTypes";
import { TASK_STATUSES } from "../../../helpers/configDefaults";

export const taskSingleClickHandler = ({ e, task, i, setToggleState, setDirections, setTaskDetail, selectedMissionsRef,
    setSelectedMissions, setSelectedCenter, setDefaultCenter, selectedTasks, setSelectedTasks, setSelectedTaskIndex,
    bookMarks, setBookMarks, lastSelectedTaskIndex, unassignedTaskList, dispatch, taskToUpdate, dateString, datedBookMarks, setDatedBookMarks, rightSidebarOpenRef, setRightSidebarOpen
}) => {
    //unselecting mission
    setSelectedMissions([]);
    selectedMissionsRef.current = [];
    setToggleState({})
    setDirections(null)
    let selectedCenter = {
        ...task.location, address: task.address, from: "task", id: task.id
    };
    if (task.lat) {
        selectedCenter.lat = task.lat
    }
    if (task.lng) {
        selectedCenter.lng = task.lng
    }
    if (e && (!e.ctrlKey && !e.metaKey)) { setSelectedCenter(selectedCenter) };
    let defaultcenter = {
        id: task.id,
        ...task.location,
        address: task.address,
    }
    if (task.lat) {
        defaultcenter.lat = task.lat
    }
    if (task.lng) {
        defaultcenter.lng = task.lng
    }
    if (e && (!e.ctrlKey && !e.metaKey)) {
        setDefaultCenter(defaultcenter)
        // map.panTo({lat: defaultcenter.lat, lng: defaultCenter.lng})

    }
    //  console.log("task:::",task)
    if (e && (e.ctrlKey || e.metaKey)) {
        if (!selectedTasks.includes(task.id)) {
            setSelectedTasks((prev) => {
                //  console.log("selected items:::",[...selectedTasks,task.id])
                return [...selectedTasks, task.id];
            });
            if (selectedTasks.length) {
                taskSelectHandler(task, false, unassignedTaskList, dispatch, taskToUpdate);
            } else {
                taskSelectHandler(task, true, unassignedTaskList, dispatch, taskToUpdate);
            }
        } else {
            //unselect the current task
            const tasks = unassignedTaskList.map((t) => {
                if (task.id === t.id) return { ...t, active: false };
                return t;
            });
            dispatch({
                type: CUSTOM_SAVE_TASKS_REDUX,
                unAssignedTasksList: tasks,
                key: taskToUpdate
            })
            //remove for selected array list
            const filteredArray = selectedTasks.filter((st) => st !== task.id);
            setSelectedTasks(filteredArray);
        }
    }
    else if (e && e.shiftKey) {
        let tasks = JSON.parse(JSON.stringify(unassignedTaskList))
        let newTaskList = tasks
        let otherTaskList = []
        if (dateString) {
            newTaskList = tasks.filter(t => momentTz(momentTz(t.orderDate)._a).format("YYYY-MM-DD") === dateString)
            otherTaskList = tasks.filter(t => momentTz(momentTz(t.orderDate)._a).format("YYYY-MM-DD") !== dateString)
        }
        if (!selectedTasks.includes(task.id)) {
            let select = []
            if (selectedTasks.length) {
                let newTasks = selectMultiTaskShiftKey(i, newTaskList, select, lastSelectedTaskIndex, selectedTasks, setSelectedTasks, dispatch, taskToUpdate, otherTaskList)
                removeTaskAfterIndex(i, newTaskList.length, newTasks, setSelectedTasks, dispatch, taskToUpdate, otherTaskList)
            }
        }
        else {
            removeTaskAfterIndex(i, newTaskList.length, newTaskList, setSelectedTasks, dispatch, taskToUpdate, otherTaskList)
        }
    }
    else if (e.detail === 2) {
        setTaskDetail(task);
        let selectedCenter = {
            ...task.location, address: task.address, from: "task", id: task.id
        };
        if (task.lat) {
            selectedCenter.lat = task.lat
        }
        if (task.lng) {
            selectedCenter.lng = task.lng
        }
        rightSidebarOpenRef.current = true;
        setRightSidebarOpen(true)
        setSelectedCenter(selectedCenter)
    }
    else {
        if (e.button === 2) {
            if (!selectedTasks.length || selectedTasks.length === 1) {
                setSelectedTasks([task.id]);
                taskSelectHandler(task, true, unassignedTaskList, dispatch, taskToUpdate);
            }
        }
        else {
            setSelectedTasks([task.id]);
            taskSelectHandler(task, true, unassignedTaskList, dispatch, taskToUpdate);
        }
    }

    if (i || i === 0) { setSelectedTaskIndex(i) }
    let newBookmarks = JSON.parse(JSON.stringify(bookMarks));
    let findBookmarkIndex = newBookmarks.findIndex(item => item.id === task.id);
    if (findBookmarkIndex > -1) {
        newBookmarks[findBookmarkIndex].selected = true;
    }
    if (findBookmarkIndex > -1 && e && (e.ctrlKey || e.metaKey)) {
        if (bookMarks[findBookmarkIndex].selected === true) {
            newBookmarks[findBookmarkIndex].selected = false;
        }
    }
    else {
        newBookmarks = newBookmarks.map(i => {
            if (i.id !== task.id) {
                i.selected = false;
            }
            return i
        })
    }
    setBookMarks(newBookmarks)

    let newDatedBookmarks = JSON.parse(JSON.stringify(datedBookMarks));
    let findDatedBookmarkIndex = newDatedBookmarks.findIndex(item => item.id === task.id);
    if (findDatedBookmarkIndex > -1) {
        newDatedBookmarks[findDatedBookmarkIndex].selected = true;
    }
    if (findDatedBookmarkIndex > -1 && e && (e.ctrlKey || e.metaKey)) {
        if (newDatedBookmarks[findDatedBookmarkIndex].selected === true) {
            newDatedBookmarks[findDatedBookmarkIndex].selected = false;
        }
    }
    else {
        newDatedBookmarks = newDatedBookmarks.map(i => {
            if (i.id !== task.id) {
                i.selected = false;
            }
            return i
        })
    }
    setDatedBookMarks(newDatedBookmarks);
};

const removeTaskAfterIndex = (i, taskListLength, newTaskList, setSelectedTasks, dispatch, taskToUpdate, otherTaskList) => {
    let unselect = [];
    // console.log("i, taskListLength, newTaskList", i, taskListLength, newTaskList)
    for (let index = i + 1; index <= taskListLength - 1; index++) {
        newTaskList[index].active = false;
        unselect.push(newTaskList[index])
        setSelectedTasks((prev) => {
            let indexOfTask = prev.indexOf(newTaskList[index].id)
            if (indexOfTask > -1) {
                prev.splice(indexOfTask, 1)
            }
            return [...prev];
        });
    }
    newTaskList.splice(i + 1, (taskListLength - (i + 1)), ...unselect)
    dispatch({
        type: CUSTOM_SAVE_TASKS_REDUX,
        unAssignedTasksList: [...newTaskList, ...otherTaskList],
        key: taskToUpdate
    })
    return newTaskList
}

const selectMultiTaskShiftKey = (i, newTaskList, select, lastSelectedTaskIndex, selectedTasks, setSelectedTasks, dispatch, taskToUpdate, otherTaskList) => {
    // console.log("i, newTaskList, select", i, newTaskList, select, lastSelectedTaskIndex)
    let findTasksBWIndexes = newTaskList.slice(lastSelectedTaskIndex, i + 1)
    for (let task of findTasksBWIndexes) {
        if (!selectedTasks.includes(task.id)) {
            setSelectedTasks((prev) => {
                return [...prev, task.id];
            });
        }
        let findFromTasks = newTaskList.find(item => {
            return item.id === task.id
        })
        findFromTasks.active = true;
        select.push(findFromTasks)
    }
    newTaskList.splice(lastSelectedTaskIndex, (i - lastSelectedTaskIndex) + 1, ...select)
    dispatch({
        type: CUSTOM_SAVE_TASKS_REDUX,
        unAssignedTasksList: [...newTaskList, ...otherTaskList],
        key: taskToUpdate
    })
    return newTaskList
}

const taskSelectHandler = (task, removeSelected = false, unassignedTaskList, dispatch, taskToUpdate) => {
    if (removeSelected) {
        const tasks = unassignedTaskList.map((t) => {
            if (task.id === t.id) return { ...t, active: true };
            return { ...t, active: false };
        });
        dispatch({
            type: CUSTOM_SAVE_TASKS_REDUX,
            unAssignedTasksList: tasks,
            key: taskToUpdate
        })
    } else {
        const tasks = unassignedTaskList.map((t) => {
            if (task.id === t.id) return { ...t, active: true };
            return t;
        });
        dispatch({
            type: CUSTOM_SAVE_TASKS_REDUX,
            unAssignedTasksList: tasks,
            key: taskToUpdate
        })
    }
};

export const missionClickhandler = async ({ mission, preventDirection, setSelectedMissions, setDefaultCenter, getDirections, setcurrentMission, setSelectedCenter, selectedMissionsRef, currentMissionRef }) => {
    let misssion = JSON.parse(JSON.stringify(mission))
    setSelectedMissions([misssion.id]);
    selectedMissionsRef.current = [mission.id]
    if (mission.tasks[0]) setDefaultCenter({ ...misssion.tasks[0].location, address: misssion.tasks[0].address, id: misssion.tasks[0].id })
    if (!preventDirection) getDirections(misssion, true);
    setcurrentMission(misssion.id);
    currentMissionRef.current = misssion.id;
    setSelectedCenter(null)
};


export const missionTaskSingleClickHandler = ({ e, task, i, datedBookMarks, setDatedBookMarks, currentMissionRef,
    markerClick, unassignedTaskList, dispatch, selectedTasks, setSelectedTasks, missionsList, lastSelectedPostion,
    setSelectedTaskPosition, currentMission, setTaskDetail, setSelectedCenter, bookMarks, setBookMarks, rightSidebarOpenRef, setRightSidebarOpen }) => {
    let newTaskList = unassignedTaskList.map(item => {
        item.active = false;
        return item
    })
    dispatch({
        type: CUSTOM_SAVE_TASKS_REDUX,
        unAssignedTasksList: newTaskList,
        key: "unAssignedTasksList"
    })
    setSelectedTaskPosition(task.position)
    if (e.ctrlKey || e.metaKey) {
        if (!selectedTasks.includes(task.id)) {
            setSelectedTasks((prev) => {
                //  console.log("selected items:::",[...selectedTasks,task.id])
                return [...selectedTasks, task.id];
            });
            if (selectedTasks.length) {
                missionTaskSelectHandler(task, false, missionsList, dispatch);
            } else {
                missionTaskSelectHandler(task, true, missionsList, dispatch);
            }
        } else {
            //unselect the current task
            const missions = missionsList.map((m) => {
                if (m.tasks && m.tasks.length) {
                    m.tasks = m.tasks.map((t) => {
                        if (task.id === t.id) return { ...t, active: false, selected: false };
                        return t;
                    });
                }
                return m;
            });
            dispatch({
                type: CUSTOM_SAVE_MISSIONS_REDUX,
                missionList: missions,
                key: "missionsList"
            })
            //remove for selected array list
            const filteredArray = selectedTasks.filter((st) => st !== task.id);
            setSelectedTasks(filteredArray);
        }
    }
    else if (e.shiftKey) {
        let missionCurrent = currentMissionRef?.current ? currentMissionRef.current : currentMission
        let currentMissionData = missionsList.find(item => {
            return item.id === missionCurrent
        })
        let indexOfMission = missionsList.map(i => i.id).indexOf(missionCurrent)
        let missionData = setMissionTaskStatus(task, currentMissionData, selectedTasks, lastSelectedPostion, setSelectedTasks)
        currentMissionData = missionData;
        if (indexOfMission > -1) {
            missionsList.splice(indexOfMission, 1, currentMissionData)
        }
        dispatch({
            type: CUSTOM_SAVE_MISSIONS_REDUX,
            missionList: missionsList,
            key: "missionsList"
        })
    }
    else if (e.detail === 2) {
        setTaskDetail(task);
        let selectedCenter = {
            ...task.location, address: task.address, from: "task", id: task.id
        };
        if (task.lat) {
            selectedCenter.lat = task.lat
        }
        if (task.lng) {
            selectedCenter.lng = task.lng
        }
        rightSidebarOpenRef.current = true;
        setRightSidebarOpen(true)
        setSelectedCenter(selectedCenter)
    }
    else {
        if (e.button === 2) {
            if (selectedTasks.length <= 1) {
                if (!selectedTasks.includes(task.id)) {
                    let selectedCenter = {
                        ...task.location, address: task.address, from: "task", id: task.id
                    };

                    if (task.lat) {
                        selectedCenter.lat = task.lat
                    }
                    if (task.lng) {
                        selectedCenter.lng = task.lng
                    }
                    setSelectedTasks([task.id]);
                    missionTaskSelectHandler(task, true, missionsList, dispatch);
                    if (e && (!e.ctrlKey && !e.metaKey)) { setSelectedCenter(selectedCenter) }
                }
                else {
                    const index = selectedTasks.indexOf(task.id);
                    if (index > -1) {
                        selectedTasks.splice(index, 1); // 2nd parameter means remove one item only
                    }
                    setSelectedTasks([task.id]);
                    missionTaskSelectHandler(task, true, missionsList, dispatch);
                    if (!markerClick) setSelectedCenter(null)
                }
            }
        }
        else {
            if (!selectedTasks.includes(task.id)) {
                let selectedCenter = {
                    ...task.location, address: task.address, from: "task", id: task.id
                };

                if (task.lat) {
                    selectedCenter.lat = task.lat
                }
                if (task.lng) {
                    selectedCenter.lng = task.lng
                }
                setSelectedTasks([task.id]);
                missionTaskSelectHandler(task, true, missionsList, dispatch);
                if (e && (!e.ctrlKey && !e.metaKey)) { setSelectedCenter(selectedCenter) }
            }
            else {
                const index = selectedTasks.indexOf(task.id);
                if (index > -1) {
                    selectedTasks.splice(index, 1); // 2nd parameter means remove one item only
                }
                setSelectedTasks([task.id]);
                missionTaskSelectHandler(task, true, missionsList, dispatch);
                if (!markerClick) setSelectedCenter(null)
            }
        }
    }
    let newBookmarks = JSON.parse(JSON.stringify(bookMarks));
    let findBookmarkIndex = newBookmarks.findIndex(item => item.id === task.id);
    if (findBookmarkIndex > -1) {
        newBookmarks[findBookmarkIndex].selected = true;
    }
    if (findBookmarkIndex > -1 && e && (e.ctrlKey || e.metaKey)) {
        if (newBookmarks[findBookmarkIndex].selected === true) {
            newBookmarks[findBookmarkIndex].selected = false;
        }
    }
    else {
        newBookmarks = newBookmarks.map(i => {
            if (i.id !== task.id) {
                i.selected = false;
            }
            return i
        })
    }
    setBookMarks(newBookmarks);

    let newDatedBookmarks = JSON.parse(JSON.stringify(datedBookMarks));
    let findDatedBookmarkIndex = newDatedBookmarks.findIndex(item => item.id === task.id);
    if (findDatedBookmarkIndex > -1) {
        newDatedBookmarks[findDatedBookmarkIndex].selected = true;
    }
    if (findBookmarkIndex > -1 && e && (e.ctrlKey || e.metaKey)) {
        if (newDatedBookmarks[findDatedBookmarkIndex].selected === true) {
            newDatedBookmarks[findDatedBookmarkIndex].selected = false;
        }
    }
    else {
        newDatedBookmarks = newDatedBookmarks.map(i => {
            if (i.id !== task.id) {
                i.selected = false;
            }
            return i
        })
    }
    setDatedBookMarks(newDatedBookmarks);
};

const missionTaskSelectHandler = (task, removeSelected = false, missionsList, dispatch) => {
    if (removeSelected) {
        const missions = missionsList.map((m) => {
            if (m.tasks && m.tasks.length) {
                m.tasks = m.tasks.map((t) => {
                    if (task.id === t.id) {
                        return { ...t, active: true, selected: true };
                    }
                    return { ...t, active: false, selected: false };
                });
            }
            return m;
        });
        dispatch({
            type: CUSTOM_SAVE_MISSIONS_REDUX,
            missionList: missions,
            key: "missionsList"
        })
    } else {
        const missions = missionsList.map((m) => {
            if (m.tasks && m.tasks.length) {
                m.tasks = m.tasks.map((t) => {
                    if (task.id === t.id) {
                        return { ...t, active: true, selected: true };
                    }
                    return t;
                });
            }
            return m;
        });
        dispatch({
            type: CUSTOM_SAVE_MISSIONS_REDUX,
            missionList: missions,
            key: "missionsList"
        })
    }
};

const setMissionTaskStatus = (task, mission, selectedTasks, lastSelectedPostion, setSelectedTasks) => {
    let select = [];
    if (!selectedTasks.includes(task.id)) {
        let findTasksBWIndexes = mission.tasks.slice(lastSelectedPostion, task.position + 1)

        for (let task of findTasksBWIndexes) {
            if (!selectedTasks.includes(task.id)) {
                setSelectedTasks((prev) => {
                    return [...prev, task.id];
                });
            }
            let findFromTasks = mission.tasks.find(item => {
                return item.id === task.id
            })
            findFromTasks.active = true;
            select.push(findFromTasks)

        }
        mission.tasks.splice(lastSelectedPostion, (task.position - lastSelectedPostion) + 1, ...select)
        // setTaskList(newTaskList)
        return mission
    }
    else {
        let unselect = [];
        for (let index = task.position + 1; index <= mission.tasks.length - 1; index++) {
            mission.tasks[index].active = false;
            unselect.push(mission.tasks[index])
            setSelectedTasks((prev) => {
                let indexOfTask = prev.indexOf(mission.tasks[index].id)
                if (indexOfTask > -1) {
                    prev.splice(indexOfTask, 1)
                }
                return [...prev];
            });
        }
        mission.tasks.splice(task.position + 1, (mission.tasks.length - (task.position + 1)), ...unselect)
        return mission
    }
}

export const selectTasksPolygon = ({ tasks, bookMarks, tasksList, setBookMarks, setSelectedTasks, dispatch }) => {
    let newSelectedTasks = []
    let newBookMarks = [...bookMarks]
    let newUnassignedTaskList = JSON.parse(JSON.stringify(tasksList))
    newBookMarks = newBookMarks.map(i => {
        i.selected = false;
        return i
    })
    newUnassignedTaskList = newUnassignedTaskList.map(i => {
        i.active = false;
        return i
    })
    for (let task of tasks) {
        if (task.missioned) {

        }
        else {
            let findSelectedIndex = newSelectedTasks.findIndex(i => i.id === task.id);
            if (findSelectedIndex < 0) {
                newSelectedTasks.push(task.id)
            }
            let unassignedTaskListIndex = newUnassignedTaskList.findIndex(i => i.id === task.id);
            if (unassignedTaskListIndex >= 0) {
                newUnassignedTaskList[unassignedTaskListIndex].active = true
            }
            let findBookmarkIndex = newBookMarks.findIndex(i => i.id === task.id)
            if (findBookmarkIndex >= 0) {
                newBookMarks[findBookmarkIndex].selected = true
            }
        }

    }
    setBookMarks(newBookMarks)
    setSelectedTasks(newSelectedTasks)
    let findUnassignedTasks = newUnassignedTaskList.filter(items => { return !!items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned });
    let findTasksWithoutDate = newUnassignedTaskList.filter(items => { return !items.actionPendingCustomer && !items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned });
    let findTasksPendingAction = newUnassignedTaskList.filter(items => { return items.actionPendingCustomer && !items.orderDate && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned });
    let findDatedTasks = newUnassignedTaskList.filter(items => {
        return items.orderDate !== null && items.taskStatus === TASK_STATUSES.UNASSIGNED && items.taskCategory === TASK_STATUSES.UNASSIGNED && !items.missioned
    })
    dispatch({
        type: CUSTOM_SAVE_ALL_TASKS_REDUX,
        taskToUpdate: {
            datedTaskList: findDatedTasks,
            tasksListPendingAction: findTasksPendingAction,
            tasksListWithouDate: findTasksWithoutDate,
            unAssignedTasksList: findUnassignedTasks
        }

    })
}
