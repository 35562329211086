import React, { useState } from "react";
import {
    Row,
    Col,
    Button,
    Dropdown,
    NavDropdown,
} from "react-bootstrap";
// import "./user.scss";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import downloadicon from "../../../../assets/image/downloadicon.png";
import ShortIcon from "../../../../assets/image/filter-icon.png";
import { ToastContainer } from "react-toastify";
import ExportPopup from "../../../Exportpopup/Exportpopup";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Swal from "sweetalert2";
import BottomMenu from "../../components/BottomMenu/BottomMenu";
import "./ListView.scss"
import { useSelector } from "react-redux";
import TopBar from "../../components/Topbar/Topbar";
import RightSideBar from "../../components/Rightsidebar/Rightsidebar";
import { timeZoneHeader } from "../../../../helpers/helperFunctions";

import filterremoveicon from "../../../../assets/image/filter-removeicon.png";
const timezone = { ...timeZoneHeader() }
const ListView = (props) => {
    console.log("editTaskeditTask", props)
    const { taskPageCount,
        taskPageLimit, tasksList } = useSelector((state) => state.task);
    const [currentPage, setCurrentPage] = useState(1);
    const [exportTasks, setExportTasks] = useState(false);
    const exportTaskToggle = () => {
        setExportTasks(!exportTasks);
    };
    const topbarProps = {
        ...props.topbarProps
    }
    const RowSelectHandler = (row, isSelect, rowIndex, e) => {
        // ...
        if (isSelect) {
            const item = row.id || "";
            props.setSelectedTasks([...props.selectedTasks, item]);
        } else {
            const items = props.selectedTasks.filter((sItem) => sItem != row.id);
            props.setSelectedTasks(items);
        }
    };

    const selectAllRowsHandler = (isSelect, rows, e) => {
        if (isSelect) {
            const items = rows
                .map((rowItem) => rowItem.id)
                .filter((itemId) => {
                    if (!props.selectedTasks.includes(itemId)) return itemId;
                });
            props.setSelectedTasks([...props.selectedTasks, ...items]);
        } else {
            const ids = rows.map((rowItem) => rowItem.id);
            const items = props.selectedItems.filter((sItem) => {
                if (!ids.includes(sItem)) return sItem;
            });
            props.setSelectedTasks(items);
        }
    };

    const selectRowProp = {
        mode: "checkbox",
        clickToSelect: false,
        onSelect: RowSelectHandler,
        onSelectAll: selectAllRowsHandler,
        bgColor: "rgb(238, 193, 213)",
    };

    const renderName = (obj) => {
        if (obj.customer) {
            return `${obj.customer.firstName ? obj.customer.firstName : ''} ${obj.customer.lastName ?
                obj.customer.lastName : ''}`
        }
        else if (obj.customerId) {
            return `${obj.customerId.firstName ? obj.customerId.firstName : ''} ${obj.customerId.lastName ?
                obj.customerId.lastName : ''}`
        }
        else {
            return ``
        }
    }

    function CustomerNameFormatter(cell, row) {
        return (
            <span>
                {renderName(row)}
            </span>
        );
    }

    function CustomerPhoneFormatter(cell, row) {
        return (
            <span>
                {row.customer.countryCode && row.customer.phoneNumber ? `${row.customer.countryCode}${row.customer.phoneNumber}` : ""}
            </span>
        );
    }
    function Latitude(cell, row) {
        return (
            <span>
                {row.location && row.location.lat ? `${row.location.lat}` : ""}
            </span>
        );
    }
    function Longitude(cell, row) {
        return (
            <span>
                {row.location && row.location.lng ? `${row.location.lng}` : ""}
            </span>
        );
    }
    function CustomerEmailFormatter(cell, row) {
        return (
            <span>
                {row.customer.email ? `${row.customer.email}` : "N/A"}
            </span>
        );
    }

    function TaskDateFormatter(cell, row) {
        return (
            <span>
                {row.orderDate ? `${moment(row.orderDate).format("YYYY/MM/DD")}` : ""}
            </span>
        );
    }

    function TeamNameFormatter(cell, row) {
        return <span>{row.teamId ? row.teamId.teamName : ""}</span>;
    }

    function AgentNameFormatter(cell, row) {
        return <span>{row.agentId && row.agentId.firstName ? row.agentId.firstName : ""} {row.agentId && row.agentId.lastName ? row.agentId.lastName : ""}</span>;
    }

    function AddressNameFormatter(cell, row) {
        return <span style={{
            overflowX: "hidden",
            textOverflow: "ellipsis",
            "whiteSpace": "nowrap",
            "display": "block",
            width: "150px"
        }
        }> {row.address ? row.address : ""}</span >;
    }

    function OrderTypeFormatter(cell, row) {
        if (row.orderType === "D") return <span>Drop</span>
        if (row.orderType === "DI") return <span>Drop & Service</span>
        if (row.orderType === "S") return <span>Service</span>
        if (row.orderType === "R") return <span>Return</span>
        if (row.orderType === "P") return <span>Pick Up</span>
        if (row.orderType === "E") return <span>Exchange</span>
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const formatStatus = (cell, row) => {
        return <span>{capitalizeFirstLetter(row.taskStatus)}</span>;
    };

    const ActualStopTime = (cell, row) => {
        if (row.orderCompletedTimeStamp && row.orderReachedTimeStamp) {
            let duration = Number(row.orderCompletedTimeStamp) - Number(row.orderReachedTimeStamp);
            return <span>{Math.ceil((duration / 1000) / 60)}</span>;
        }
        else {
            return <span>{row.durationTime}</span>;
        }

    }
    const handlePageClick = async ({ selected }) => {
        if (selected + 1 !== currentPage) {
            setCurrentPage(selected + 1);
        }
    };

    const exportPopupProps = {
        ...props,
        setExportTasks,
    }
    const renderMerchantName = (cell, row) => {
        if (row.createdFor) {
            return row.createdFor.companyName ? row.createdFor.companyName : `${row.createdFor.firstName}${row.createdFor.lastName}`
        }
        else if (row.merchantId) {
            return row.merchantId.companyName ? row.merchantId.companyName : `${row.merchantId.firstName}${row.merchantId.lastName}`
        }
        else {
            return row.userId.companyName ? row.userId.companyName : `${row.userId.firstName}${row.userId.lastName}`
        }
    }

    const deleteAllHandler = () => {
        if (props.selectedTasks && props.selectedTasks.length) {
            Swal.fire({
                title: "Are you sure?",
                text: "You want to delete task(s)!",
                icon: "warning",
                showCancelButton: true,
                color: "#ffffff",
                background: "#151719",
                confirmButtonColor: "#1A1D1F",
                cancelButtonColor: "#1A1D1F",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => props.deleteTaskFunction(result, null, props.selectedTasks));
        }
    }
    const rightSidebarProps = {
        ...props
    }

    const handleActionClick = (type, item) => {
        if (type === "Edit") {
            props.editMenuHandler({ selectedTask: [item.id] })
        }
        if (type === "Delete") {
            props.deleteMenuHandler({ selectedTask: [item.id], list: true })
        }
    }

    const Remove = (cell, row) => {
        return (
            <>
                <span>
                    <span className="filter-edit">
                        <div className="action">
                            <NavDropdown
                                title={<img src={filterremoveicon} alt={"down-arrow"} />}
                                id="nav-dropdown"
                            >
                                <NavDropdown.Item
                                    eventKey="4.1"
                                    onClick={() => {
                                        handleActionClick("Edit", row);
                                    }}
                                >
                                    Edit
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    eventKey="4.6"
                                    onClick={() => {
                                        handleActionClick("Delete", row);
                                    }}
                                >
                                    Delete
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </span>
                </span>
            </>
        );
    };

    const renderOrderDate = (task) => {
        let orderDate = moment(task.orderDate).tz(timezone.timezone)._a;
        return moment(orderDate).tz(timezone.timezone).format("YYYY-MM-DD");
    }

    return (<>
        <div className="customer-page-wrapper d-flex">
            {/* customer layout start */}
            <div>
                <TopBar {...topbarProps} />
                {/* <RightSideBar {...rightSidebarProps} /> */}
                <div className="customer-form-wrapper">
                    <div className="customer-heading-box">
                        <Row>
                            <Col xs={6}>
                                <h1>List View</h1>
                            </Col>
                            <Col xs={6}>
                                <div className="createmsbox">
                                    <div onClick={() => exportTaskToggle()}>
                                        <Button onClick={() => exportTaskToggle()}>
                                            <img src={downloadicon} alt="icon" />
                                        </Button>
                                        <span >Export tasks</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="customer-searching-box">
                        <Row>
                            <Col xs={6}>
                                <div className="create-action-option mb-3">
                                    <Dropdown className="list-dropdown">
                                        <Dropdown.Toggle className="bulk-action">
                                            Bulk Action
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={deleteAllHandler}>
                                                Delete Selected
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* customer list start */}
                    <div className="customer-list-wrapper">
                        {/* <MyMapComponent key="map" /> */}
                        <BootstrapTable
                            data={tasksList}
                            striped={true}
                            hover={true}
                            selectRow={selectRowProp}
                        >
                            <TableHeaderColumn
                                dataField="orderType"
                                dataAlign="left"
                                dataSort={false}
                                width="150px"
                                dataFormat={OrderTypeFormatter}
                            >
                                Task type <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="customer"
                                dataSort={true}
                                width="200px"
                                dataFormat={CustomerNameFormatter}
                            >
                                Recipient name
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="customer"
                                dataSort={true}
                                width="200px"
                                dataFormat={CustomerPhoneFormatter}
                            >
                                Phone Number
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="customer"
                                dataSort={true}
                                width="200px"
                                dataFormat={CustomerEmailFormatter}
                            >
                                Contact Email
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="address"
                                dataAlign="left"
                                width="200px"
                                dataSort={false}
                                tdStyle={{ whiteSpace: 'normal' }}
                                dataFormat={AddressNameFormatter}
                            >
                                Address <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                dataFormat={formatStatus}
                                width="150px"
                                dataSort={false}
                                isKey={true}
                            >
                                Task Status
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="orderDate"
                                dataSort={true}
                                dataFormat={renderOrderDate}
                                width="200px"
                            >
                                Planned Date
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="after"
                                dataSort={false}
                                dataAlign="left"
                                width="170px"
                            >
                                Between <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="before"
                                dataSort={false}
                                dataAlign="left"
                                width="170px"
                            >
                                And <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>

                            {/* <TableHeaderColumn
                            dataField="location"
                            width="100px"
                            dataSort={false}
                            dataFormat={Latitude}
                        >
                            Latitude
                            <img className="ms-1" src={ShortIcon} alt="" />
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="location"
                            width="200px"
                            dataSort={false}
                            dataFormat={Longitude}
                        >
                            Longitude
                            <img className="ms-1" src={ShortIcon} alt="" />
                        </TableHeaderColumn> */}


                            <TableHeaderColumn
                                dataField="orderStartedDate"
                                width="200px"
                                dataSort={false}
                                dataFormat={(cell, row) => {
                                    return `${row.orderStartedDate ? moment(row.orderStartedDate).format("lll") : ""}`
                                }}
                            >
                                Started On
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="orderCompletedDate"
                                width="200px"
                                dataSort={false}
                                dataFormat={(cell, row) => {
                                    return `${row.orderCompletedDate ? moment(row.orderCompletedDate).format("lll") : ""}`
                                }}
                            >
                                Completed On
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="notes"
                                dataSort={false}
                                width="200px"
                            // dataFormat={TaskDateFormatter}
                            >
                                Task Notes
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="agentId"
                                width="150px"
                                dataSort={false}
                                dataFormat={AgentNameFormatter}
                            >
                                Agent Name
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="teamId"
                                width="150px"
                                dataSort={false}
                                dataFormat={TeamNameFormatter}
                            >
                                Team Name
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="teamId"
                                width="150px"
                                dataSort={false}
                                dataFormat={(cell, row) => {
                                    return `${row.picturesBefore?.length > 0 || row.picturesAfter?.length > 0 ? "Yes" : "No"}`
                                }}
                            >
                                Picture(s)
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="teamId"
                                width="150px"
                                dataSort={false}
                                dataFormat={(cell, row) => {
                                    return `${row.signature?.original ? "Yes" : "No"}`
                                }}
                            >
                                Signature
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="agentNotes"
                                width="150px"
                                dataSort={false}
                            // dataFormat={AgentNameFormatter}
                            >
                                Driver Notes
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="createdFor"
                                width="200px"
                                dataSort={false}
                                dataFormat={renderMerchantName}
                            >
                                Assigned organization
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="createdFor"
                                width="200px"
                                dataSort={false}
                                dataFormat={(cell, row) => {
                                    return row.teamIds ? row.teamIds.map(t => t.teamName).join(", ") : "All Teams"
                                }}
                            >
                                Visibility
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>


                            <TableHeaderColumn
                                dataField="durationTime"
                                dataAlign="left"
                                dataSort={false}
                                width="190px"
                            >
                                Expected Time at Stop <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="durationTime"
                                dataAlign="left"
                                width="180px"
                                dataSort={false}
                                dataFormat={ActualStopTime}
                            >
                                Actual Time at Stop <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="capacity"
                                dataAlign="left"
                                width="150px"
                                dataSort={false}
                            >
                                Task Volume <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="reason"
                                dataAlign="left"
                                width="150px"
                                dataSort={false}
                            >
                                Failed Reason <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="price"
                                // dataFormat={MissionFormatter}
                                width="150px"
                                dataSort={false}
                            >
                                Task Cost
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn
                            dataField="price"
                            // dataFormat={MissionFormatter}
                            width="150px"
                            dataSort={false}
                        >
                            Delivery cost
                            <img className="ms-1" src={ShortIcon} alt="" />
                        </TableHeaderColumn> */}
                            <TableHeaderColumn
                                dataField="createdDate"
                                dataFormat={(cell, row) => {
                                    return `${row.createdDate ? moment(row.createdDate).format("DD-MMM-YYYY") : ""}`
                                }}
                                width="160px"
                                dataSort={false}
                            >
                                Task Creation Date
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="createdBy"
                                dataFormat={(cell, row) => {
                                    return `${row.createdBy.companyName ? row.createdBy.companyName : row.createdBy.firstName + " " + row.createdBy.lastName}`
                                }}
                                width="150px"
                                dataSort={false}
                            >
                                Task Created By
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="createdBy"
                                dataFormat={Remove}
                                width="150px"
                                dataSort={false}
                            >
                                Action
                                <img className="ms-1" src={ShortIcon} alt="" />
                            </TableHeaderColumn>
                        </BootstrapTable>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={taskPageCount}
                            previousLabel="Prev"
                            renderOnZeroPageCount={null}
                            className="pagination"
                        />
                    </div>

                    {/* customer list end */}
                </div>
            </div>
            {props.editTask && <RightSideBar {...rightSidebarProps} />}
            {/* customer layout end */}
        </div>
        {/* Export Tasks popup start */}
        {exportTasks && <ExportPopup {...exportPopupProps} />}
        {/* Export Tasks popup end */}
        <BottomMenu {...props.bottomMenuProps} />

        <ToastContainer
            style={{ color: "black" }}
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>)
}

export default ListView