import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Card, Row, Col, InputGroup, FormControl, Button, Dropdown, Modal, Container } from 'react-bootstrap';

import nameicon from '../../assets/image/nameicon.png';
import mailicon from '../../assets/image/mailicon.png';
import companyicon from '../../assets/image/companyicon.png';
import passwordicon from '../../assets/image/password.png';
import cardicon from '../../assets/image/cardicon.png';
import backicon from '../../assets/image/backicon.png';
import countryicon from '../../assets/image/countryicon.png';
import deliveryicon from '../../assets/image/deliveryicon.png';
import industryicon from '../../assets/image/industryicon.png';
import stateicon from '../../assets/image/stateicon.png';
import selecticon from '../../assets/image/selecticon.png';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import authService from "../../services/auth.service";
import { Loader } from "../Loader/loader";
import {
    successAlert,
    errorAlert,
    infoAlert,
} from "../../services/toast.service";
import data from "./data.json"

const Businessinfo = ({ step, setStep, formData, setformData }) => {
    let history = useHistory();

    const [country, setCountry] = useState(formData.country ? formData.country : '')
    const [state, setState] = useState(formData.state ? formData.state : '')
    const [industries, setIndustries] = useState([]);
    const [dailyDeliveryVolumes, setDailyDeliveryVolumes] = useState([]);
    const [newsLetter, setNewsLetter] = useState(
        formData.newsLetter ? formData.newsLetter : false
    );
    const [termsAndConditions, setTermsAndConditions] = useState(
        formData.termsAndConditions ? formData.termsAndConditions : false
    );

    const [loading, setLoading] = useState(false);
    const continueHandler = () => {
        // setStep(step + 1)
        goHandler(formData)
    }
    const backHandler = () => {

        setStep(step - 1)
    }
    console.log("CountryRegionData", data)
    const goHandler = async (formValue) => {
        // e.preventDefault();
        setLoading(true);
        let date = new Date();
        date.setHours(8);
        date.setMinutes(0);
        let timeSplit = date.toLocaleTimeString().split(":")
        let afterTime = `${timeSplit[0]}:${timeSplit[1]}`
        let afterSeconds = Number((date.getHours() * 3600) + (date.getMinutes() * 60))
        formData.defaultAfterTime = afterTime
        formData.defaultAfterTimeSeconds = afterSeconds
        formData.defaultAfterTimePicker = date;
        let beforedate = new Date();
        beforedate.setHours(17);
        beforedate.setMinutes(0);
        let beforetimeSplit = beforedate.toLocaleTimeString().split(":")
        let beforeTime = `${beforetimeSplit[0]}:${beforetimeSplit[1]}`
        let beforeSeconds = Number((beforedate.getHours() * 3600) + (beforedate.getMinutes() * 60))
        formData.defaultBeforeTime = beforeTime
        formData.defaultBeforeTimeSeconds = beforeSeconds
        formData.defaultBeforeTimePicker = beforedate;
        let countryCode = "CA";
        let stateCode = "";
        let countryData = CountryRegionData.find(i => i[0] === country);
        if (countryData && countryData.length) {
            countryCode = countryData[1]
        }
        let stateData = data.find(i => i.countryName === country);
        if (stateData) {
            let regions = stateData.regions
            let stateCodeData = regions.find(i => i.name === state);
            if (stateCodeData) {
                stateCode = stateCodeData.shortCode
            }
        }
        const response = await authService.register({
            ...formData,
            ...formValue,
            country: country ? country : '',
            countryISOCode: countryCode,
            stateCode: stateCode,
            state: state ? state : '',
            newsLetter,
            termsAndConditions
        });
        if (response && response.data && response.data.success) {
            setLoading(false);
            successAlert(response.data.message);
            setTimeout(() => {
                history.push("/login");
            }, 3000);
        } else {
            setLoading(false);
            errorAlert(
                response.data.message ? response.data.message : "Something went wrong"
            );
        }
    };
    // Yup.addMethod(Yup.string, "country", function (errorMessage) {
    //     return this.test(`test-country`, errorMessage, function (value) {
    //         const { path, createError } = this;

    //         return (
    //             country ||
    //             createError({ path, message: errorMessage })
    //         );
    //     });
    // });
    // Yup.addMethod(Yup.string, "state", function (errorMessage) {
    //     return this.test(`test-state`, errorMessage, function (value) {
    //         const { path, createError } = this;

    //         return (
    //             state ||
    //             createError({ path, message: errorMessage })
    //         );
    //     });
    // });
    const validateCustom = (values, props) => {
        let error = {}
        if (!country) {
            error.country = 'please select a Country';
            error.state = 'please select a State';
        } else {
            delete error.country
        }


        return error
    }

    const validationSchema = Yup.object().shape({
        industry: Yup.string().required("please select industry"),
        // month: Yup.string().required("please select industry"),
        dailyDeliveryVolume: Yup.string().required("please select daily delivery volume"),
        // state: Yup.string().required("please select Stage"),

    });


    useEffect(() => {
        getIndustries();
        getDailyDeliveryVolumes();
    }, []);


    const getIndustries = async () => {
        let params = {
            type: ["INDUSTRIES"]
        };
        const response = await authService.getCommonServices(params);
        if (response.status == 200) {
            setIndustries(response.data.data.results || []);
        }
        console.log("user list response  :::", response);
    }

    const getDailyDeliveryVolumes = async () => {
        let params = {
            type: ["DAILY_DELIVERY_VOLUMES"]
        };
        const response = await authService.getCommonServices(params);
        if (response.status == 200) {
            setDailyDeliveryVolumes(response.data.data.results || []);
        }
        console.log("user list response  :::", response);
    }

    const handleSubmit = (formValue) => {

        setformData(prev => {
            return { ...prev, ...formValue, country: country ? country : '', state: state ? state : '' }
        })
        continueHandler()


    }

    return (
        <div>
            <div className="signupinfo-form">
                <h1 className="heading">Create your Gomove account</h1>
                <h2 className="personal-info-subheading">Business info</h2>
                <div className="signup-form-box business-info">

                    <Formik
                        initialValues={formData}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        // onSubmit={goHandler}
                        validate={validateCustom}
                    >
                        <Form inline="true">
                            <Row>
                                <Col xs={6}>
                                    <InputGroup className="mb-3 business-input">
                                        {/* <InputGroup.Text id="country"><img src={countryicon} alt="countryicon" /></InputGroup.Text> */}
                                        <CountryDropdown
                                            className="form-control"
                                            value={country}
                                            onChange={(val) => {
                                                console.log("val", val)
                                                setCountry(val)
                                                setformData(prev => {
                                                    return { ...prev, country: country ? country : '' }
                                                })
                                            }} />

                                        {!country && <ErrorMessage name="country" component="div" />}
                                    </InputGroup>
                                </Col>
                                <Col xs={6}>
                                    <InputGroup className="mb-3 business-input">
                                        {/* <InputGroup.Text id="State"><img src={stateicon} alt="stateicon" /></InputGroup.Text> */}

                                        <RegionDropdown
                                            className="form-control"
                                            country={country}
                                            value={state}
                                            onChange={(val, a) => { console.log(val, a.target.value); setState(val) }} />

                                        {!state && <ErrorMessage name="state" component="div" />}

                                    </InputGroup>

                                </Col>
                                <Col xs={6}>
                                    <InputGroup className="mb-3 business-input">
                                        <InputGroup.Text id="Industry"><img src={industryicon} alt="industryicon" /></InputGroup.Text>

                                        {industries.length ? <Field className="form-control" name="industry" as="select">
                                            <option selected value="">Select Industry</option>
                                            {industries.map(i => {
                                                return (<option key={i.id} value={i.name}>{i.name}</option>)
                                            })}
                                        </Field> : <Field className="form-control" name="industry" as="select">
                                            <option selected value="">Select Industry</option>
                                            <option value="Dummy Industry">Dummy Industry</option>
                                            <option value="Dummy Industry">Dummy Industry</option>
                                            <option value="Dummy Industry">Dummy Industry</option>
                                        </Field>}
                                        <ErrorMessage name="industry" component="div" />
                                    </InputGroup>
                                </Col>
                                <Col xs={6}>
                                    <InputGroup className="mb-3 business-input">
                                        <InputGroup.Text id="Delivery"><img src={deliveryicon} alt="deliveryicon" /></InputGroup.Text>
                                        {dailyDeliveryVolumes.length ? <Field className="form-control" name="dailyDeliveryVolume" as="select">
                                            <option selected value="" >Daily Delivery Volumes</option>
                                            {dailyDeliveryVolumes.map(i => {
                                                return (<option key={i.id} value={i.name}>{i.name}</option>)
                                            })}
                                        </Field> : <Field className="form-control" name="dailyDeliveryVolume" as="select">
                                            <option selected value="" >Daily Delivery Volumes</option>
                                            <option value="jan">Jan</option>
                                            <option value="feb">Feb</option>
                                            <option value="march">March</option>
                                        </Field>}

                                        <ErrorMessage name="dailyDeliveryVolume" component="div" />
                                        {/* <Form.Select>
                                            <option>Monthly Delivery Volum</option>
                                            <option value="one">One</option>
                                            <option value="two">Two</option>
                                            <option value="three">Three</option>
                                        </Form.Select> */}
                                    </InputGroup>
                                </Col>
                                <div className="payment-information">
                                    <InputGroup className="mb-3 privacypolicy">
                                        <InputGroup.Checkbox
                                            id="newsletter"
                                            value={newsLetter}
                                            checked={newsLetter === true}
                                            onChange={(e) => setNewsLetter(!newsLetter)}
                                        />

                                        <label htmlFor="newsletter">
                                            Yes! I’d like to receive Delivered, Gomove’s curated industry newsletter
                                        </label>
                                    </InputGroup>
                                    <InputGroup
                                        className="mb-3 privacypolicy"
                                    >
                                        <InputGroup.Checkbox
                                            id="customCheck"
                                            checked={termsAndConditions === true}
                                            value={termsAndConditions}
                                            onChange={(e) => setTermsAndConditions(!termsAndConditions)}
                                            name="example1"
                                        />
                                        <label htmlFor="customCheck">
                                            I agree to the <a href="https://gomove.ai/terms-conditions/" target="_blank" rel="noreferrer">terms of service </a>
                                            and <a href="https://gomove.ai/privacy-policy/" target="_blank" rel="noreferrer">privacy policy</a>
                                        </label>
                                    </InputGroup>
                                </div>
                            </Row>

                            <div className="bsbtn">
                                <Row>
                                    <Col xs={6}>
                                        <div className="submitbtn">
                                            <Button onClick={backHandler}>Back</Button>

                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="submitbtn">
                                            <Button type="submit" disabled={!termsAndConditions}>Continue</Button>
                                            <p>This site is protected by reCAPTCHA and the Google Privacy Policy.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Formik>
                </div>
                {loading ? <Loader /> : null}
            </div>
        </div>
    );
};

export default Businessinfo;