import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: transparent;
  margin-top: 80px;
  padding: 20px;
  border-radius: 8px;
  width: 250px;
  color: white;
`;

const TaskRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TaskType = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin-right: 10px;
`;

const TaskInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Count = styled.div`
  margin-right: 5px;
`;

const TaskList = ({ data }) => {
  return (
    <Container>
      {data.map((task, index) => (
        <TaskRow key={index}>
          <TaskType>
            <Dot color={task.color} />
            {task.name}
          </TaskType>
          <TaskInfo>
            <Count>{task.value}</Count>
            <div>• {task.percentage}%</div>
          </TaskInfo>
        </TaskRow>
      ))}
    </Container>
  );
};

export default TaskList;