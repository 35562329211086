import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Card,
  Form as BootstrapForm,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Dropdown,
  Modal,
  Container,
  NavDropdown,
} from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import closeicon from "../../../../assets/image/close-icons.png";
import taskPdfIcon from "../../../../assets/image/download.png";
import taskService from "../../../../services/task.service";
import moment from "moment-timezone";
import { renderCustomerName, timeZoneHeader } from "../../../../helpers/helperFunctions";
import fileDownload from "js-file-download";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { errorAlert } from "../../../../services/toast.service";

const timezone = timeZoneHeader();
const TaskRightSideDetails = (props) => {
  const history = useHistory();
  const [taskHistory, setTaskHistory] = useState([]);
  const userData = useSelector((state) => state.auth.user);
  let task = props.taskDetail;
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  useEffect(() => {
    getTaskHistory();
  }, [task]);
  let getTaskHistory = async () => {
    let response = await taskService.getTaskHistory({ taskId: task.id });
    if (response && response.data && response.data.results) {
      setTaskHistory(response.data.results);
    }
  };
  const renderEmail = (obj) => {
    if (obj.customer) {
      return `${obj.customer.email ? obj.customer.email : ""}`;
    } else if (obj.customerId) {
      return `${obj.customerId.email ? obj.customerId.email : ""}`;
    } else {
      return `NA`;
    }
  };

  const [isHoveringReport, setIsHoveringReport] = useState(false);

  const handleMouseOverReport = () => {
    setIsHoveringReport(true);
  };

  const handleMouseOutReport = () => {
    setIsHoveringReport(false);
  };

  const borderColor = (status) => {
    let color = "#B3B3B3";
    if (status === "created") {
      color = "#B3B3B3";
    } else if (status === "updated") {
      color = "#B3B3B3";
    } else if (status === "assigned") {
      color = "#AE16F2";
    } else if (
      status === "started" ||
      status === "Picture Before" ||
      status === "reached" ||
      status === "Picture After"
    ) {
      color = "#AE16F2";
    } else if (status === "completed") {
      color = "#00B330";
    } else if (status === "failed" || status === "Reason") {
      color = "#D10015";
    }
    return color;
  };
  const printManifestHandler = async () => {
    if (
      (props.features && props.features.exportTaskPdf) ||
      userData.role === 1
    ) {
      // props.setPrintToggle(true)
      let data = {
        taskId: task.id,
      };
      let response = await taskService.createTaskPDF(data);
      if (response.data) {
        fileDownload(response.data, "Task Report.pdf");
        // props.setPDFData(response.data)
      } else {
        return errorAlert("Something went wrong!!!");
      }
    } else {
      history.push({
        pathname: "/Business/exportTaskPdf",
      });
    }
  };

  let orderType = "";
  if (task.orderType === "D") orderType = "Drop";
  else if (task.orderType === "DI") orderType = "Drop & Service";
  else if (task.orderType === "S") orderType = "Service";
  else if (task.orderType === "R") orderType = "Return";
  else if (task.orderType === "P") orderType = "Pick Up";
  else if (task.orderType === "E") orderType = "Exchange";

  const renderDocs = () => {
    let docs = [];
    // if (task.docs && task.docs.length) {
    //   for (let item of task.docs) {
    //     let librarydoc = props.libraryList.find(i => i.id === item);
    //     if (librarydoc) {
    //       let name = librarydoc.attachments[0].split("/")
    //       docs.push(
    //         <li><span className="pdfvalue">PDF</span><span className="pdfname">{name[3]}</span></li>
    //       )
    //     }
    //   }
    // }
    if (task.libraryFiles && task.libraryFiles.length) {
      for (let item of task.libraryFiles) {
        let name = item.original.split("/");
        let ext = item.original
          .substring(item.original.lastIndexOf(".") + 1, item.original.length)
          .toLowerCase();
        docs.push(
          <div className="upload__image-wrapper">
            <div
              className="selected-docs"
              onClick={() => {
                if (item && item.original) {
                  window.open(item.original, "_blank");
                }
              }}
            >
              {ext === "pdf" ? (
                <div>
                  <span className="pdfvalue">PDF</span>
                </div>
              ) : (
                <div>
                  <img class="original-image" src={item.original} alt="icon" />
                </div>
              )}
            </div>
            <div className="filename">
              <span>{name[4]}</span>
            </div>
          </div>
        );
      }
    } else if (task.documents && task.documents.length) {
      for (let item of task.documents) {
        docs.push(
          <li>
            <span className="pdfvalue">PDF</span>
            <span
              className="pdfname"
              onClick={() => {
                if (item && item.original) {
                  window.open(item.original, "_blank");
                }
              }}
            >
              {item.fileName}
            </span>
          </li>
        );
      }
    } else {
      docs.push(<li></li>);
    }
    return docs;
  };
  const renderMerchantName = (task) => {
    if (task.createdFor) {
      return task.createdFor.companyName
        ? task.createdFor.companyName
        : `${task.createdFor.firstName}${task.createdFor.lastName}`;
    } else if (task.merchantId) {
      return task.merchantId.companyName
        ? task.merchantId.companyName
        : `${task.merchantId.firstName}${task.merchantId.lastName}`;
    } else {
      return task.userId.companyName
        ? task.userId.companyName
        : `${task.userId.firstName}${task.userId.lastName}`;
    }
  };
  const renderOrderDate = () => {
    let orderDate = moment(task.orderDate).tz(timezone.timezone)._a;
    return moment(orderDate).tz(timezone.timezone).format("MMMM DD, YYYY");
  }

  return (
    <div>
      {/* create detail history start */}
      <div className="createtask-slide Detailhistory">
        <div className="close-wrapper">
          <Button
            className="createtask-close"
            onClick={() => {
              props.setTaskDetail(null);
              if (props.fromSearch) {
                props.setShowSearchComponent(true)
              }
            }}
          >
            <img src={closeicon} alt="icon" />
          </Button>
        </div>
        <div className="create-task-field ">
          <div>
            <span className="taskid">Task Information</span>
          </div>
          <div className="task-title" onMouseOver={handleMouseOverReport}
            onMouseOut={handleMouseOutReport}>
            <Button
              className="createtask-print"
              onClick={() => {
                printManifestHandler();
              }}
            >
              <img src={taskPdfIcon} alt="icon" />
            </Button>
            {isHoveringReport && (
              <div className="report-text">
                <p>Download task report</p>
              </div>
            )}
          </div>
        </div>
        {/* Customer Details */}
        <div className="create-customer-detail">
          <div className="Detail-customers-form">
            <ul>
              <li>
                <span className="csname">Recipient Name</span>
                <span className="csvalue">{renderCustomerName(task)}</span>
              </li>
              <li>
                <span className="csname">Phone Number</span>
                <span className="csvalue">
                  {task.customerId && task.customerId.phoneNumber}
                </span>
              </li>
              <li>
                <span className="csname">Email Address</span>
                <span className="csvalue">{renderEmail(task)}</span>
              </li>
              <li>
                <span className="csname">Task Type</span>
                <span className="csvalue">{orderType}</span>
              </li>
              <li>
                <span className="csname">Location</span>
                <span className="csvalue">{task.address}</span>
              </li>
              <li>
                <span className="csname">Planned Date</span>
                <span className="csvalue">
                  {renderOrderDate()}
                </span>
              </li>
              <li>
                <span className="csname">Time Window</span>
                <span className="csvalue">
                  {task.after}-{task.before}
                </span>
              </li>
              <li>
                <span className="csname">Time at stop</span>
                <span className="csvalue">{task.durationTime} min</span>
              </li>
              <li>
                <span className="csname">Capacity</span>
                <span className="csvalue">
                  {task.capacity} {task.capacityUnit}
                </span>
              </li>
              <li>
                <span className="csname">Delivery Cost</span>
                <span className="csvalue">
                  {task.price}
                  {task.priceUnitSymbol}
                </span>
              </li>
              <li>
                <span className="csname">Assigned Org</span>
                <span className="csvalue">{renderMerchantName(task)}</span>
              </li>
              <li>
                <span className="csname">Visibility</span>
                {task.teamIds?.map((i) => {
                  return <span className="csvalueteam">{i.teamName}</span>;
                })}
              </li>
              <li>
                <span className="csname">Notes</span>
                <span className="csvaluenotes">{task.notes}</span>
              </li>
              <li>
                <span className="csname">Doc</span>
              </li>
              <div className="pdfdetail">
                <ul>{renderDocs()}</ul>
              </div>
            </ul>
          </div>
        </div>
        {/* Order Details */}
        <div className="create-settime-duration">
          <h2 className="history">Timeline</h2>
          <div className="history-detail-form">
            <ul>
              {taskHistory.map((i, index) => {
                return (
                  <li key={index}>
                    <div className="hstime">
                      <span
                        className="hsdetail"
                        style={{ borderColor: borderColor(i.status) }}
                      >
                        <span className="hsvalue">
                          {capitalizeFirstLetter(i.status)}
                        </span>
                        <span className="separator">{">"}</span>
                        <span className="datetime datetime1">
                          {moment(i.createdDate).format("DD MMM YYYY HH:mm")}
                        </span>
                        <span className="separator">{"|"}</span>
                        {i.updatedBy &&
                          (i.status === "started" ||
                            i.status === "created" ||
                            i.status === "updated") && (
                            <span className="hsby">{` By ${i.updatedBy.firstName} ${i.updatedBy.lastName}`}</span>
                          )}
                        {i.status === "Picture Before" &&
                          i.picturesBefore &&
                          i.picturesBefore.length && (
                            <span className="hslink">
                              {i.picturesBefore.map((item, i) => {
                                return item.original ? (
                                  <a
                                    href={item.original}
                                    target="_blank"
                                    rel="noreferrer"
                                  >{`Picture ${i + 1}`}</a>
                                ) : null;
                              })}
                            </span>
                          )}
                        {i.status === "Picture After" &&
                          i.picturesAfter &&
                          i.picturesAfter.length && (
                            <span className="hslink">
                              {i.picturesAfter.map((item, i) => {
                                return item.original ? (
                                  <a
                                    href={item.original}
                                    target="_blank"
                                    rel="noreferrer"
                                  >{`Picture ${i + 1}`}</a>
                                ) : null;
                              })}
                            </span>
                          )}
                        {i.status === "reached" &&
                          i.reachedLocation &&
                          i.reachedLocation.location && (
                            <span className="hslink">
                              <a
                                href={
                                  "https://maps.google.com?q=" +
                                  i.reachedLocation.location.lat +
                                  "," +
                                  i.reachedLocation.location.lng
                                }
                                target="_blank"
                                rel="noreferrer"
                              >{`Location`}</a>
                            </span>
                          )}
                        {i.status === "completed" && i.signature && (
                          <span className="hslink">
                            {i.signature.original ? (
                              <a
                                href={i.signature.original}
                                target="_blank"
                                rel="noreferrer"
                              >{`Signature`}</a>
                            ) : null}
                          </span>
                        )}
                        {i.status === "Notes" && (
                          <span className="hsby">{i.agentNotes}</span>
                        )}
                        {i.status === "failed" && i.signature && (
                          <span className="hslink">
                            {i.signature.original ? (
                              <a
                                href={i.signature.original}
                                target="_blank"
                                rel="noreferrer"
                              >{`Signature`}</a>
                            ) : null}
                          </span>
                        )}
                        {i.status === "failed" && i.reason && (
                          <span className="hsby">{`Reason: ${i.reason}`}</span>
                        )}
                        {i.status === "Reason" &&
                          i.failedReason &&
                          i.failedReason.length && (
                            <span className="hsby">
                              {i.failedReason.map((i) => {
                                return ` ${i}`;
                              })}
                            </span>
                          )}
                        {/* {i.status === "failed" && i.failedReason && i.failedReason.length && <span className="hsby">{`Reason:${i.failedReason.map(i => { return ` ${i}` })}`}</span>} */}
                      </span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      {/* create detail history end */}
    </div>
  );
};

export default TaskRightSideDetails;
