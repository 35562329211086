// TODO: Resolve lint issues
/* eslint-disable */
import {
    put,
    takeLatest,
    all,
    call,
} from 'redux-saga/effects'

/********************* ACTION TYPE ********************* */
import { ADD_TASKS_TO_MISSION_API, ASSIGN_MISSION_API, CHANGE_TASK_STATUS_API, CREATE_MISSION_API, CREATE_MULTIPLE_TASKS_API, CREATE_TASK_API, DELETE_MISSION_API, DELETE_TASKS_API, DUPLICATE_MISSION_API, GET_MISSIONS_API, GET_TASKS_API, GET_TASK_ANALYTICS, GET_TASK_HISTORY_API, MAIN_SEARCH_API, OPTIMIZE_MISSION_API, REMOVE_TASKS_API, SAVE_TASK_POSITION_API, SCHEDULE_DELIVERY_API, SET_LINKED_TASKS_API, SUBMIT_LEGAL_DOCS_API, TASK_COUNT_API, UNASSIGN_MISSION_API, UPDATE_MISSION_API, UPDATE_MISSION_DIRECTIONS_API, UPDATE_MULTIPLE_MISSIONS_API, UPDATE_MULTIPLE_TASKS_API } from './actionTypes';

/*************************** ACTIONS *******************/
import { saveDeleteMissionsList, saveDeleteTasksList, saveMissionsList, saveMultiMissionUpdateList, saveRemoveTasksList, saveTaskHistory, saveTasksList } from './actions';
import { apiSagaHandler } from '../network/saga';
import { errorReducerHandler } from '../alert/actions'
import { showWarning } from '../alert/actions';
import { clean, getdateQuery, timeZoneHeader } from '../../helpers/helperFunctions';
import momentTz from 'moment-timezone';
let timezone = { ...timeZoneHeader() };

const API_URL = "task/"
/************************ USER SAGA *******************************/
function* saga() {
    yield all([
        call(createTaskApi),
        call(getTasksListApi),
        call(getMissionsListApi),
        call(createMissionApi),
        call(deleteMissionApi),
        call(deleteTasksApi),
        call(addTasksToMissionApi),
        call(updateMultipleMissionApi),
        call(assignMissionApi),
        call(optimizeMissionApi),
        call(unAssignMissionApi),
        call(removeTasksApi),
        call(changeTaskStatusApi),
        call(setLinkedTasksApi),
        call(mainSearchApi),
        call(saveTaskPostionApi),
        call(submitLegalSignedDocApi),
        call(getTaskHistoryApi),
        call(createMultipleTasksApi),
        call(updateMultipleTasksApi),
        call(updateMissionDirectionApi),
        call(updateMissionApi),
        call(duplicateMissionApi),
        call(scheduleDeliveryApi),
        call(getTaskAnalyticsApi)
    ]);
}

function* getTasksListApi() {
    yield takeLatest(GET_TASKS_API, getTasksListHandler)
}

function* getMissionsListApi() {
    yield takeLatest(GET_MISSIONS_API, getMissionsListHandler)
}

function* createTaskApi() {
    yield takeLatest(CREATE_TASK_API, createTaskHandler)
}

function* createMissionApi() {
    yield takeLatest(CREATE_MISSION_API, createMissionHandler)
}

function* deleteMissionApi() {
    yield takeLatest(DELETE_MISSION_API, deleteMissionHandler)
}

function* deleteTasksApi() {
    yield takeLatest(DELETE_TASKS_API, deleteTasksHandler)
}

function* updateMultipleMissionApi() {
    yield takeLatest(UPDATE_MULTIPLE_MISSIONS_API, updateMultipleMissionHandler)
}

function* addTasksToMissionApi() {
    yield takeLatest(ADD_TASKS_TO_MISSION_API, addTasksToMissionHandler)
}

function* assignMissionApi() {
    yield takeLatest(ASSIGN_MISSION_API, assignMissionHandler)
}

function* optimizeMissionApi() {
    yield takeLatest(OPTIMIZE_MISSION_API, optimizeMissionHandler)
}

function* unAssignMissionApi() {
    yield takeLatest(UNASSIGN_MISSION_API, unAssignMissionHandler)
}

function* removeTasksApi() {
    yield takeLatest(REMOVE_TASKS_API, removeTasksHandler)
}

function* changeTaskStatusApi() {
    yield takeLatest(CHANGE_TASK_STATUS_API, changeTaskStatusHandler)
}

function* setLinkedTasksApi() {
    yield takeLatest(SET_LINKED_TASKS_API, setLinkedTaskHandler)
}

function* mainSearchApi() {
    yield takeLatest(MAIN_SEARCH_API, mainSearchHandler)
}

function* saveTaskPostionApi() {
    yield takeLatest(SAVE_TASK_POSITION_API, saveTaskPostionHandler)
}

function* submitLegalSignedDocApi() {
    yield takeLatest(SUBMIT_LEGAL_DOCS_API, submitLegalSignedDocHandler)
}

function* getTaskHistoryApi() {
    yield takeLatest(GET_TASK_HISTORY_API, getTaskHistoryHandler)
}

function* createMultipleTasksApi() {
    yield takeLatest(CREATE_MULTIPLE_TASKS_API, createMultipleTasksHandler)
}

function* updateMultipleTasksApi() {
    yield takeLatest(UPDATE_MULTIPLE_TASKS_API, updateMultipleTasksHandler)
}
function* updateMissionDirectionApi() {
    yield takeLatest(UPDATE_MISSION_DIRECTIONS_API, updateMissionDirectionHandler)
}
function* updateMissionApi() {
    yield takeLatest(UPDATE_MISSION_API, updateMissionHandler)
}
function* duplicateMissionApi() {
    yield takeLatest(DUPLICATE_MISSION_API, duplicateMissionHandler)
}
function* scheduleDeliveryApi() {
    yield takeLatest(SCHEDULE_DELIVERY_API, scheduleDeliveryHandler)
}
function* getTaskAnalyticsApi() {
    yield takeLatest(GET_TASK_ANALYTICS, getTaskAnalyticsHandler)
}

function* getTasksListHandler(data) {
    let { payload, resCall, saveLimit, auth, saveInRedux } = data?.data;
    if (payload.date) {
        const dateQuery = getdateQuery(payload.date, "orderDate")
        delete payload.date
        payload = { ...payload, ...dateQuery }
    }
    let config = {
        "endpoint": `${API_URL}`,
        "auth": auth,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.saveLimit = saveLimit
        if (saveInRedux) yield put(saveTasksList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* getMissionsListHandler(data) {
    let { payload, resCall, saveLimit, auth, saveInRedux } = data?.data;
    if (payload.date) {
        const dateQuery = getdateQuery(payload.date, "orderDate")
        delete payload.date
        payload = { ...payload, ...dateQuery }
    }
    let config = {
        "endpoint": `${API_URL}missions`,
        "auth": auth,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes) {
        apiRes.saveLimit = saveLimit
        if (saveInRedux) yield put(saveMissionsList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* createTaskHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        currentDate,
        resCall } = data?.data;
    payload = clean(payload)
    if (payload.orderDate) {
        payload.orderDate = momentTz(payload.orderDate).tz(timezone.timezone).startOf("d")._d
    }
    let config = {
        "endpoint": `${API_URL}`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json',
            ...timeZoneHeader()
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        if (!payload.id) apiRes.createApi = true;
        if (payload.id) apiRes.editApi = true;
        apiRes.sortBy = sortBy;
        apiRes.missionId = payload.missionId
        apiRes.currentPage = currentPage || 1;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        apiRes.currentDate = currentDate
        yield put(saveTasksList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* createMissionHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        viewType,
        pageLimit,
        resCall } = data?.data;
    payload = clean(payload)
    if (payload.date) {
        const dateQuery = getdateQuery(payload.date, "missionDate")
        payload = { ...payload, dateQuery: JSON.stringify(dateQuery), missionDate: payload.date }
        // delete payload.date;
        let missionDates = momentTz(payload.date).tz(timezone.timezone).startOf("d")._a
        payload.missionDate = momentTz(missionDates).tz(timezone.timezone).startOf("d")._d
    }
    let config = {
        "endpoint": `${API_URL}createMission`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json',
            ...timeZoneHeader()
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.createApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage || 1;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        apiRes.taskIds = payload.taskIds;
        apiRes.viewType = viewType
        yield put(saveMissionsList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* deleteMissionHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}deleteMission`,
        "auth": true,
        "method": "PUT",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.createApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage || 1;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        apiRes.taskIds = payload.taskIds;
        yield put(saveDeleteMissionsList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* deleteTasksHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}deleteTask`,
        "auth": true,
        "method": "PUT",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.createApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage || 1;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        apiRes.taskIds = payload.taskIds;
        yield put(saveDeleteTasksList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* updateMultipleMissionHandler(data) {
    let { payload, resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}mission/multipleUpdate`,
        "auth": true,
        "method": "PUT",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        yield put(saveMultiMissionUpdateList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* addTasksToMissionHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}addTaskToMission`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.addTaskApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage || 1;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        apiRes.taskIds = payload.taskIds;
        yield put(saveMissionsList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* assignMissionHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}assignMission`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.assignApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage || 1;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        apiRes.taskIds = payload.taskIds;
        yield put(saveMissionsList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* optimizeMissionHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}optimiseMission`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.optimizeApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage || 1;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        apiRes.taskIds = payload.taskIds;
        yield put(saveMissionsList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* unAssignMissionHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}unAssignMission`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.assignApi = true;
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage || 1;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        apiRes.taskIds = payload.taskIds;
        yield put(saveMissionsList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* removeTasksHandler(data) {
    let { payload,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}removeTasksFromMission`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.taskIds = payload.taskIds;
        yield put(saveRemoveTasksList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* changeTaskStatusHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        pageCount,
        pageLimit,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}changeStatus`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.editApi = true;
        yield put(saveTasksList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}


function* setLinkedTaskHandler(data) {
    let { payload,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}setLinkedTasks`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.optimizeApi = true;
        yield put(saveMissionsList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* mainSearchHandler(data) {
    let { payload,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}mainSearch`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
        loading: true,
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* saveTaskPostionHandler(data) {
    let { payload, loading,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}changePosition`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        loading,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.changePositionApi = true;
        yield put(saveMissionsList(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* submitLegalSignedDocHandler(data) {
    let { payload,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}submitLegalSignedDoc`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* getTaskHistoryHandler(data) {
    let { payload,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}getTaskHistory`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json'
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        yield put(saveTaskHistory(apiRes))
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* createMultipleTasksHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        currentDate,
        pageCount,
        pageLimit, userId,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}createMultipleTasks`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json',
            ...timeZoneHeader()
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.createMultiApi = true
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage || 1;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        yield getTasksListHandler({
            data: {
                payload: {
                    date: currentDate,
                    userId: userId,
                    all: true
                },
                auth: true,
                saveInRedux: true
            }
        })
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}
function* updateMultipleTasksHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        orderDate,
        withoutDate,
        pageCount,
        pageLimit, userId, userIds,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}updateMultipleTasks`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json',
            ...timeZoneHeader()
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.createMultiApi = true
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage || 1;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        yield getTasksListHandler({
            data: {
                payload: {
                    orderDate: orderDate,
                    withoutDate: withoutDate,
                    userId: userId,
                    userIds: userIds,
                    limit: pageLimit,
                    all: true
                },
                auth: true,
                saveInRedux: true
            }
        })
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* updateMissionDirectionHandler(data) {
    let { payload,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}mission/updateDirections`,
        "auth": true,
        "method": "PUT",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json',
            ...timeZoneHeader()
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* updateMissionHandler(data) {
    let { payload,
        resCall } = data?.data;
    payload = clean(payload)

    if (payload.missionDate) payload.missionDate = momentTz(payload.missionDate).tz(timezone.timezone)._d
    if (payload.endsOnDate) payload.endsOnDate = momentTz(payload.endsOnDate).tz(timezone.timezone).endOf("d")._d
    let config = {
        "endpoint": `${API_URL}mission/updateMission`,
        "auth": true,
        "method": "PUT",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json',
            ...timeZoneHeader()
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* duplicateMissionHandler(data) {
    let { payload,
        resCall } = data?.data;
    payload = clean(payload)
    delete payload.type
    delete payload.repeatOnDays
    if (payload.missionDate) payload.missionDate = momentTz(payload.missionDate).tz(timezone.timezone)._d
    payload.timezone = timezone.timezone;
    let config = {
        "endpoint": `${API_URL}mission/duplicateMission`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json',
            ...timeZoneHeader()
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* scheduleDeliveryHandler(data) {
    let { payload,
        sortBy,
        currentPage,
        orderDate,
        withoutDate,
        pageCount,
        pageLimit, userId,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}scheduleDelivery`,
        "auth": true,
        "method": "POST",
        "localApi": false,
        body: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json',
            ...timeZoneHeader()
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        apiRes.sortBy = sortBy;
        apiRes.currentPage = currentPage || 1;
        apiRes.pageLimit = pageLimit
        apiRes.pageCount = pageCount
        yield getTasksListHandler({
            data: {
                payload: {
                    orderDate: orderDate,
                    withoutDate: withoutDate,
                    userId: userId,
                    limit: pageLimit,
                    all: true
                },
                auth: true,
                saveInRedux: true
            }
        })
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

function* getTaskAnalyticsHandler(data) {
    let { payload,
        resCall } = data?.data;
    payload = clean(payload)
    let config = {
        "endpoint": `${API_URL}getTaskAnalytics`,
        "auth": true,
        "method": "GET",
        "localApi": false,
        params: {
            ...payload
        },
        headers: {
            'Content-Type': 'application/json',
            ...timeZoneHeader()
        },
    }

    const apiRes = yield apiSagaHandler(config)
    if (apiRes && apiRes.success) {
        if (resCall) resCall(apiRes)
    }
    else {
        yield put(errorReducerHandler(apiRes));
    }
}

export default saga;
