import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Card, Row, Col, Button, FormControl, InputGroup, Dropdown, Modal, Container } from 'react-bootstrap';
import './Login.scss';
import logo from '../../assets/image/logo.png';
import passwordicon from '../../assets/image/password.png';
import mailicons from '../../assets/image/mailicons.png';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login, resetMessage } from "../../slices/auth";
import { clearMessage } from "../../slices/message";
import { successAlert, errorAlert, infoAlert } from '../../services/toast.service';
import { ToastContainer } from 'react-toastify';
import AuthService from "../../services/auth.service";


const ResetPassword = (props) => {

  const initialValues = {
    password: ""
  };

  const { token } = useParams();

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required').min(8, 'Password is too short - should be 8 chars minimum.'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const handleSubmit = async (formValue) => {
    console.log("formValue", formValue)
    delete formValue.passwordConfirmation
    formValue.token = token
    // check email is already taken or not 
    const response = await AuthService.setPassword(formValue)
    console.log(response, "response");
    if (response && response.data && response.data.success) {
      successAlert(
        response.data.message
          ? response.data.message
          : "Password updated successfully !"
      );
      localStorage.removeItem("user");
      setTimeout(() => {
        props.history.push("/login");
      }, 2000);
    } else {
      errorAlert(
        response.data.message
          ? response.data.message
          : "Something Went Wrong !"
      );
    }


  }

  return (
    <>
      <div>
        <div className="signin-form">
          <div className="logo-bar">
            <img src={logo} alt="logo" />
            <h1 className="headingpassword">Create new password</h1>
          </div>
          <div className="signup-form-box password-formbox">
            <p>We'll ask for this password whenever you sign in.</p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              <Form inline="true" >
                <Row>
                  <Col xs={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="password"><img src={mailicons} alt="emailicon" /></InputGroup.Text>
                      <Field className="form-control" placeholder="New Password" type="password" name="password" aria-label="New password" />
                      <ErrorMessage name="password" component="div" />
                    </InputGroup>

                  </Col>
                  <Col xs={12}>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="passwordagain"><img src={passwordicon} alt="passwordicon" /></InputGroup.Text>
                      <Field className="form-control" placeholder="Password again" name="passwordConfirmation" type="password" aria-label="Password" />
                      <ErrorMessage name="passwordConfirmation" component="div" />
                    </InputGroup>

                  </Col>
                  <Col xs={12}>
                    <div className="continue-btn">
                      <Button variant="primary" type="submit">
                        Save Changes
                      </Button>
                    </div>
                    <div className="recapcta-box">
                      <p>This site is protected by reCAPTCHA and the Google Privacy Policy.</p>
                      <p className="dha-text">Don’t have an account?  <Link to={'/signup'}>Sign up</Link></p>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <ToastContainer
        style={{ color: "black" }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );





};

export default ResetPassword;