import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Button,
  NavDropdown,
} from "react-bootstrap";
import "./Settings.scss";
import { getProfile } from "../../slices/auth";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import builderpopupimg from "../../assets/image/builderppimg.png";
import Union from "../../assets/image/Union.png";
import Plus from "../../assets/image/plus.png";
import downloadicon from "../../assets/image/downloadicon.png";
import ShortIcon from "../../assets/image/filter-icon.png";
import filterremoveicon from "../../assets/image/filter-removeicon.png";
import closeicon from "../../assets/image/close-icon.png";
import roleicon from "../../assets/image/roleicon.png";
import nameicon from "../../assets/image/nameicon.png";
import mailicon from "../../assets/image/mailicon.png";
import industryicon from "../../assets/image/industryicon.png";
import countryicon from "../../assets/image/countryicon.png";
import companyicon from "../../assets/image/companyicon.png";
import phoneicon from "../../assets/image/phoneicon.png";
import websiteicon from "../../assets/image/websiteicon.png";
import stateicon from "../../assets/image/stateicon.png";
import ContentLayout from "../../components/contentlayout";
import teamicon from "../../assets/image/teamicon.png";
import { Formik, Field, ErrorMessage, Form as FormF } from "formik";
import { ToastContainer } from "react-toastify";
import { errorAlert, successAlert } from "../../services/toast.service";
import authService from "../../services/auth.service";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Hub from "../hub/hub";
import Users from "../Users/user";
import Subscription from "./subscription";
import Transactions from "./transactions";
import Extensions from "./extensions";
import Notifications from "./Notifications";
import ScheduleDeliveryTab from "./ScheduleDelivery";
import TaskMissionTab from "./TaskMissionTab";
import RegionalSettingTab from "./RegionalSettingTab";
import AgentAppTab from "./AgentAppTab";
import GroupsTab from "./GroupsTab";
import LegalProtectionTab from "./LegalProtectionTab";
import OperationCommunicationsTab from "./OperationCommunicationsTab";

import "react-tabs/style/react-tabs.css";
import ExperienceCommunicationsTab from "./ExperienceCommunicationsTab";
import PerformanceTab from "./PerformanceTab";
import ApiKeysTab from "./ApiKeysTab";
import defaultMessages from "./defaultMessagesNotification.json";
import defaultSectionsDeliveryConfirmation from "./defaultSectionsDeliveryConfirmation.json";
import defaultSectionsResponsibility from "./defaultSectionsResponsibility.json";
import PaymentMethod from "./PaymentMethod";
import BillingContacts from "./BillingContacts";

const settingTypes = {
  CUSTOMER_EXPERIENCE: "CUSTOMER_EXPERIENCE",
  ADVANCE_SETTINGS: "ADVANCE_SETTINGS",
  OPERATIONS: "OPERATIONS",
};
let initialCustomizeValue = {
  image: {
    organizationLogo: false,
    driverPhoto: false,
    none: false,
  },
  information: {
    driverName: false,
    vehicleDescription: false,
    destinationAddress: false,
  },
  communication: {
    call: false,
    message: false,
    enableFeedback: false,
  },
};
let initialLandingValue = {
  url: "",
  landingPageImage: null,
};

const commonServicesTypes = {
  RESPONSIBILITY_DISCHARGE: "RESPONSIBILITY_DISCHARGE",
  CONFIRMATION_DELIVERY: "CONFIRMATION_DELIVERY",
  CUSTOMER_EXPERIENCE_NOTIFICATIONS: "CUSTOMER_EXPERIENCE_NOTIFICATIONS",
  CUSTOMER_EXPERIENCE_COMMUNICATIONS: "CUSTOMER_EXPERIENCE_COMMUNICATIONS",
  COMMON_RESPONSIBILITY_DISCHARGE: "COMMON_RESPONSIBILITY_DISCHARGE",
  COMMON_CONFIRMATION_DELIVERY: "COMMON_CONFIRMATION_DELIVERY",
  COMMON_CUSTOMER_EXPERIENCE_NOTIFICATIONS:
    "COMMON_CUSTOMER_EXPERIENCE_NOTIFICATIONS",
  COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS:
    "COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS",
};

const settingSubType = {
  SCHEDULE_DELIVERY: "SCHEDULE_DELIVERY",
  TASK_AND_MISSION: "TASK_AND_MISSION",
  AGENT_APP: "AGENT_APP",
  GROUPS: "GROUPS",
  COMMUNICATIONS: "COMMUNICATIONS",
  NOTIFICATIONS: "NOTIFICATIONS",
  API_KEY: "API_KEY",
  PERFORMANCE: "PERFORMANCE",
  REGIONAL_SETTINGS: "REGIONAL_SETTINGS",
  LEGAL_PROTECTION: "LEGAL_PROTECTION",

  COMMON_SCHEDULE_DELIVERY: "COMMON_SCHEDULE_DELIVERY",
  COMMON_TASK_AND_MISSION: "COMMON_TASK_AND_MISSION",
  COMMON_AGENT_APP: "COMMON_AGENT_APP",
  COMMON_GROUPS: "COMMON_GROUPS",
  COMMON_COMMUNICATIONS: "COMMON_COMMUNICATIONS",
  COMMON_NOTIFICATIONS: "COMMON_NOTIFICATIONS",
  COMMON_API_KEY: "COMMON_API_KEY",
  COMMON_PERFORMANCE: "COMMON_PERFORMANCE",
  COMMON_REGIONAL_SETTINGS: "COMMON_REGIONAL_SETTINGS",
  COMMON_LEGAL_PROTECTION: "COMMON_LEGAL_PROTECTION",
};

const initialValues = {
  logo: {
    original: "",
    thumbnail: "",
    processed: "",
    fileName: "",
  },
  companyName: "",
  email: "",
  firstName: "",
  lastName: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  webSite: "",
  addressLine1: "",
  addressLine2: "",
  companyEmail: "",
  apartment: "",
};

const initialValueSettings = {
  deliverySendTrackingLink: false,
  deliveryAddALangingPage: false,
  deliverySendProofOfDelivery: false,
  deliveryAgentToInitiateMessage: false,
  deliveryAnonymizeAgentPhoneNumber: false,
  deliveryAgentStartedMission: false,
  deliveryAgentStartedTask: false,
  deliveryAgentArrived: false,
  deliverySuccessFulTask: false,
  deliveryFailedTask: false,
  installationSendTrackingLink: false,
  installationAddALangingPage: false,
  installationSendProofOfDelivery: false,
  installationAgentToInitiateMessage: false,
  installationAnonymizeAgentPhoneNumber: false,
  installationAgentStartedMission: false,
  installationAgentStartedTask: false,
  installationAgentArrived: false,
  installationSuccessFulTask: false,
  installationFailedTask: false,
  defaultTaskDuration: 10,
  defaultTaskDurationUnit: "min",
  defaultTaskCapacity: 20,
  defaultTaskCapacityUnit: "min",
  defaultInstallationTime: 45,
  defaultInstallationTimeUnit: "min",
  defaultDelayTime: 10,
  defaultDelayTimeUnit: "min",
  documents: [],
  autoArrivalDistance: 0,
  autoArrivalDistanceUnit: "min",
  taskDelayAlert: 0,
  taskDelayAlertUnit: "min",
  distanceUnit: 0,
  weightUnit: "",
  volumeUnit: "",
  notes: false,
  signature: false,
  photos: false,
  defaultBeforeTime: "",
  defaultAfterTime: "05:18",
  defaultScheduleTime: "",
  defaultBeforeSeconds: "",
  defaultAfterSeconds: "",
  defaultScheduleTimeSeconds: "",
  defaultBeforeTimePicker: "",
  defaultScheduleTimePicker: "",
  defaultAfterTimePicker: "",
  responsibilityDischarge: false,
  confirmationDeliveryForm: false,
  photosBeforeAndAfter: false,
  pictureAssessment: false,
  defaultMaxTaskPerMission: 12,
  defaultMaxTaskPerMissionUnit: "min",
  defaultMaxMissionDuration: 600,
  defaultMaxMissionDurationUnit: "min",
  defaultMaxMissionCapacity: 27,
  defaultMaxMissionCapacityUnit: "min",
  eventName: "",
  eventDescription: "",
  addNotes: false,
  requestAsssembleorInstallation: false,
  chooseTimeWindow: false,
  collectPayment: false,
  customerCannotScheduleWithin: null,
  dailyAvailableSlots: [
    { name: "MONDAY", day: 1, slots: null },
    { name: "TUESDAY", day: 2, slots: null },
    { name: "WEDNESDAY", day: 3, slots: null },
    { name: "THURSDAY", day: 4, slots: null },
    { name: "FRIDAY", day: 5, slots: null },
    { name: "SATURDAY", day: 6, slots: null },
    { name: "SUNDAY", day: 0, slots: null },
  ],
  type: "",
  subType: "",
  defaultPickup: 10,
  defaultDrop: 10,
  defaultDropService: 45,
  defaultReturn: 20,
  defaultExchange: 45,
  defaultService: 10,
  defaultTaskVolume: 1.5,
  defaultTaskDelayAlert: 10,
};

const initialValueCommonServices = {
  deliveryAgentStartedMission: defaultMessages.deliveryAgentStartedMission,
  deliveryAgentStartedTask: defaultMessages.deliveryAgentStartedTask,
  deliveryAgentArrived: defaultMessages.deliveryAgentArrived,
  deliverySuccessFulTask: defaultMessages.deliverySuccessFulTask,
  deliveryFailedTask: defaultMessages.deliverySuccessFulTask,
  deliveryCustomizeTrackingPage: {},
  deliveryAddLinkToLandingPage: {},
  deliveryProofOfDelivery: {},
  installationCustomizeTrackingPage: {},
  installationAddLinkToLandingPage: {},
  installationProofOfDelivery: {},
  section: [],
  signature: {},
};

const Settings = () => {
  const locationHook = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.auth.user);
  const [createCustomer, setcreateCustomer] = useState("false");
  const [userLogo, setlogo] = useState(false);
  const [showScheduleSettings, setShowScheduleSettings] = useState(false);
  const createCustomerToggle = () => {
    setcreateCustomer(!createCustomer);
  };
  let roleBasedDisabled = false;
  if (userData.role === 2 && userData.userId) {
    roleBasedDisabled = true;
  }
  const addLogoToggle = () => {
    if (!roleBasedDisabled) setlogo(!userLogo);
  };

  const [features, setFeatures] = useState({});

  const defaultSubscriptionData = useSelector(
    (state) => state.defaultSubscription
  );
  const extensionData = useSelector((state) => state.extensions);

  useEffect(() => {
    extensionData &&
      extensionData.extension &&
      extensionData.extension.length &&
      extensionData.extension.map((data) => {
        if (data.isEnabled) {
          data.userExtensions.map((extension) => {
            setFeatures((prev) => {
              return {
                ...prev,
                ...extension.features,
                additionalTaskNumber: Number(
                  extension.features.additionalTaskNumber
                ),
              };
            });
          });
        }
      });
  }, [extensionData]);

  useEffect(() => {
    if (
      defaultSubscriptionData &&
      defaultSubscriptionData.extension &&
      defaultSubscriptionData.extension.data &&
      defaultSubscriptionData.extension.data.results.length
    ) {
      setFeatures((prev) => {
        return {
          ...prev,
          ...defaultSubscriptionData.extension.data.results[0].features,
        };
      });
    }
  }, [defaultSubscriptionData]);

  const [selectedMainTab, setSelectedMainTab] = useState(0);
  const [afterOpen, setAfterOpen] = useState(false);
  const [beforeOpen, setBeforeOpen] = useState(false);
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const [selectedSubTab, setSelectedSubTab] = useState(0);
  const [settingData, setSettingData] = useState([]);
  const [commonServicesData, setCommonServicesData] = useState([]);

  const [customizeTrackingPage, setCustomizeTrackingPage] = useState(
    initialCustomizeValue
  );
  const [addLinkToLandingPage, setAddLinkToLandingPage] =
    useState(initialLandingValue);
  const [sections, setSections] = useState([]);
  const [signature, setSignature] = useState(null);
  const [formData, setFormData] = useState(initialValues);
  const [settingFormData, setSettingFormData] = useState(initialValueSettings);
  const [commonServicesFormData, setCommonServicesFormData] = useState(
    initialValueCommonServices
  );

  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    let user = Object.assign({}, userData);
    if (user.role !== 1 && user.userId) {
      user = user.user;
    }
    setUserInfo(user);
    let formData = {
      logo:
        user.logo && user.logo.original
          ? user.logo
          : {
            original: "",
            thumbnail: "",
            processed: "",
            fileName: "",
          },
      companyName: user.companyName ? user.companyName : "",
      email: user.email ? user.email : "",
      firstName: user.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      country: user.country ? user.country : "",
      state: user.state ? user.state : "",
      city: user.city ? user.city : "",
      zipCode: user.zipCode ? user.zipCode : "",
      webSite: user.webSite ? user.webSite : "",
      addressLine1: user.addressLine1 ? user.addressLine1 : "",
      addressLine2: user.addressLine2 ? user.addressLine2 : "",
      companyEmail: user.companyEmail ? user.companyEmail : "",
      apartment: user.apartment ? user.apartment : "",
      phoneNumber: user.phoneNumber ? user.phoneNumber : "",
      address: user.address ? user.address : "",
    };
    setFormData(formData);
    getSetting();
    getCommonServices();
  }, []);

  const getSetting = async () => {
    let params = {
      userId: userData.id,
      subType: [
        settingSubType.AGENT_APP,
        settingSubType.API_KEY,
        settingSubType.COMMUNICATIONS,
        settingSubType.GROUPS,
        settingSubType.LEGAL_PROTECTION,
        settingSubType.NOTIFICATIONS,
        settingSubType.PERFORMANCE,
        settingSubType.REGIONAL_SETTINGS,
        settingSubType.TASK_AND_MISSION,
        settingSubType.SCHEDULE_DELIVERY,
      ],
    };
    if (userData.role === 1 && !userData.subAdmin) {
      delete params.userId;
      params.subType = [
        settingSubType.COMMON_AGENT_APP,
        settingSubType.COMMON_API_KEY,
        settingSubType.COMMON_COMMUNICATIONS,
        settingSubType.COMMON_GROUPS,
        settingSubType.COMMON_LEGAL_PROTECTION,
        settingSubType.COMMON_NOTIFICATIONS,
        settingSubType.COMMON_PERFORMANCE,
        settingSubType.COMMON_REGIONAL_SETTINGS,
        settingSubType.COMMON_TASK_AND_MISSION,
        settingSubType.COMMON_SCHEDULE_DELIVERY,
      ];
    }
    let response = await authService.getSettings(params);
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.results.length
    ) {
      setSettingData(response.data.data.results)
    }
  };

  const getCommonServices = async () => {
    let params = {
      userId: userData.id,
      type: [
        commonServicesTypes.CONFIRMATION_DELIVERY,
        commonServicesTypes.CUSTOMER_EXPERIENCE_COMMUNICATIONS,
        commonServicesTypes.CUSTOMER_EXPERIENCE_NOTIFICATIONS,
        commonServicesTypes.RESPONSIBILITY_DISCHARGE,
      ],
    };
    if (userData.role === 1 && !userData.subAdmin) {
      params.type = [
        commonServicesTypes.COMMON_CONFIRMATION_DELIVERY,
        commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_NOTIFICATIONS,
        commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS,
        commonServicesTypes.COMMON_RESPONSIBILITY_DISCHARGE,
      ];
    }
    if (userData.role === 1 && !userData.subAdmin) {
      delete params.userId;
    }
    let response = await authService.getCommonServices(params);
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.results.length
    ) {
      setCommonServicesData(response.data.data.results);
    }
  };

  function username() {
    return <span>Large items Mtl</span>;
  }
  function group() {
    return <span>Salim Bouafia</span>;
  }
  function Access() {
    return <span>Super Admin </span>;
  }
  function Email() {
    return <span>alex.charboneau@ikea.ca</span>;
  }
  function Phone() {
    return <span>+1 (514) 649 - 2141 </span>;
  }

  const [customerActive, setcustomerActive] = useState("false");

  const customerActiveToggle = () => {
    setcustomerActive(!customerActive);
  };

  const resetFormData = async () => {
    let user = Object.assign({}, userData);
    if (user.userId) {
      user = user.user;
    }
    setUserInfo(user);
    let formData = {
      logo:
        user.logo && user.logo.original
          ? user.logo
          : {
            original: "",
            thumbnail: "",
            processed: "",
            fileName: "",
          },
      companyName: user.companyName ? user.companyName : "",
      email: user.email ? user.email : "",
      firstName: user.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      country: user.country ? user.country : "",
      state: user.state ? user.state : "",
      city: user.city ? user.city : "",
      zipCode: user.zipCode ? user.zipCode : "",
      webSite: user.webSite ? user.webSite : "",
      addressLine1: user.addressLine1 ? user.addressLine1 : "",
      addressLine2: user.addressLine2 ? user.addressLine2 : "",
      companyEmail: user.companyEmail ? user.companyEmail : "",
      apartment: user.apartment ? user.apartment : "",
      address: user.address ? user.address : "",
      phoneNumber: user.phoneNumber ? user.phoneNumber : "",
    };
    setFormData(formData);
  };
  const updateProfileHandler = async (formValues) => {
    formValues.logo = formData.logo;
    let response = await authService.updateProfile(formValues);
    if (response && response.data && response.data.user) {
      // response.data.user.token = userInfo.token;
      localStorage.setItem("user", JSON.stringify(response.data.user));
      setUserInfo(response.data.user);
      dispatch(getProfile({ userId: userData.id }));
      let user = response.data.user;
      if (user.userId) {
        user = user.user;
      }
      let formData = {
        logo: user.logo
          ? user.logo
          : {
            original: "",
            thumbnail: "",
            processed: "",
            fileName: "",
          },
        companyName: user.companyName ? user.companyName : "",
        email: user.email ? user.email : "",
        firstName: user.firstName ? user.firstName : "",
        lastName: user.lastName ? user.lastName : "",
        country: user.country ? user.country : "",
        state: user.state ? user.state : "",
        city: user.city ? user.city : "",
        zipCode: user.zipCode ? user.zipCode : "",
        webSite: user.webSite ? user.webSite : "",
        addressLine1: user.addressLine1 ? user.addressLine1 : "",
        addressLine2: user.addressLine2 ? user.addressLine2 : "",
        companyEmail: user.companyEmail ? user.companyEmail : "",
        apartment: user.apartment ? user.apartment : "",
        address: user.address ? user.address : "",
        phoneNumber: user.phoneNumber ? user.phoneNumber : "",
      };
      setFormData(formData);
      successAlert("Information changed successfully");
    } else {
      errorAlert(
        response.data.message ? response.data.message : "Something went wrong"
      );
    }
  };

  const Remove = (cell, row) => {
    return (
      <>
        <span>
          <span className="filter-edit">
            <NavDropdown
              title={<img src={filterremoveicon} alt={"dropdownimage"} />}
              id="nav-dropdown"
            >
              <NavDropdown.Item eventKey="4.1">Edit</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.2">Delete</NavDropdown.Item>
            </NavDropdown>
          </span>
        </span>
      </>
    );
  };
  const selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
    bgColor: "rgb(238, 193, 213)",
  };

  const userlist = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];
  const [useropen, setuserOpen] = React.useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputSettingValue, setInputSettingValue] = useState(null);

  const [isSearching, setIsSearching] = useState(false);
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(inputValue, 500);
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        handleChangeSetting();
      } else {
        setIsSearching(false);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );
  useEffect(() => {
    if (locationHook && locationHook.state?.createHub) {
      setSelectedSubTab(1);
    }
    if (locationHook && locationHook.state?.billingTab) {
      setSelectedSubTab(3)
    }
  }, []);

  const handleDrawerOpen = () => {
    setuserOpen(true);
  };
  const handleDrawerClose = () => {
    setuserOpen(false);
  };
  const [bulkOpened, setbulkOpened] = useState(false);

  function bulktoggle() {
    setbulkOpened((wasOpened) => !wasOpened);
  }

  const handleChangeSetting = async (e, data) => {
    let inputData = inputSettingValue;
    if (data) inputData = data;
    let dataForApi = {
      type: inputData.type,
      subType: inputData.subType,
    };
    if (inputData.key && inputData.key.length) {
      for (let i of inputData.key) {
        dataForApi = { ...dataForApi, ...i };
      }
    }
    let response = await authService.saveSettings(dataForApi);

    if (response && response.data && response.data.data && response.data.data) {
      let newSettingData = JSON.parse(JSON.stringify(settingData));
      let findIndex = newSettingData
        .map((i) => i.subType)
        .indexOf(response.data.data.subType);
      if (findIndex > -1) {
        newSettingData[findIndex] = response.data.data;
      } else {
        newSettingData.push(response.data.data);
      }
      setSettingData(newSettingData);
      let findTabData = newSettingData.find((i) => {
        return (
          i.type === response.data.data.type &&
          i.subType === response.data.data.subType
        );
      });
      setSettingFormData(findTabData);
    }
  };

  const saveCommonServiceData = async (data, type) => {
    let dataForApi = {
      type,
      ...data,
    };
    let response = await authService.saveCommonServices(dataForApi);
    if (response && response.data && response.data.data && response.data.data) {
      let newServicesData = JSON.parse(JSON.stringify(commonServicesData));
      let findIndex = newServicesData
        .map((i) => i.type)
        .indexOf(response.data.data.type);
      if (findIndex > -1) {
        newServicesData[findIndex] = response.data.data;
      } else {
        newServicesData.push(response.data.data);
      }
      console.log("newServicesData", newServicesData);
      setCommonServicesData(newServicesData);
      console.log(newServicesData[findIndex]);
      setCommonServicesFormData(newServicesData[findIndex]);
    }
  };

  const handleSetSettingFormV2 = (currentMainTab, currentSubTab, type) => {
    let subTab = 0;
    let mainTab = 0;
    if (currentMainTab) mainTab = currentMainTab;
    if (currentSubTab) subTab = currentSubTab;
    if (type) {
      let findServiceData = commonServicesData.find((i) => i.type === type);
      if (findServiceData) {
        if (
          type === commonServicesTypes.RESPONSIBILITY_DISCHARGE ||
          type === commonServicesTypes.COMMON_RESPONSIBILITY_DISCHARGE ||
          type === commonServicesTypes.CONFIRMATION_DELIVERY ||
          type === commonServicesTypes.COMMON_CONFIRMATION_DELIVERY
        ) {
          setSections(findServiceData.sections);
          setSignature(findServiceData.signature);
        }
        if (
          type ===
          commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS ||
          type === commonServicesTypes.CUSTOMER_EXPERIENCE_COMMUNICATIONS
        ) {
          if (findServiceData.deliveryCustomizeTrackingPage)
            setCustomizeTrackingPage(
              findServiceData.deliveryCustomizeTrackingPage
            );
          setAddLinkToLandingPage(
            findServiceData.deliveryAddLinkToLandingPage || initialLandingValue
          );
        }
        setCommonServicesFormData(findServiceData);
      } else {
        if (
          type === commonServicesTypes.RESPONSIBILITY_DISCHARGE ||
          type === commonServicesTypes.COMMON_RESPONSIBILITY_DISCHARGE
        ) {
          setSections(defaultSectionsResponsibility.sections);
          setSignature(defaultSectionsResponsibility.signature);
        } else if (
          type === commonServicesTypes.CONFIRMATION_DELIVERY ||
          type === commonServicesTypes.COMMON_CONFIRMATION_DELIVERY
        ) {
          setSections(defaultSectionsDeliveryConfirmation.sections);
          setSignature(defaultSectionsDeliveryConfirmation.signature);
        }
      }
    } else {
      if (userData.role !== 1 || userData.subAdmin) {
        // Operation + Task & Mission
        if (mainTab === 1 && subTab === 0) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.SCHEDULE_DELIVERY
            );
          });
          if (findTabData) {
            if (
              !findTabData.dailyAvailableSlots ||
              findTabData.dailyAvailableSlots.length === 0
            ) {
              findTabData.dailyAvailableSlots =
                settingFormData.dailyAvailableSlots;
            }
            setSettingFormData(findTabData);
          }
        } else if (mainTab === 1 && subTab === 1) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.TASK_AND_MISSION
            );
          });
          let findRegionalTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.REGIONAL_SETTINGS
            );
          });
          console.log("findRegionalTabData", findRegionalTabData)
          if (findRegionalTabData) {
            findTabData.timeFormat = findRegionalTabData.timeFormat
          }
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Regional Setting
        else if (mainTab === 1 && subTab === 2) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.REGIONAL_SETTINGS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Agent app
        else if (mainTab === 1 && subTab === 3) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.AGENT_APP
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Groups
        else if (
          mainTab === 1 &&
          userInfo &&
          userInfo.role === 6 &&
          subTab === 4
        ) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.GROUPS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Legal Protection
        else if (
          mainTab === 1 &&
          ((userInfo && userInfo.role === 6 && subTab === 5) || subTab === 4)
        ) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.LEGAL_PROTECTION
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Communication
        else if (mainTab === 1 && subTab === 6) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMUNICATIONS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Customer Experience + Notifications
        else if (mainTab === 2 && subTab === 0) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.CUSTOMER_EXPERIENCE &&
              i.subType === settingSubType.NOTIFICATIONS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
          let findServiceData = commonServicesData.find(
            (i) =>
              i.type === commonServicesTypes.CUSTOMER_EXPERIENCE_NOTIFICATIONS
          );
          if (findServiceData) setCommonServicesFormData(findServiceData);
        }
        // Customer Experience + Communication
        else if (mainTab === 2 && subTab === 1) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.CUSTOMER_EXPERIENCE &&
              i.subType === settingSubType.COMMUNICATIONS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
          let findServiceData = commonServicesData.find(
            (i) =>
              i.type === commonServicesTypes.CUSTOMER_EXPERIENCE_COMMUNICATIONS
          );
          if (findServiceData) setCommonServicesFormData(findServiceData);
        }
      } else {
        // Operation + Schedule
        if (mainTab === 1 && subTab === 0) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_SCHEDULE_DELIVERY
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }

        // Operation + Task & Mission
        else if (mainTab === 1 && subTab === 1) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_TASK_AND_MISSION
            );
          });
          let findRegionalTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_REGIONAL_SETTINGS
            );
          });
          if (findRegionalTabData) {
            findTabData.timeFormat = findRegionalTabData.timeFormat
          }
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Regional Setting
        else if (mainTab === 1 && subTab === 2) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_REGIONAL_SETTINGS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Agent app
        else if (mainTab === 1 && subTab === 3) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_AGENT_APP
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Groups
        else if (
          mainTab === 1 &&
          userInfo &&
          userInfo.role === 6 &&
          subTab === 4
        ) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_GROUPS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Legal Protection
        else if (
          mainTab === 1 &&
          ((userInfo && userInfo.role === 6 && subTab === 5) || subTab === 4)
        ) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_LEGAL_PROTECTION
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Communication
        else if (mainTab === 1 && subTab === 6) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_COMMUNICATIONS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Customer Experience + Notifications
        else if (mainTab === 2 && subTab === 0) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.CUSTOMER_EXPERIENCE &&
              i.subType === settingSubType.COMMON_NOTIFICATIONS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
          let findServiceData = commonServicesData.find(
            (i) =>
              i.type ===
              commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_NOTIFICATIONS
          );
          if (findServiceData) setCommonServicesFormData(findServiceData);
        }
        // Customer Experience + Communication
        else if (mainTab === 2 && subTab === 1) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.CUSTOMER_EXPERIENCE &&
              i.subType === settingSubType.COMMON_COMMUNICATIONS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
          let findServiceData = commonServicesData.find(
            (i) =>
              i.type ===
              commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS
          );
          if (findServiceData) setCommonServicesFormData(findServiceData);
        }
      }
    }
  };
  const handleSetSettingForm = (currentMainTab, currentSubTab, type) => {
    let subTab = 0;
    let mainTab = 0;
    if (currentMainTab) mainTab = currentMainTab;
    if (currentSubTab) subTab = currentSubTab;
    if (type) {
      let findServiceData = commonServicesData.find((i) => i.type === type);
      if (findServiceData) {
        if (
          type === commonServicesTypes.RESPONSIBILITY_DISCHARGE ||
          type === commonServicesTypes.COMMON_RESPONSIBILITY_DISCHARGE ||
          type === commonServicesTypes.CONFIRMATION_DELIVERY ||
          type === commonServicesTypes.COMMON_CONFIRMATION_DELIVERY
        ) {
          setSections(findServiceData.sections);
          setSignature(findServiceData.signature);
        }
        if (
          type ===
          commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS ||
          type === commonServicesTypes.CUSTOMER_EXPERIENCE_COMMUNICATIONS
        ) {
          if (findServiceData.deliveryCustomizeTrackingPage)
            setCustomizeTrackingPage(
              findServiceData.deliveryCustomizeTrackingPage
            );
          setAddLinkToLandingPage(
            findServiceData.deliveryAddLinkToLandingPage || initialLandingValue
          );
        }
        setCommonServicesFormData(findServiceData);
      } else {
        if (
          type === commonServicesTypes.RESPONSIBILITY_DISCHARGE ||
          type === commonServicesTypes.COMMON_RESPONSIBILITY_DISCHARGE
        ) {
          setSections(defaultSectionsResponsibility.sections);
          setSignature(defaultSectionsResponsibility.signature);
        } else if (
          type === commonServicesTypes.CONFIRMATION_DELIVERY ||
          type === commonServicesTypes.COMMON_CONFIRMATION_DELIVERY
        ) {
          setSections(defaultSectionsDeliveryConfirmation.sections);
          setSignature(defaultSectionsDeliveryConfirmation.signature);
        }
      }
    } else {
      if (userData.role !== 1 || userData.subAdmin) {
        // Operation + Task & Mission
        if (mainTab === 1 && subTab === 0) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.SCHEDULE_DELIVERY
            );
          });
          if (findTabData) {
            if (
              !findTabData.dailyAvailableSlots ||
              findTabData.dailyAvailableSlots.length === 0
            ) {
              findTabData.dailyAvailableSlots =
                settingFormData.dailyAvailableSlots;
            }
            setSettingFormData(findTabData);
          }
        } else if (mainTab === 1 && subTab === 1) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.TASK_AND_MISSION
            );
          });
          let findRegionalTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.REGIONAL_SETTINGS
            );
          });
          if (findRegionalTabData) {
            findTabData.timeFormat = findRegionalTabData.timeFormat
          }
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Regional Setting
        else if (mainTab === 1 && subTab === 2) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.REGIONAL_SETTINGS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Agent app
        else if (mainTab === 1 && subTab === 3) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.AGENT_APP
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Groups
        else if (
          mainTab === 1 &&
          userInfo &&
          userInfo.role === 6 &&
          subTab === 4
        ) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.GROUPS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Legal Protection
        else if (
          mainTab === 1 &&
          ((userInfo && userInfo.role === 6 && subTab === 5) || subTab === 4)
        ) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.LEGAL_PROTECTION
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Communication
        else if (mainTab === 1 && subTab === 6) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMUNICATIONS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Customer Experience + Notifications
        else if (mainTab === 2 && subTab === 0) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.CUSTOMER_EXPERIENCE &&
              i.subType === settingSubType.NOTIFICATIONS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
          let findServiceData = commonServicesData.find(
            (i) =>
              i.type === commonServicesTypes.CUSTOMER_EXPERIENCE_NOTIFICATIONS
          );
          if (findServiceData) setCommonServicesFormData(findServiceData);
        }
        // Customer Experience + Communication
        else if (mainTab === 2 && subTab === 1) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.CUSTOMER_EXPERIENCE &&
              i.subType === settingSubType.COMMUNICATIONS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
          let findServiceData = commonServicesData.find(
            (i) =>
              i.type === commonServicesTypes.CUSTOMER_EXPERIENCE_COMMUNICATIONS
          );
          if (findServiceData) setCommonServicesFormData(findServiceData);
        }
      } else {
        // Operation + Schedule
        if (mainTab === 1 && subTab === 0) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_SCHEDULE_DELIVERY
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }

        // Operation + Task & Mission
        else if (mainTab === 1 && subTab === 1) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_TASK_AND_MISSION
            );
          });
          let findRegionalTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_REGIONAL_SETTINGS
            );
          });
          if (findRegionalTabData) {
            findTabData.timeFormat = findRegionalTabData.timeFormat
          }
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Regional Setting
        else if (mainTab === 1 && subTab === 2) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_REGIONAL_SETTINGS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Agent app
        else if (mainTab === 1 && subTab === 3) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_AGENT_APP
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Groups
        else if (
          mainTab === 1 &&
          userInfo &&
          userInfo.role === 6 &&
          subTab === 4
        ) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_GROUPS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Legal Protection
        else if (
          mainTab === 1 &&
          ((userInfo && userInfo.role === 6 && subTab === 5) || subTab === 4)
        ) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_LEGAL_PROTECTION
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Operation + Communication
        else if (mainTab === 1 && subTab === 6) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.OPERATIONS &&
              i.subType === settingSubType.COMMON_COMMUNICATIONS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
        }
        // Customer Experience + Notifications
        else if (mainTab === 2 && subTab === 0) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.CUSTOMER_EXPERIENCE &&
              i.subType === settingSubType.COMMON_NOTIFICATIONS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
          let findServiceData = commonServicesData.find(
            (i) =>
              i.type ===
              commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_NOTIFICATIONS
          );
          if (findServiceData) setCommonServicesFormData(findServiceData);
        }
        // Customer Experience + Communication
        else if (mainTab === 2 && subTab === 1) {
          let findTabData = settingData.find((i) => {
            return (
              i.type === settingTypes.CUSTOMER_EXPERIENCE &&
              i.subType === settingSubType.COMMON_COMMUNICATIONS
            );
          });
          if (findTabData) setSettingFormData(findTabData);
          let findServiceData = commonServicesData.find(
            (i) =>
              i.type ===
              commonServicesTypes.COMMON_CUSTOMER_EXPERIENCE_COMMUNICATIONS
          );
          if (findServiceData) setCommonServicesFormData(findServiceData);
        }
      }
    }
  };
  // Hook
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  const handleDocUpload = async (image) => {
    let formData = new FormData();
    formData.append("image", image);
    let response = await authService.uploadFile(formData);
    if (response && response.data) {
      return response.data;
    }
  };

  let settingProps = {
    inputValue,
    setInputValue,
    inputSettingValue,
    setInputSettingValue,
    settingFormData,
    settingTypes,
    settingSubType,
    afterOpen,
    setAfterOpen,
    beforeOpen,
    setBeforeOpen,
    scheduleOpen,
    setScheduleOpen,
    handleChangeSetting,
    handleDrawerOpen,
    bulktoggle,
    bulkOpened,
    handleSetSettingForm,
    commonServicesFormData,
    saveCommonServiceData,
    commonServicesTypes,
    signature,
    setSignature,
    sections,
    setSections,
    userData,
    customizeTrackingPage,
    setCustomizeTrackingPage,
    addLinkToLandingPage,
    setAddLinkToLandingPage,
    handleDocUpload,
    features,
    history,
  };
  const uploadInput = useRef(null);
  const [image, setImage] = useState("");
  const [imageError, setImageError] = useState("");
  const changeProfileClick = (e) => {
    uploadInput.current.click();
  };
  const [imageUrl, setImageUrl] = useState(builderpopupimg);
  const uploadProfilePicture = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
        setFormData((prev) => {
          return { ...prev, logo: { original: e.target.result } };
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleImageUpload = async () => {
    let formData = new FormData();
    formData.append("image", image);
    let response = await authService.uploadFile(formData);
    if (response && response.data) {
      setFormData((prev) => {
        return {
          ...prev,
          logo: response.data,
        };
      });
      addLogoToggle();
    }
  };

  const userProps = {
    settingPage: true,
  };

  const GeneralTabs = () => {
    return (<div className="st-wrap">
      <Tabs
        selectedIndex={selectedSubTab}
        onSelect={(index) => setSelectedSubTab(index)}
      >
        <TabList>
          {userData &&
            (userData.role !== 1 || userData.subAdmin) && (
              <Tab>Company</Tab>
            )}
          <Tab>Hub</Tab>
          {userData && !userData.subAdmin && (
            <Tab>Administrator</Tab>
          )}
          {userInfo && userInfo.role === 6 && <Tab>Users</Tab>}
          {userData &&
            !userData.userId &&
            userData.role !== 1 && <Tab>Billings</Tab>}
          {userInfo && userInfo.extensionTabShown && (
            <Tab>Extensions</Tab>
          )}
          {userInfo &&
            userInfo &&
            userInfo.role === 1 &&
            !userInfo.userId && <Tab>Access</Tab>}
        </TabList>

        {userData &&
          (userData.role !== 1 || userData.subAdmin) && (
            <TabPanel>
              <div
                className="company-settings"
                style={{
                  display: "flex",
                }}
              >
                <div className="addcustomer-information">
                  <h2>Company informations</h2>
                  <div className="customer-form">
                    {userInfo && (
                      <Formik
                        initialValues={formData}
                        render={(formProps) => {
                          return (
                            <FormF>
                              <Row className="custinfo-form">
                                <Col xs={6}>
                                  <InputGroup>
                                    <InputGroup.Text id="firstname">
                                      <img
                                        src={companyicon}
                                        alt="nameicon"
                                      />
                                    </InputGroup.Text>
                                    <Field
                                      className="form-control"
                                      placeholder="Company Name"
                                      name="companyName"
                                      aria-label="Company Name"
                                      disabled={roleBasedDisabled}
                                      value={
                                        formProps.values
                                          .companyName
                                      }
                                    />
                                    <ErrorMessage
                                      name="CompanyName"
                                      component="div"
                                    />
                                  </InputGroup>
                                </Col>

                                <Col xs={6}>
                                  <InputGroup>
                                    <InputGroup.Text id="phonenumber">
                                      <img
                                        src={phoneicon}
                                        alt="phoneicon"
                                      />
                                    </InputGroup.Text>
                                    <Field
                                      className="form-control"
                                      placeholder="Phone Number"
                                      name="phoneNumber"
                                      aria-label="Phone Number"
                                      disabled={roleBasedDisabled}
                                      value={
                                        formProps.values
                                          .phoneNumber
                                      }
                                    />
                                    <ErrorMessage
                                      name="phoneNumber"
                                      component="div"
                                    />
                                  </InputGroup>
                                </Col>
                                <Col xs={6}>
                                  <InputGroup>
                                    <InputGroup.Text id="website">
                                      <img
                                        src={websiteicon}
                                        alt="lasticon"
                                      />
                                    </InputGroup.Text>
                                    <Field
                                      className="form-control"
                                      placeholder="Web Site"
                                      name="webSite"
                                      aria-label="Web Site"
                                      disabled={roleBasedDisabled}
                                      value={
                                        formProps.values.webSite
                                      }
                                    />
                                    <ErrorMessage
                                      name="webSite"
                                      component="div"
                                    />
                                  </InputGroup>
                                </Col>
                                <Col xs={6}>
                                  <InputGroup>
                                    <InputGroup.Text id="yourworkemail">
                                      <img
                                        src={mailicon}
                                        alt="emailicon"
                                      />
                                    </InputGroup.Text>
                                    <Field
                                      className="form-control"
                                      placeholder="Email"
                                      name="email"
                                      disabled={true}
                                      aria-label="Your email"
                                      value={
                                        formProps.values.email
                                      }
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component="div"
                                    />
                                  </InputGroup>
                                </Col>
                                <Col xs={12}>
                                  <InputGroup>
                                    <InputGroup.Text id="Addressname">
                                      <img
                                        src={stateicon}
                                        alt="Addressicon"
                                      />
                                    </InputGroup.Text>

                                    <Field
                                      className="form-control"
                                      placeholder="Address line 1"
                                      name="addressLine1"
                                      aria-label="Address"
                                      disabled={roleBasedDisabled}
                                      value={
                                        formProps.values
                                          .addressLine1
                                      }
                                    />
                                    <ErrorMessage
                                      name="address"
                                      component="div"
                                    />
                                  </InputGroup>
                                </Col>
                                <Col xs={12}>
                                  <InputGroup>
                                    <InputGroup.Text id="Addressname">
                                      <img
                                        src={stateicon}
                                        alt="Addressicon"
                                      />
                                    </InputGroup.Text>

                                    <Field
                                      className="form-control"
                                      placeholder="Address line 2"
                                      name="addressLine2"
                                      aria-label="Address"
                                      disabled={roleBasedDisabled}
                                      value={
                                        formProps.values
                                          .addressLine2
                                      }
                                    />
                                    <ErrorMessage
                                      name="address"
                                      component="div"
                                    />
                                  </InputGroup>
                                </Col>
                                <Col xs={6}>
                                  <InputGroup>
                                    <InputGroup.Text id="city">
                                      <img
                                        src={stateicon}
                                        alt="AptBldicon"
                                      />
                                    </InputGroup.Text>
                                    <Field
                                      className="form-control"
                                      placeholder="City"
                                      name="city"
                                      disabled={roleBasedDisabled}
                                      aria-label="City"
                                      value={
                                        formProps.values.city
                                      }
                                    />
                                    <ErrorMessage
                                      name="city"
                                      component="div"
                                    />
                                  </InputGroup>
                                </Col>
                                <Col xs={6}>
                                  <InputGroup>
                                    <InputGroup.Text id="state">
                                      <img
                                        src={industryicon}
                                        alt="AptBldicon"
                                      />
                                    </InputGroup.Text>
                                    <Field
                                      className="form-control"
                                      placeholder="State"
                                      name="state"
                                      disabled={roleBasedDisabled}
                                      aria-label="state"
                                      value={
                                        formProps.values.state
                                      }
                                    />
                                    <ErrorMessage
                                      name="state"
                                      component="div"
                                    />
                                  </InputGroup>
                                </Col>
                                <Col xs={6}>
                                  <InputGroup>
                                    <InputGroup.Text id="Zip">
                                      <img
                                        src={stateicon}
                                        alt="AptBldicon"
                                      />
                                    </InputGroup.Text>
                                    <Field
                                      className="form-control"
                                      placeholder="Zip"
                                      name="zipCode"
                                      disabled={roleBasedDisabled}
                                      aria-label="Zip"
                                      value={
                                        formProps.values.zipCode
                                      }
                                    />
                                    <ErrorMessage
                                      name="zipCode"
                                      component="div"
                                    />
                                  </InputGroup>
                                </Col>
                                <Col xs={6}>
                                  <InputGroup>
                                    <InputGroup.Text id="Country">
                                      <img
                                        src={countryicon}
                                        alt="AptBldicon"
                                      />
                                    </InputGroup.Text>
                                    <Field
                                      className="form-control"
                                      placeholder="Country"
                                      name="country"
                                      disabled={roleBasedDisabled}
                                      aria-label="Country"
                                      value={
                                        formProps.values.country
                                      }
                                    />
                                    <ErrorMessage
                                      name="Country"
                                      component="div"
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>
                              {userInfo && !userInfo.userId && (
                                <div className="st-des">
                                  This information will be used as
                                  a default where needed. If
                                  you’re looking to update your
                                  company information for billing,
                                  visit
                                  <span
                                    style={{
                                      color: "#0d6efd",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setSelectedSubTab(3);
                                    }}
                                  >
                                    {" "}
                                    Billing.
                                  </span>
                                </div>
                              )}
                              <div className="ct-btn">
                                <Button
                                  className="cncle-btn"
                                  onClick={() => resetFormData()}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className="ctaskbtn"
                                  type="submit"
                                  disabled={roleBasedDisabled}
                                >
                                  Update
                                </Button>
                              </div>
                            </FormF>
                          );
                        }}
                        onSubmit={updateProfileHandler}
                      ></Formik>
                    )}
                  </div>
                </div>
                <div className="customer-logo">
                  <div
                    className="logo-box"
                    disabled={roleBasedDisabled}
                    onClick={addLogoToggle}
                  >
                    <button type="button">
                      {formData &&
                        formData.logo &&
                        formData.logo.original && (
                          <img
                            src={formData.logo.original}
                            alt="icon"
                          />
                        )}
                      {(!formData ||
                        !formData.logo ||
                        !formData.logo.original) && (
                          <img src={Plus} alt="icon" />
                        )}
                      {(!formData ||
                        !formData.logo ||
                        !formData.logo.original) && (
                          <span>Add your logo</span>
                        )}
                      {(!formData ||
                        !formData.logo ||
                        !formData.logo.original) && (
                          <span>250 x 250</span>
                        )}
                    </button>
                  </div>
                </div>
              </div>
            </TabPanel>
          )}
        <TabPanel>
          <div className="company-settings">
            <Hub />
          </div>
        </TabPanel>
        {/* Administrator informations start */}
        {userData && !userData.subAdmin && (
          <TabPanel>
            <div className="company-settings">
              <div className="addcustomer-information Administrator">
                <h2>Administrator informations</h2>
                <div className="customer-form">
                  <Formik
                    initialValues={formData}
                    render={(formProps) => {
                      return (
                        <FormF>
                          <Row className="custinfo-form">
                            <Col xs={6}>
                              <InputGroup>
                                <InputGroup.Text id="firstname">
                                  <img
                                    src={nameicon}
                                    alt="nameicon"
                                  />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  placeholder="First Name"
                                  name="firstName"
                                  disabled={true}
                                  aria-label="First Name"
                                  value={
                                    formProps.values.firstName
                                  }
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                />
                              </InputGroup>
                            </Col>
                            <Col xs={6}>
                              <InputGroup>
                                <InputGroup.Text id="lastname">
                                  <img
                                    src={nameicon}
                                    alt="lasticon"
                                  />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  placeholder="Last Name"
                                  name="lastName"
                                  disabled={true}
                                  aria-label="Last Name"
                                  value={
                                    formProps.values.lastName
                                  }
                                />
                                <ErrorMessage
                                  name="lastName"
                                  component="div"
                                />
                              </InputGroup>
                            </Col>
                            <Col xs={6}>
                              <InputGroup>
                                <InputGroup.Text id="phonenumber">
                                  <img
                                    src={phoneicon}
                                    alt="phoneicon"
                                  />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  placeholder="Phone Number"
                                  name="phoneNumber"
                                  disabled={true}
                                  aria-label="Phone Number"
                                  value={
                                    formProps.values.phoneNumber
                                  }
                                />
                                <ErrorMessage
                                  name="phoneNumber"
                                  component="div"
                                />
                              </InputGroup>
                            </Col>
                            <Col xs={6}>
                              <InputGroup>
                                <InputGroup.Text id="yourworkemail">
                                  <img
                                    src={mailicon}
                                    alt="emailicon"
                                  />
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  placeholder="Your work email"
                                  name="email"
                                  disabled={true}
                                  aria-label="Your email"
                                  value={formProps.values.email}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                />
                              </InputGroup>
                            </Col>
                            {/* <Col xs={8}>
                                    <InputGroup>
                                      <InputGroup.Text id="Addressname">
                                        <img
                                          src={countryicon}
                                          alt="Addressicon"
                                        />
                                      </InputGroup.Text>

                                      <Field
                                        className="form-control"
                                        placeholder="Address"
                                        name="address"
                                        aria-label="Address"
                                        value={formProps.values.address}
                                      />
                                      <ErrorMessage
                                        name="address"
                                        component="div"
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Col xs={4}>
                                    <InputGroup>
                                      <InputGroup.Text id="AptBld">
                                        <img
                                          src={industryicon}
                                          alt="AptBldicon"
                                        />
                                      </InputGroup.Text>
                                      <Field
                                        className="form-control"
                                        placeholder="Apt, Bld"
                                        name="apartment"
                                        aria-label="AptBld"
                                        value={formProps.values.apartment}
                                      />
                                      <ErrorMessage
                                        name="apartment"
                                        component="div"
                                      />
                                    </InputGroup>
                                  </Col> */}
                          </Row>
                          <div className="st-des">
                            These preferences only apply to you.
                          </div>
                          {/* <div className="ct-btn">
                                    <Button
                                      className="cncle-btn"
                                      onClick={() => resetFormData()}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      className="ctaskbtn"
                                      type="submit"
                                      disabled={roleBasedDisabled}
                                    >
                                      Update
                                    </Button>
                                  </div> */}
                        </FormF>
                      );
                    }}
                    onSubmit={updateProfileHandler}
                  ></Formik>
                </div>
              </div>
            </div>
          </TabPanel>
        )}
        {/* Administrator informations end */}

        {/* user information start */}
        {userInfo && userInfo.role === 6 && (
          <TabPanel>
            <div className="company-settings">
              <div className="customer-heading-box">
                <Row>
                  <Col xs={6}>
                    <div className="user-headinginfo">
                      <h2>Users informations</h2>
                      <p>
                        Create new users, customize user
                        permissions, and remove users from your
                        account.
                      </p>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="createmsbox newuser">
                      <div>
                        <Button
                          className="cticon"
                          onClick={handleDrawerOpen}
                        >
                          <img src={Union} alt="icon" />
                        </Button>
                        <span>Add new User</span>
                      </div>
                      <div>
                        <Button>
                          <img src={downloadicon} alt="icon" />
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              {/* user list start */}
              <div className="customer-list-wrapper">
                <BootstrapTable
                  data={userlist}
                  striped={true}
                  hover={true}
                  selectRow={selectRowProp}
                  pagination
                  wrapperClasses="table-responsive"
                >
                  <TableHeaderColumn
                    dataField="id"
                    isKey={true}
                    dataAlign="left"
                    dataSort={true}
                    dataFormat={username}
                    width="200px"
                  >
                    Name{" "}
                    <img
                      className="ms-1"
                      src={ShortIcon}
                      alt=""
                    />
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataAlign="left"
                    dataSort={true}
                    dataFormat={group}
                    width="200px"
                  >
                    Group{" "}
                    <img
                      className="ms-1"
                      src={ShortIcon}
                      alt=""
                    />
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    dataField="Address"
                    dataSort={true}
                    dataFormat={Access}
                    width="150px"
                  >
                    Access
                    <img
                      className="ms-1"
                      src={ShortIcon}
                      alt=""
                    />
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="Phone"
                    dataSort={true}
                    dataFormat={Phone}
                    width="200px"
                  >
                    Phone
                    <img
                      className="ms-1"
                      src={ShortIcon}
                      alt=""
                    />
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="Email"
                    dataSort={true}
                    dataFormat={Email}
                    width="250px"
                  >
                    Email
                    <img
                      className="ms-1"
                      src={ShortIcon}
                      alt=""
                    />
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    dataField="Action"
                    dataAlign="right"
                    dataFormat={Remove}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>

              {/* user list end */}
            </div>
          </TabPanel>
        )}
        {/* user information end */}
        {/* billing information start */}
        {userData && !userData.userId && userData.role !== 1 && (
          <TabPanel>
            <div className="billing-settings">
              <Tabs>
                <TabList>
                  <Tab>Overview</Tab>
                  <Tab>invoices</Tab>
                  <Tab>Payment Method</Tab>
                  <Tab>Billing contacts</Tab>
                </TabList>
                <TabPanel>
                  <Subscription />
                </TabPanel>
                <TabPanel>
                  <Transactions />
                </TabPanel>
                <TabPanel>
                  <PaymentMethod />
                </TabPanel>
                <TabPanel>
                  <BillingContacts />
                </TabPanel>
              </Tabs>
            </div>
          </TabPanel>
        )}
        {userInfo && userInfo.extensionTabShown && (
          <TabPanel>
            <Extensions />
          </TabPanel>
        )}
        <TabPanel>
          {userInfo &&
            userInfo &&
            userInfo.role === 1 &&
            !userInfo.userId && (
              <div className="company-settings">
                <Users {...userProps} />
              </div>
            )}
        </TabPanel>
        {/* billing information end */}
      </Tabs>
    </div>)
  }

  const OperationTabs = () => {
    return (<div className="st-wrap">
      <Tabs
        defaultFocus={true}
        defaultIndex={!showScheduleSettings ? 1 : 0}
        onSelect={(curr, prev) => {
          setSelectedSubTab(curr);
          handleSetSettingForm(selectedMainTab, curr);
        }}
      >
        <TabList>
          <Tab style={{ display: "none" }}>Schedule</Tab>
          <Tab>Control</Tab>
          <Tab>Regional Settings</Tab>
          {userData && !userData.subAdmin && (
            <Tab> Agent App</Tab>
          )}
          {userData && userData.role === 6 && <Tab>Groups</Tab>}
          {userData && !userData.subAdmin && (
            <Tab style={{ display: "none" }}>Legal protection</Tab>
          )}
          {userData && !userData.subAdmin && (
            <Tab>Communications</Tab>
          )}
        </TabList>
        <TabPanel>
          <ScheduleDeliveryTab {...settingProps} />
        </TabPanel>
        <TabPanel>
          <TaskMissionTab {...settingProps} />
        </TabPanel>
        <TabPanel>
          <RegionalSettingTab {...settingProps} />
        </TabPanel>
        {userData && !userData.subAdmin && (
          <TabPanel>
            <AgentAppTab {...settingProps} />
          </TabPanel>
        )}
        {userInfo && userInfo.role === 6 && (
          <TabPanel>
            <GroupsTab {...settingProps} />
          </TabPanel>
        )}

        {userData && !userData.subAdmin && (
          <TabPanel>
            <LegalProtectionTab {...settingProps} />
          </TabPanel>
        )}

        {userData && !userData.subAdmin && (
          <TabPanel>
            <OperationCommunicationsTab {...settingProps} />
          </TabPanel>
        )}
      </Tabs>
    </div>)
  }

  const CustomerExperienceTabs = () => {
    return (<div className="st-wrap">
      <Tabs
        onSelect={(curr, prev) => {
          setSelectedSubTab(curr);
          handleSetSettingForm(selectedMainTab, curr);
        }}
      >
        <TabList>
          <Tab>Notifications</Tab>
          <Tab>Communications</Tab>
        </TabList>
        <TabPanel>
          <Notifications {...settingProps} />
        </TabPanel>
        <TabPanel>
          <ExperienceCommunicationsTab {...settingProps} />
        </TabPanel>
      </Tabs>
    </div>)
  }

  const AdvancedSettingTabs = () => {
    return (<div className="st-wrap">
      <Tabs
        onSelect={(curr, prev) => {
          setSelectedSubTab(curr);
          handleSetSettingForm(selectedMainTab, curr);
        }}
      >
        <TabList>
          <Tab>API Keys</Tab>
          <Tab>Performance </Tab>
        </TabList>
        <TabPanel>
          <ApiKeysTab />
        </TabPanel>
        <TabPanel>
          <PerformanceTab />
        </TabPanel>
      </Tabs>
    </div>)
  }
  const MainTabs = () => {
    return (<>
      <TabList className="maintabheading">
        <Tab>General</Tab>
        {userData && <Tab>Operations</Tab>}
        {userData && <Tab>Customer experience</Tab>}
        {userData && userData.role === 1 && !userData.subAdmin && (
          <Tab>Advanced Settings</Tab>
        )}
      </TabList>

      <TabPanel>
        {GeneralTabs()}
      </TabPanel>
      {userData && (
        <TabPanel>
          {/* Operations tab start */}
          {OperationTabs()}
          {/* Operations tab end */}
        </TabPanel>
      )}
      {userData && (
        <TabPanel>
          {CustomerExperienceTabs()}
        </TabPanel>
      )}
      {userInfo && userInfo.role === 1 && !userData.subAdmin && (
        <TabPanel>
          {AdvancedSettingTabs()}
        </TabPanel>
      )}
    </>)
  }

  return (
    <>
      <ContentLayout>
        <div className="customer-page-wrapper settings-layout-wrapper">
          <div className="settings-heading-box">
            <h1>Settings</h1>
          </div>
          <div className="customer-form-wrapper setting-layout">
            <div className="settings-tab-wrapper">
              <Tabs
                className="maintablist"
                onSelect={(selectedTab) => {
                  setSelectedMainTab(selectedTab);
                  let subTab = 0;
                  if (!showScheduleSettings) {
                    subTab = 1
                  }
                  if (selectedTab === 2) {
                    subTab = 0;
                  }
                  handleSetSettingForm(selectedTab, subTab);
                }}
              >
                {MainTabs()}
              </Tabs>
            </div>
          </div>
        </div>
      </ContentLayout>
      {/* add user popup start */}
      <div className="opencustomer-popup" open={useropen}>
        <div className="addCustomer-popup">
          <div className="addcustomer-slide">
            <div className="addcustomer-field">
              <span className="title">Add New User</span>
              <div className="customer-import">
                <div className="customer-import-field">
                  <Button>
                    <img src={Union} alt="icon" />
                  </Button>
                  <span>import</span>
                  <Button>
                    <img src={downloadicon} alt="icon" />
                  </Button>
                </div>
                <Button
                  className="createcustomer-close"
                  onClick={handleDrawerClose}
                >
                  <img src={closeicon} alt="icon" />
                </Button>
              </div>
            </div>

            <div className="addcustomer-information">
              <h2>Lead informations</h2>
              <div className="customer-form">
                <Form inline="true">
                  <Row className="custinfo-form">
                    <Col xs={6}>
                      <InputGroup>
                        <InputGroup.Text id="firstname">
                          <img src={nameicon} alt="nameicon" />
                        </InputGroup.Text>
                        <FormControl
                          placeholder="First Name"
                          aria-label="First Name"
                        />
                      </InputGroup>
                    </Col>
                    <Col xs={6}>
                      <InputGroup>
                        <InputGroup.Text id="lastname">
                          <img src={nameicon} alt="lasticon" />
                        </InputGroup.Text>
                        <FormControl
                          placeholder="Last Name"
                          aria-label="Last Name"
                        />
                      </InputGroup>
                    </Col>
                    <Col xs={6}>
                      <InputGroup>
                        <InputGroup.Text id="phonenumber">
                          <img src={phoneicon} alt="phoneicon" />
                        </InputGroup.Text>
                        <FormControl
                          placeholder="Phone Number"
                          aria-label="Phone Number"
                        />
                      </InputGroup>
                    </Col>
                    <Col xs={6}>
                      <InputGroup>
                        <InputGroup.Text id="yourworkemail">
                          <img src={mailicon} alt="emailicon" />
                        </InputGroup.Text>
                        <FormControl
                          placeholder="Email"
                          aria-label="Your work email"
                        />
                      </InputGroup>
                    </Col>
                    <Col xs={6}>
                      <InputGroup className="mb-3 teamsl">
                        <InputGroup.Text id="teamicon">
                          <img src={teamicon} alt="teamicon" />
                        </InputGroup.Text>
                        <Form.Select>
                          <option>Assign To Group</option>
                          <option value="one">One</option>
                          <option value="two">Two</option>
                          <option value="three">Three</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col xs={6}>
                      <InputGroup className="mb-3 teamsl">
                        <InputGroup.Text id="roleicon">
                          <img src={roleicon} alt="roleicon" />
                        </InputGroup.Text>

                        <Form.Select>
                          <option>Access</option>
                          <option value="one">One</option>
                          <option value="two">Two</option>
                          <option value="three">Three</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                  </Row>
                  <div className="ct-btn">
                    <Button className="cncle-btn">Cancel</Button>
                    <Button className="ctaskbtn">Let’s Go</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* add user popup end */}
      {/* add user popup start */}
      <div className="opencustomer-popup" open={userLogo}>
        <div className="addCustomer-popup">
          <div className="addcustomer-slide">
            <div className="addcustomer-field">
              <span className="title">Add your logo</span>
              <div className="customer-import">
                <Button
                  className="createcustomer-close"
                  onClick={addLogoToggle}
                >
                  <img src={closeicon} alt="icon" />
                </Button>
              </div>
            </div>

            <div className="addcustomer-information">
              <h2>Company information</h2>
              <div className="customer-form">
                <div className="builderpp-img">
                  <div>
                    <img
                      style={{ width: "340px", height: "340px" }}
                      src={imageUrl ? imageUrl : Plus}
                      alt="img"
                      onClick={changeProfileClick}
                    />
                  </div>
                  {!imageUrl && !formData.logo && !formData.logo && (
                    <span>Add you logo</span>
                  )}
                  <input
                    className="file-upload"
                    ref={uploadInput}
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => {
                      uploadProfilePicture(e);
                    }}
                    style={{ display: "none" }}
                  />
                  <p>{imageError}</p>
                </div>
                <div className="ct-btn">
                  <Button onClick={addLogoToggle} className="cncle-btn">
                    Cancel
                  </Button>
                  <Button onClick={handleImageUpload} className="ctaskbtn">
                    Let’s Go
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        style={{ color: "black" }}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* add user popup end */}
    </>
  );
};

export default Settings;
